/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Footer from "components/Footer";
import Header from "components/Header";
import ScrollToTop from "components/Intraction/scrollTop";
import { useScroll } from "framer-motion";
import React, { useRef } from "react";

interface LayoutProps {
  children: React.ReactNode;
  theme?: "white" | "blue";
}

const Layout = ({ children, theme = "white" }: LayoutProps) => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ container: ref });

  const layoutStyle = css`
    > svg {
      position: fixed;
      right: 20px;
      bottom: 20px;
      z-index: 100;
    }
    .bg {
      stroke: #ef8113;
      opacity: 0.3;
      stroke-dashoffset: 0;
      stroke-width: 15%;
      fill: none;
    }
    .indicator {
      stroke: #ef8113;
      stroke-dashoffset: 0;
      stroke-width: 15%;
      fill: none;
    }
  `;

  return (
    <div css={layoutStyle}>
      {/* <svg id="progress" width="100" height="100" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="30" pathLength="1" className="bg" />
        <motion.circle
          cx="50"
          cy="50"
          r="30"
          pathLength="1"
          className="indicator"
          style={{ pathLength: scrollYProgress }}
        />
      </svg> */}

      <Header theme={theme} />
      {children}
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default Layout;
