/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import XBar from "components/Intraction/xBar";
import { motion } from "framer-motion";
import React from "react";
import { container } from "styles";
import bg from "static/esg/bg.png";

import { Mobile, PC } from "components/Common/Responsive";

const Section1 = () => {
  const section1Style = css`
    margin-top: 184px;
    .title {
      width: 100%;
      img {
        width: 100%;
      }
    }
  `;

  const section1MoStyle = css`
    margin-top: 60px;
    width: 100%;
    overflow: hidden;
    .title {
      img {
        height: 490px;
      }
    }
  `;

  return (
    <>
      <PC>
        <div css={section1Style}>
          <motion.div
            css={container}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <motion.div
              className="title"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={bg} alt="bg" />
            </motion.div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section1MoStyle}>
          <div css={container}>
            <div className="title">
              <img src={bg} alt="bg" />
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section1;
