/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Title from "components/Common/Title";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { container } from "styles";
import slide4 from "static/employment/slide4.png";
import slide5 from "static/employment/slide5.png";
import slide6 from "static/employment/slide6.png";
import { Mobile, PC } from "components/Common/Responsive";
import { useMediaQuery } from "react-responsive";
import { motion, useInView } from "framer-motion";

const Section2 = () => {
  const section2Style = css`
    padding: 160px 0;
    position: relative;
    .slide {
      margin: 120px 0px;
      .slick-slide {
        padding: 30px 0;
      }
      .slick-list {
        margin: -15px 0; // space(여백)/2
      }
    }
    .slide-bar {
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 100%;
      top: 30px;
      left: 0;
      height: calc(100% - 60px);
      li {
        width: 2px;
        height: 33.33333%;
        background: rgba(45, 45, 45, 0.3);
        font-size: 0;
        &.active {
          background: #000;
        }
      }
    }
    .slick-dots {
      bottom: 95px;
      padding-left: 98px;
      text-align: left;
      li {
        width: 13px;
        border-radius: 50%;
        height: 13px;
        border: 2px solid rgba(0, 0, 0, 0.3);

        margin: 0 12px;
        button {
          width: 13px;
          height: 13px;
          padding: 0;
        }
        button::before {
          display: none;
        }
        &:first-of-type {
          margin-left: 0;
        }
        &.slick-active {
          border: 2px solid #000;
        }
      }
    }

    .list {
      display: flex;
      align-items: center;
      img {
        width: 355px;
      }
      .info {
        padding-left: 98px;
        .number {
          color: #4f80ff;
          font-size: 34px;
          line-height: 44px;
        }
        .title {
          font-size: 36px;
          font-weight: bold;
          margin-top: 12px;
          line-height: 50px;
        }
        .text {
          font-size: 16px;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: -0.16px;
          margin-top: 12px;
        }
      }
      .img {
        margin-left: auto;
      }
    }
  `;

  const sectionMo2Style = css`
    padding: 35px 0;
    position: relative;
    .slide {
      margin: 120px 0px;
      .slick-slide {
        padding: 30px 0;
      }
      .slick-list {
        margin: -15px 0; // space(여백)/2
      }
    }
    .slide-bar {
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 100%;
      top: 30px;
      left: 0;
      height: calc(100% - 60px);
      li {
        width: 2px;
        height: 33.33333%;
        background: rgba(45, 45, 45, 0.3);
        font-size: 0;
        &.active {
          background: #000;
        }
      }
    }
    .slick-dots {
      bottom: 95px;
      padding-left: 98px;
      text-align: left;
      li {
        width: 13px;
        border-radius: 50%;
        height: 13px;
        border: 2px solid rgba(0, 0, 0, 0.3);

        margin: 0 12px;
        button {
          width: 13px;
          height: 13px;
          padding: 0;
        }
        button::before {
          display: none;
        }
        &:first-of-type {
          margin-left: 0;
        }
        &.slick-active {
          border: 2px solid #000;
        }
      }
    }
    .ul {
      margin-top: 10px;
    }
    .list {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 30px 40px;
      img {
        width: 100%;
      }
      .info {
        .number {
          color: #000;
          font-size: 24px;
          line-height: 44px;
        }
        .title {
          font-size: 24px;
          font-weight: bold;
          margin-top: 12px;
          line-height: 36px;
        }
        .text {
          font-size: 16px;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: -0.16px;
          margin-top: 24px;
        }
      }
      .img {
        margin-top: 50px;
        margin-left: auto;
      }
    }
  `;

  const ref = useRef(null);
  const slideRef = useRef(null);
  const [active, setActive] = useState(0);
  const isInView = useInView(ref, { once: true, amount: 0.6 });
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  useEffect(() => {
    if (slideRef !== null && !isMobile) {
      if (isInView) {
        (slideRef as any).current.slickPlay();
      } else {
        (slideRef as any).current.slickPause();
      }
    }
  }, [isInView]);

  const settings = {
    dots: true,
    infinite: true,
    vertical: true,
    verticalSwiping: true,
    speed: 1000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (old: any, next: any) => setActive(next),
  };

  return (
    <>
      <PC>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <div css={section2Style}>
            <Title
              title={
                <>
                  지니웍스 구성원이
                  <br />
                  함께 추구하고 지키는 가치
                </>
              }
            />
            <div css={container} ref={ref}>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <Slider {...settings} className="slide" ref={slideRef}>
                  <div>
                    <div className="list">
                      <div className="info">
                        <div className="number">01</div>
                        <div className="title">
                          혁신을 위한 몰입과
                          <br />
                          탁월함의 추구
                        </div>
                        <div className="text">
                          빠르게 변하는 IT 환경 속에서 이전에 없던 혁신을 만들기
                          위해 <br />
                          계속해서 파고들며 몰입합니다. 안주하지 않고, 더 나은
                          <br />
                          결과를 만들기 위해 끊임없이 개선하며 도전합니다.
                        </div>
                      </div>
                      <div className="img">
                        <img src={slide4} alt="slide4" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="list">
                      <div className="info">
                        <div className="number">02</div>
                        <div className="title">
                          개인의 성장은
                          <br /> 곧 팀과 회사의 성장
                        </div>
                        <div className="text">
                          지니웍스의 구성원은 끊임없이 배우고 성장하기를
                          원합니다. <br />
                          도전하고 성장하려는 구성원이 모여 개인 이상의 시너지를
                          만들고,
                          <br /> 회사는 이러한 성장을 아낌없이 응원하고
                          지원합니다.
                        </div>
                      </div>
                      <div className="img">
                        <img src={slide5} alt="slide5" />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="list">
                      <div className="info">
                        <div className="number">03</div>
                        <div className="title">
                          존중을 바탕으로 한
                          <br />
                          솔직한 소통
                        </div>
                        <div className="text">
                          지니웍스에서 가장 중요한 가치는 소통입니다. 직위나
                          연차에 관계없이, <br />
                          상호 존중을 바탕으로 의견을 나눕니다. 열린 소통을 통해
                          같은 <br />
                          비전을 공유하고, 공동의 목표를 향해 나아갑니다.
                        </div>
                      </div>
                      <div className="img">
                        <img src={slide6} alt="slide6" />
                      </div>
                    </div>
                  </div>
                </Slider>
                <ul className="slide-bar">
                  <li className={active === 0 ? "active" : ""}>1</li>
                  <li className={active === 1 ? "active" : ""}>2</li>
                  <li className={active === 2 ? "active" : ""}>3</li>
                </ul>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </PC>
      <Mobile>
        <div css={sectionMo2Style}>
          <Title
            title={
              <>
                지니웍스 구성원이
                <br />
                함께 추구하고 지키는 가치
              </>
            }
          />
          <div css={container}>
            <div className="ul">
              <div className="list">
                <div className="info">
                  <div className="number">01</div>
                  <div className="title">
                    혁신을 위한 몰입과
                    <br />
                    탁월함의 추구
                  </div>
                  <div className="text">
                    빠르게 변하는 IT 환경 속에서 이전에 없던 혁신을 만들기 위해
                    계속해서 파고들며 몰입합니다. 안주하지 않고, 더 나은 결과를
                    만들기 위해 끊임없 이 개선하며 도전합니다.
                  </div>
                </div>
                <div className="img">
                  <img src={slide4} alt="slide4" />
                </div>
              </div>

              <div className="list">
                <div className="info">
                  <div className="number">02</div>
                  <div className="title">
                    개인의 성장은
                    <br /> 곧 팀과 회사의 성장
                  </div>
                  <div className="text">
                    지니웍스의 구성원은 끊임없이 배우고 성장하기를 원합니다.
                    도전하고 성장하려는 구성원이 모여 개인 이상의 시너지를
                    만들고, 회사는 이러한 성장을 아낌없이 응원하고 지원합니다.
                  </div>
                </div>
                <div className="img">
                  <img src={slide5} alt="slide5" />
                </div>
              </div>

              <div className="list">
                <div className="info">
                  <div className="number">03</div>
                  <div className="title">
                    존중을 바탕으로 한
                    <br />
                    솔직한 소통
                  </div>
                  <div className="text">
                    지니웍스에서 가장 중요한 가치는 소통입니다. 직위나 연차에
                    관계없이, 상호 존중을 바탕으로 의견을 나눕니다. 열린 소통을
                    통해 같은 비전을 공유하고, 공동의 목표를 향해 나아갑니다.
                  </div>
                </div>
                <div className="img">
                  <img src={slide6} alt="slide6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section2;
