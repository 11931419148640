/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { container } from "styles";
import Slider from "react-slick";
import Title from "components/Common/Title";
import slide1 from "static/main/slide1.png";
import slide2 from "static/main/slide2.png";
import slide3 from "static/main/slide3.png";
import { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import Bar from "components/Intraction/yBar";
import BarBox from "components/Intraction/barBox";
import Arc from "components/Intraction/arc";
import Yline from "components/Intraction/yLine";
import { tabandmo } from "styles/media";
import { Mobile, PC } from "components/Common/Responsive";

const Section1 = () => {
  const section1Style = css`
    padding: 120px 0;
    .motion-container {
      height: 460px;
    }
    .slide {
      > .slick-list {
        width: 455px;
        height: 460px;
        overflow: hidden;
        margin: 0 auto;
        margin-top: 90px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        .list {
          width: 455px;
          height: 460px;
          display: flex;
        }
        img {
          height: 100%;
        }
      }
      .slick-dots {
        bottom: -50px;
        li {
          width: 13px;
          border-radius: 50%;
          height: 13px;
          border: 2px solid rgba(0, 0, 0, 0.3);
          margin: 0 12px;
          button {
            width: 13px;
            height: 13px;
            padding: 0;
          }
          button::before {
            display: none;
          }
          &.slick-active {
            border: 2px solid #000;
          }
        }
      }
    }
    .slide-bar {
      display: flex;
      li {
        width: 33.33333%;
        height: 2px;
        background: rgba(45, 45, 45, 0.3);
        font-size: 0;
        &:nth-of-type(2) {
          flex-shrink: 0;
          width: 455px;
        }
        &.active {
          background: #000;
        }
      }
    }
    .left-shape {
      position: absolute;
      left: 50%;
      margin-left: -285px;
      top: 100px;
      .line {
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, 4px);
      }
    }
    .right-shape {
      position: absolute;
      left: 50%;
      margin-left: 135px;
      top: -15px;
      .line {
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-15px, 4px);
      }
    }
    .bar-box {
      position: absolute;
      left: 50%;
      bottom: 0;
    }
    ${tabandmo} {
      padding: 60px 0 0 0;
      .motion-container {
        height: auto;
      }
      .slide-bar {
        display: none;
      }
      .slick-track {
        display: flex;
      }
      .slide {
        position: relative;
        top: 4px;
      }
      .slick-slide {
        display: flex;
      }
      .slide > .slick-list {
        width: 100%;
        height: auto;
        display: flex;
      }
      .slide > .slick-list .list {
        width: 100%;
        height: auto;
        display: flex;
      }
      .slide > .slick-list img {
        width: 100%;
        height: auto;
        float: left;
      }
    }
  `;

  const section1MoStyle = css`
    padding: 60px 0 0 0;
    .slide {
      margin-bottom: -4px;
      > .slick-list {
        overflow: hidden;
        margin: 0 auto;
        margin-top: 50px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        .list {
          display: flex;
        }
        img {
          width: 100%;
        }
      }
      button {
        display: none !important;
      }
      .slick-dots {
        display: none !important;
      }
    }
    .left-shape {
      position: absolute;
      left: 50%;
      margin-left: calc(-38vw - 32px);
      top: 15vw;
      .line {
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, 4px);
      }
    }
    .right-shape {
      position: absolute;
      left: 50%;
      margin-left: 30vw;
      top: 3vw;
      .line {
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-15px, 4px);
      }
    }
    .bar-box {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  `;

  const ref = useRef(null);
  const slideRef = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.6 });
  const [active, setActive] = useState(0);

  useEffect(() => {
    if (slideRef !== null) {
      if (isInView) {
        (slideRef as any).current.slickPlay();
      } else {
        (slideRef as any).current.slickPause();
      }
    }
  }, [isInView]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (old: any, next: any) => setActive(next),
  };

  return (
    <>
      <PC>
        <div css={section1Style} ref={ref}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Title
              title={
                <>
                  커머스부터 마케팅까지
                  <br />
                  비즈니스 성장 실현<PC>을 위해 함께합니다</PC>
                </>
              }
              sub={
                <>
                  지니웍스는 기술 플랫폼을 기반으로{" "}
                  <Mobile>
                    <br />
                  </Mobile>
                  새로운 성장 가치를 실현합니다.
                </>
              }
            />

            <div css={container}>
              <div className="motion-container">
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <Slider {...settings} className="slide" ref={slideRef}>
                    <div>
                      <div className="list">
                        <img src={slide1} alt="slide1" />
                      </div>
                    </div>
                    <div>
                      <div className="list">
                        <img src={slide2} alt="slide2" />
                      </div>
                    </div>
                    <div>
                      <div className="list">
                        <img src={slide3} alt="slide3" />
                      </div>
                    </div>
                  </Slider>
                  <div className="left-shape">
                    <Yline
                      width={100}
                      delay={0.6}
                      duration={0.3}
                      type="bottom"
                      visible={isInView}
                      className="line"
                    />
                    <Bar width={117} height={117} />
                  </div>
                  <div className="right-shape">
                    <Yline
                      width={100}
                      delay={1.2}
                      duration={0.3}
                      type="bottom"
                      visible={isInView}
                      className="line"
                    />
                    <Arc
                      width={110}
                      height={110}
                      visible={isInView}
                      delay={0.9}
                    />
                  </div>
                  <BarBox
                    width={227}
                    height={170}
                    className="bar-box"
                    delay={1.5}
                  />
                  <ul className="slide-bar">
                    <li className={active === 0 ? "active" : ""}>1</li>
                    <li className={active === 1 ? "active" : ""}>2</li>
                    <li className={active === 2 ? "active" : ""}>3</li>
                  </ul>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section1MoStyle} ref={ref}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Title
              title={
                <>
                  커머스부터 마케팅까지
                  <br />
                  비즈니스 성장 실현
                </>
              }
              sub={
                <>
                  지니웍스는 기술 플랫폼을 기반으로{" "}
                  <Mobile>
                    <br />
                  </Mobile>
                  새로운 성장 가치를 실현합니다.
                </>
              }
            />

            <div css={container}>
              <div className="motion-container">
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <Slider {...settings} className="slide" ref={slideRef}>
                    <div>
                      <div className="list">
                        <img src={slide1} alt="slide1" />
                      </div>
                    </div>
                    <div>
                      <div className="list">
                        <img src={slide2} alt="slide2" />
                      </div>
                    </div>
                    <div>
                      <div className="list">
                        <img src={slide3} alt="slide2" />
                      </div>
                    </div>
                  </Slider>
                  <div className="left-shape">
                    <Yline
                      width={54}
                      delay={0.6}
                      duration={0.3}
                      type="bottom"
                      visible={isInView}
                      className="line"
                    />
                    <Bar width={64} height={64} />
                  </div>
                  <div className="right-shape">
                    <Yline
                      width={54}
                      delay={1.2}
                      duration={0.3}
                      type="bottom"
                      visible={isInView}
                      className="line"
                    />
                    <Arc
                      width={64}
                      height={64}
                      visible={isInView}
                      delay={0.9}
                    />
                  </div>
                  <BarBox
                    width={227}
                    height={170}
                    className="bar-box"
                    delay={1.5}
                  />
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </Mobile>
    </>
  );
};

export default Section1;
