/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Mobile, PC } from "components/Common/Responsive";
import { motion } from "framer-motion";
import mobile1Image from "static/commerce/mobile1.png";
import mobile2Image from "static/commerce/mobile2.png";
import mobile3Image from "static/commerce/mobile3.png";
import mobileMo1Image from "static/commerce/mobile_Mo1.png";
import mobileMo2Image from "static/commerce/mobile_Mo2.png";
import mobileMo3Image from "static/commerce/mobile_Mo3.png";

const Section1 = () => {
  const Section1Style = css`
    position: relative;
    width: 100%;
    height: 700px;
    margin-top: 7px;
    margin-bottom: 210px;
    div {
      position: absolute;
      width: 263px;
      height: 544px;
      z-index: 2;
      display: block;
      img {
        width: 100%;
      }
    }
    > div:nth-of-type(1) {
      left: 0;
      bottom: 0;
    }
    div:nth-of-type(2) {
      bottom: 95px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    div:nth-of-type(3) {
      right: 0;
      bottom: 0;
    }
  `;
  const Section1MoStyle = css`
    position: relative;

    width: calc(100% + 40px);
    height: 326px;
    margin-top: 60px;
    margin-bottom: 90px;
    margin-left: -20px;
    overflow: hidden;

    img {
      position: absolute;
      z-index: 2;
    }
    img:nth-of-type(1) {
      width: 140px;
      height: 283px;
      left: -60px;
      top: 17px;
    }
    img:nth-of-type(2) {
      top: 0;
      width: 140px;
      height: 283px;
      left: 32%;
    }
    img:nth-of-type(3) {
      width: 140px;
      height: 283px;
      right: -60px;
      top: 17px;
    }
  `;
  return (
    <>
      <PC>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <div css={Section1Style}>
            <motion.div
              className="phone"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={mobile1Image} alt="mobile1Image" />
            </motion.div>
            <motion.div
              className="phone"
              variants={{
                offscreen: {
                  y: 100,
                  x: "-50%",
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  x: "-50%",
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={mobile2Image} alt="mobile2Image" />
            </motion.div>
            <motion.div
              className="phone"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={mobile3Image} alt="mobile3Image" />
            </motion.div>
          </div>
        </motion.div>
      </PC>
      <Mobile>
        <div css={Section1MoStyle}>
          <img src={mobile1Image} alt="mobile1Image" />
          <img src={mobile2Image} alt="mobile2Image" />
          <img src={mobile3Image} alt="mobile3Image" />
        </div>
      </Mobile>
    </>
  );
};

export default Section1;
