import { css } from "@emotion/react";
import { tabandmo } from "./media";

export const container = css`
  width: 1140px;
  margin: 0 auto;
  position: relative;
  ${tabandmo} {
    width: 100%;
  }
`;
