/** @jsxImportSource @emotion/react */
import { useParams, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import Contents from "components/Common/Contents";
import Layout from "components/Common/Layout";
import nextIcon from "static/news/next.svg";
import prevIcon from "static/news/prev.svg";
import YouTube from "react-youtube";
import comeImage from "static/news/come.png";

import PageTitle from "components/Common/PageTitle";
import { useEffect, useState } from "react";
import { api } from "service/config";
import moment from "moment";
import { Helmet } from "react-helmet";
interface DetailProps {
  detailType: "image" | "video";
}
const Detail = ({ detailType }: DetailProps) => {
  const DetailStyle = css`
    .title {
      display: flex;
      flex-direction: column;
      /* margin-top: -65px; */
      margin-top: 88px;
      padding-bottom: 12px;
      border-bottom: 1px solid #aeaeae;
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 42px; /* 150% */
      letter-spacing: -0.28px;
    }
    .title strong {
      display: block;
      margin-top: 30px;
      color: #aeaeae;
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      display: flex;
      align-items: center;
    }
    .title span {
      color: #aeaeae;
      text-align: justify;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      margin-left: 10px;
    }
    .contents {
      padding-top: 60px;
    }
    .contents > img {
      width: 100%;
    }
    .contents .video {
      display: flex;
      justify-content: center;
    }
    .contents .video iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
    .contents .info {
      color: #000;
      margin-top: 60px;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 175% */
      letter-spacing: -0.16px;
      white-space: pre-wrap;
    }
    .contents .info span > p {
      margin-bottom: 42px;
    }
    .contents .info span > p:last-of-type {
      margin-bottom: 0px;
    }
    .contents .info a {
      color: #000;
      margin-left: 3px;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: -0.16px;
      text-decoration-line: underline;
    }
    .pagination {
      margin-top: 90px;
      margin-bottom: 120px;
      border-top: 1px solid #e1e1e1;
      color: #666;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    }
    .pagination .active {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    }
    .pagination > div {
      position: relative;
      padding: 16px 38px;
      border-top: 1px solid #e1e1e1;
      border-bottom: 1px solid #e1e1e1;
    }
    .pagination > button {
      margin-top: 24px;
      border-radius: 4px;
      background: #4f80ff;
      padding: 9px 27.5px;
      border: 0;
      color: #fff;
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      /* Bold/body-S-800 */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px; /* 157.143% */
    }
    .pagination i {
      position: absolute;
      left: 8px;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 20px;
      height: 20px;
    }
    .pagination .next i {
      background: url(${nextIcon});
    }
    .pagination .prev i {
      background: url(${prevIcon});
    }
  `;

  const navigate = useNavigate();
  const { id } = useParams();

  // const selectedVideoItem = newsVideoList[index];
  // const nextVideoItem = newsVideoList[index - 1];
  // const prevVideoItem = newsVideoList[index + 1];

  const [newsVideoList, setNewsVideoList] = useState<any>();
  const [newsList, setNewsList] = useState<any>();

  useEffect(() => {
    if (detailType === "video") {
      api.get(`/video/video/${id}`).then((response) => {
        setNewsVideoList(response.data);
      });
    } else {
      api.get(`/news/news/${id}`).then((response) => {
        setNewsList(response.data);
      });
    }
  }, [id]);

  return (
    <Layout>
      <Helmet>
        <title>
          뉴스 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>
      <PageTitle
        titleText="뉴스룸"
        subTitleText="언론보도 및 최신소식을 안내드립니다."
        contentsTitleText={detailType === "image" ? `언론보도` : `비디오`}
        contentsSubTitleText={``}
        titleImage={comeImage}
        fill="#4F80FF"
        textHeight={55}
      />
      <Contents>
        <div css={DetailStyle}>
          {detailType === "image" ? (
            <div className="title">
              {newsList?.title}
              <strong>
                뉴스
                <span> {moment(newsList?.createAt).format("YYYY-MM-DD")}</span>
              </strong>
            </div>
          ) : (
            <div className="title">
              {newsVideoList?.title}
              <strong>
                비디오
                <span>
                  {moment(newsVideoList?.createAt).format("YYYY-MM-DD")}
                </span>
              </strong>
            </div>
          )}

          <div className="contents">
            {detailType === "image" ? (
              newsList?.fileDtos.map((v: any) => {
                return (
                  <img
                    src={`${v.filePath}`}
                    alt={`뉴스 이미지 ${newsList?.id}`}
                    style={{
                      width: "auto",
                      maxWidth: "100%",
                    }}
                  />
                );
              })
            ) : (
              <div className="video">
                <YouTube
                  videoId={`${newsVideoList?.urlLink}`}
                  opts={{
                    width: "800",
                    height: "450",
                    playerVars: {
                      autoplay: 1, //자동재생 O
                      rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
                      modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                    },
                  }}
                />
              </div>
            )}
            {detailType === "image" && (
              <div className="info">{newsList?.content}</div>
            )}
            <a href={newsList?.urlLink} target="_blank">
              <div className="info">{newsList?.source}</div>
            </a>
          </div>
          {detailType === "image" ? (
            <div className="pagination">
              {newsList?.next?.id !== undefined && (
                <div
                  className="next"
                  onClick={() => {
                    navigate("/news/list/" + newsList?.next?.id);
                  }}
                >
                  <i></i>
                  {newsList?.next?.title}
                </div>
              )}

              {newsList?.before?.id !== undefined && (
                <div
                  className="active prev"
                  onClick={() => {
                    navigate("/news/list/" + newsList.before?.id);
                  }}
                >
                  <i></i>
                  {newsList?.before?.title}
                </div>
              )}

              <button
                onClick={() => {
                  navigate("/news/list");
                }}
              >
                목록
              </button>
            </div>
          ) : (
            <div className="pagination">
              {newsVideoList?.next?.id !== undefined && (
                <div
                  className="next"
                  onClick={() => {
                    navigate("/news/video/" + newsVideoList?.next?.id);
                  }}
                >
                  <i></i>
                  {newsVideoList?.next?.title}
                </div>
              )}

              {newsVideoList?.before?.id !== undefined && (
                <div
                  className="active prev"
                  onClick={() => {
                    navigate("/news/video/" + newsVideoList?.before?.id);
                  }}
                >
                  <i></i>
                  {newsVideoList?.before?.title}
                </div>
              )}

              <button
                onClick={() => {
                  navigate("/news/video");
                }}
              >
                목록
              </button>
            </div>
          )}
        </div>
      </Contents>
    </Layout>
  );
};

export default Detail;
