/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import XBar from "components/Intraction/xBar";
import React from "react";
import { motion } from "framer-motion";
import big1 from "static/commerce/big1.png";
import img1 from "static/commerce/img1.png";
import img2 from "static/commerce/img2.png";
import img3 from "static/commerce/img3.png";
import img4 from "static/commerce/img4.png";
import Bar from "components/Intraction/yBar";
import { Mobile, PC } from "components/Common/Responsive";

const Section3 = () => {
  const section3Style = css`
    background: #f1f5ff;
    > div {
      position: relative;
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      padding-top: 255px;
      padding-bottom: 156px;
      padding-top: 255px 100px 156px 100px;
    }

    > div > svg {
      position: absolute;
      right: 0;
    }
    > div > svg:nth-of-type(1) {
      top: 0;
    }
    > div > svg:nth-of-type(2) {
      top: 84px;
    }
    > div > svg:nth-of-type(3) {
      bottom: 0;
      right: 0;
    }
    .left {
      margin-left: 88px;
      color: #000;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
    }
    .left span {
      color: var(--gray-2-aeaeae, #666);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.2px;
    }
    .left h1 {
      color: #000;
      margin: 20px 0;
      font-family: Pretendard;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 58px; /* 120.833% */
      letter-spacing: -0.48px;
    }

    .left img {
      width: 399px;
      height: 307px;
      margin-top: 47px;
    }
    .right {
      margin-right: 88px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .right > div {
      width: 178px;
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
    }
    .right > div:nth-of-type(n + 3) {
      margin-top: 44px;
    }
    .right > div img {
      width: 103px;
      height: 103px;
      flex-shrink: 0;
    }
    .right > div h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 154.545% */
      letter-spacing: -0.22px;
      margin-top: 24px;
      margin-bottom: 12px;
    }
  `;
  const section3MoStyle = css`
    background: #f1f5ff;
    > div {
      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding-top: 60px;
      padding-bottom: 90px;
    }

    .left {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    }
    .left span {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.36px;
    }
    .left h1 {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px; /* 150% */
      letter-spacing: -0.64px;
      margin-bottom: 12px;
      margin-top: 2px;
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 26px 25px 0px 25px;
    }
    .right > div {
      width: calc(50% - 16px);
      margin-top: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
    }
    .right > div img {
      width: 102px;
      height: 102px;
      flex-shrink: 0;
    }
    .right > div h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      margin-top: 16px;
      margin-bottom: 8px;
    }
  `;

  return (
    <>
      <PC>
        <div css={section3Style}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <XBar width={126} height={84} type="left" />
            <motion.svg width={126} height={84}>
              <motion.circle cx={42} cy={42} r={42} fill={"#4F80FF"} />
              <motion.circle cx={126} cy={42} r={42} fill={"#4F80FF"} />
            </motion.svg>
            <motion.svg width={200} height={100}>
              <Bar width={100} height={100} />
              <motion.circle cx={150} cy={50} r={50} fill={"#4F80FF"} />
            </motion.svg>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="left">
                <span>셀러(seller)</span>
                <h1>판매 파트너</h1>
                한 번의 입점과 상품등록으로
                <br />
                네트워크로 연결된 다수 채널 동시 판매
                <img src={big1} alt="big1" />
              </div>
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.6,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="right">
                <div>
                  <img src={img1} alt="img1" />
                  <h1>높은 입점 효과</h1>
                  상품을 다수 채널 노출 가능하며, 방문자가 많은 새로운 고객층
                  확보
                </div>
                <div>
                  <img src={img2} alt="img2" />
                  <h1>높은 운영 효율</h1>
                  다수 채널을 단일 시스템으로 운영하여 시간 및 비용 절감
                </div>
                <div>
                  <img src={img3} alt="img3" />
                  <h1>높은 구매전환율</h1>각 채널별 고객 DB와 정교한 AI 추천
                  알고리즘을 활용한 추천 및 타겟팅
                </div>
                <div>
                  <img src={img4} alt="img4" />
                  <h1>폐쇄형 서비스</h1>
                  제휴 연동된 고객에게만 판매하여 검색엔진에 가격 비노출
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section3MoStyle}>
          <div>
            <div className="left">
              <span>셀러(seller)</span>
              <h1>판매 파트너</h1>
              한 번의 입점과 상품등록으로
              <br />
              네트워크로 연결된 다수 채널 동시 판매
            </div>
            <div className="right">
              <div>
                <img src={img1} alt="img1" />
                <h1>높은 입점 효과</h1>
                상품을 다수 채널 노출 가능하며, 방문자가 많은 새로운 고객층 확보
              </div>
              <div>
                <img src={img2} alt="img2" />
                <h1>높은 운영 효율</h1>
                다수 채널을 단일 시스템으로 운영하여 시간 및 비용 절감
              </div>
              <div>
                <img src={img3} alt="img3" />
                <h1>높은 구매전환율</h1>각 채널별 고객 DB와 정교한 AI 추천
                알고리즘을 활용한 추천 및 타겟팅
              </div>
              <div>
                <img src={img4} alt="img4" />
                <h1>폐쇄형 서비스</h1>
                제휴 연동된 고객에게만 판매하여 검색엔진에 가격 비노출
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section3;
