/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import React from "react";
import slide1 from "static/employment/slide1.png";
import slide2 from "static/employment/slide2.png";
import slide3 from "static/employment/slide3.png";
import { tabandmo } from "styles/media";

const Section1 = () => {
  const infiniteAnimation1 = keyframes`
   0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-100%);
    }
    50.1% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
`;

  const infiniteAnimation2 = keyframes`
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-200%);
    }
`;

  const section1Style = css`
    .slide_container {
      overflow: hidden;

      .slide_wrapper {
        display: flex;
        flex-wrap: nowrap;
      }
      .slide {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        position: relative;
        padding: 60px 0;
        &.original {
          animation: 120s linear infinite normal none running
            ${infiniteAnimation1};
        }
        &.clone {
          animation: 120s linear infinite ${infiniteAnimation2};
        }
        li {
          width: 676px;
          height: 510px;
          margin-right: 40px;
          img {
            width: 100%;
          }
        }
      }
    }
    ${tabandmo} {
      .slide_container {
        .slide {
          li {
            width: 250px;
            height: auto;
            margin-right: 8px;
          }
        }
      }
    }
  `;

  return (
    <div css={section1Style}>
      <div className="slide_container">
        <ul className="slide_wrapper">
          <div className={"slide original"}>
            <li>
              <img src={slide1} alt="slide1" />
            </li>
            <li>
              <img src={slide2} alt="slide1" />
            </li>
            <li>
              <img src={slide3} alt="slide1" />
            </li>
            <li>
              <img src={slide1} alt="slide1" />
            </li>
            <li>
              <img src={slide2} alt="slide1" />
            </li>
            <li>
              <img src={slide3} alt="slide1" />
            </li>
          </div>
          <div className={"slide clone"}>
            <li>
              <img src={slide1} alt="slide1" />
            </li>
            <li>
              <img src={slide2} alt="slide1" />
            </li>
            <li>
              <img src={slide3} alt="slide1" />
            </li>
            <li>
              <img src={slide1} alt="slide1" />
            </li>
            <li>
              <img src={slide2} alt="slide1" />
            </li>
            <li>
              <img src={slide3} alt="slide1" />
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Section1;
