/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import React from "react";
import { container } from "styles";
import cont1 from "static/esg/cont1.png";
import cont2 from "static/esg/cont2.png";
import cont3 from "static/esg/cont3.png";
import mCont1 from "static/esg/mCont1.png";
import mCont2 from "static/esg/mCont2.png";
import mCont3 from "static/esg/mCont3.png";

import { Mobile, PC } from "components/Common/Responsive";
import Xline from "components/Intraction/xLine";
import Title from "components/Common/Title";

const Section2 = () => {
  const section2Style = css`
    margin-top: 177px;
    .cont:nth-of-type(1) {
      margin-top: 211px;
    }
    .cont {
      display: flex;
      align-items: center;
      margin-top: 150px;
      &.right {
        flex-direction: row-reverse;
        .img {
          svg {
            left: auto;
            right: 0;
          }
        }
      }
      justify-content: space-between;
      .img {
        width: 657px;
        position: relative;
        img {
          width: 100%;
        }
        svg {
          position: absolute;
          bottom: -6px;
          left: -2px;
        }
      }
      .info {
        .title {
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 54px;
          span {
            color: #7c70e9;
          }
        }
        .text {
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 26px;
          letter-spacing: -0.16px;
          margin-top: 12px;
        }
      }
    }
  `;

  const section2MoStyle = css`
    margin-top: 90px;
    .cont {
      align-items: center;
      margin-top: 60px;
      &.right {
        flex-direction: row-reverse;
        .img {
          svg {
            left: auto;
            right: 0;
          }
        }
        .info {
          text-align: right;
        }
      }
      justify-content: space-between;
      .img {
        width: 100%;
        position: relative;
        img {
          width: 100%;
        }
        svg {
          position: absolute;
          bottom: -6px;
          left: -2px;
        }
      }
      .info {
        padding: 0 20px;
        margin-top: 20px;
        .title {
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          span {
            color: #7c70e9;
          }
        }
        .text {
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
          letter-spacing: -0.12px;
        }
      }
    }
  `;

  return (
    <>
      <PC>
        <div css={section2Style}>
          <div css={container}>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              <Title
                title={
                  <>
                    기업의 이익을 넘어 모두가 <br />
                    누릴 수 있는 사회적 가치를 추구합니다
                  </>
                }
              />
            </motion.div>
            <motion.div
              className="cont"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0,
                      duration: 0.3,
                    },
                  },
                }}
                className="img"
              >
                <img src={cont1} alt="cont1" />
                {/* <Xline width={760} delay={0.6} type="right" /> */}
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
                className="info"
              >
                <div className="title">
                  <span>E</span>nvironmental
                  <br />
                  환경경영
                </div>
                <div className="text">
                  미래 세대와 함께 누리는 지속가능한 생태환경 조성을 목표로
                  <br />
                  경영 전반의 친환경 가치를 창출하고 있습니다.
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className="cont right"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0,
                      duration: 0.3,
                    },
                  },
                }}
                className="img"
              >
                <img src={cont2} alt="cont1" />
                {/* <Xline width={760} delay={0.6} type="left" /> */}
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
                className="info"
              >
                <div className="title">
                  <span>S</span>ocial
                  <br />
                  사회공헌
                </div>
                <div className="text">
                  인권 존중, 지역사회 균형, 사회적 약자 보호 등 단순 기부를
                  <br />
                  벗어나 현대 사회가 직면한 문제 해결에 기여할 수 있는 <br />
                  다양한 사업을 개발하고 추진합니다.
                </div>
              </motion.div>
            </motion.div>
            <motion.div
              className="cont"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      duration: 0.3,
                    },
                  },
                }}
                className="img"
              >
                <img src={cont3} alt="cont1" />
                {/* <Xline width={760} delay={0.6} type="right" /> */}
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
                className="info"
              >
                <div className="title">
                  <span>G</span>overnance
                  <br />
                  지배구조
                </div>
                <div className="text">
                  구성원과 고객을 우선하는 가치, 열린 소통과 신뢰를
                  <br /> 바탕으로 깨끗하고 투명한 조직 문화를 만듭니다.
                </div>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </PC>
      <Mobile>
        <div css={section2MoStyle}>
          <div css={container}>
            <Title
              title={
                <>
                  기업의 이익을 넘어 모두가 <br />
                  누릴 수 있는 사회적 가치를 추구합니다
                </>
              }
            />
            <div className="cont">
              <div className="img">
                <img src={cont1} alt="cont1" />
              </div>
              <div className="info">
                <div className="title">
                  <span>E</span>nvironmental
                  <br />
                  환경경영
                </div>
                <div className="text">
                  미래 세대와 함께 누리는 지속가능한 생태환경 조성을 목표로
                  <br />
                  경영 전반의 친환경 가치를 창출하고 있습니다.
                </div>
              </div>
            </div>
            <div className="cont right">
              <div className="img">
                <img src={cont2} alt="cont2" />
              </div>
              <div className="info">
                <div className="title">
                  <span>S</span>ocial
                  <br />
                  사회공헌
                </div>
                <div className="text">
                  인권 존중, 지역사회 균형, 사회적 약자 보호 등 단순 기부를
                  <br />
                  벗어나 현대 사회가 직면한 문제 해결에 기여할 수 있는 <br />
                  다양한 사업을 개발하고 추진합니다.
                </div>
              </div>
            </div>
            <div className="cont">
              <div className="img">
                <img src={cont3} alt="cont3" />
              </div>
              <div className="info">
                <div className="title">
                  <span>G</span>overnance
                  <br />
                  지배구조
                </div>
                <div className="text">
                  구성원과 고객을 우선하는 가치, 열린 소통과 신뢰를
                  <br /> 바탕으로 깨끗하고 투명한 조직 문화를 만듭니다.
                </div>
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section2;
