/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { container } from "styles";
import React, { useRef } from "react";
import icon1 from "static/main/icon1.png";
import icon2 from "static/main/icon2.png";
import icon3 from "static/main/icon3.png";
import icon4 from "static/main/icon4.png";
import Title from "components/Common/Title";
import { motion, useInView } from "framer-motion";
import CountUp from "react-countup";
import Yline from "components/Intraction/yLine";
import BarBox from "components/Intraction/barBox";
import Bar from "components/Intraction/yBar";
import XBar from "components/Intraction/xBar";
import { Mobile, PC } from "components/Common/Responsive";

const Section3 = () => {
  const section3Style = css`
    padding: 120px 0 240px 0;
    .list {
      display: flex;
      margin-top: 120px;
      li {
        flex: 1;
        text-align: center;
        position: relative;
        padding: 20px 0;
        img {
          width: 45px;
          height: 45px;
        }
        .title {
          margin-top: 24px;
          font-size: 44px;
          font-weight: bold;
          line-height: 68px;
        }
        .line {
          position: absolute;
          right: -4px;
          top: 0;
        }
        .text {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
    .bar-box {
      position: absolute;
      right: 0;
      bottom: -240px;
    }
    .ract-box {
      display: flex;
      position: absolute;
      left: 0;
      bottom: -240px;
      flex-direction: column;
    }
  `;
  const section3MoStyle = css`
    padding: 60px 0 137px 0;
    .list {
      display: flex;
      margin-top: 60px;
      padding: 20px 30px;
      flex-wrap: wrap;
      li {
        text-align: center;
        position: relative;
        width: 50%;
        flex-shrink: 0;
        .title {
          font-size: 28px;
          font-weight: bold;
          line-height: 42px;
        }
        .text {
          font-size: 12px;
          line-height: 18px;
        }
      }
      li:nth-of-type(3),
      li:nth-of-type(4) {
        margin-top: 12px;
      }
    }
    .bar-box {
      position: absolute;
      right: 0;
      bottom: -137px;
    }
    .ract-box {
      display: flex;
      position: absolute;
      left: 0;
      bottom: -137px;
      flex-direction: column;
    }
  `;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.6 });
  return (
    <>
      <PC>
        <div css={section3Style} ref={ref}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Title
              title={
                <>
                  더욱 빠르게, 더 많이
                  <br />
                  혁신을 현실로 만들어 갑니다
                </>
              }
              sub={
                <>
                  빠르게 변하는 환경에 앞서서 새로운 기회를 창출하며
                  <br />
                  사업 규모를 확대해 나가고 있습니다.
                </>
              }
            />
            <div css={container}>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <ul className="list">
                  <li>
                    <img src={icon1} alt="icon1" />
                    <div className="title">
                      <CountUp
                        duration={1.2}
                        end={750}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={300}
                      />
                      억+
                    </div>
                    <motion.div
                      className="text"
                      variants={{
                        offscreen: {
                          opacity: 0,
                        },
                        onscreen: {
                          opacity: 1,
                          transition: {
                            delay: 1.8,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      커머스 네트워크 연간 GMV
                    </motion.div>
                    <Yline
                      width={213}
                      delay={0.9}
                      duration={0.3}
                      type="bottom"
                      visible={isInView}
                      className="line"
                    />
                  </li>
                  <li>
                    <img src={icon2} alt="icon2" />
                    <div className="title">
                      <CountUp
                        duration={1.2}
                        end={6000}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={300}
                      />
                      개+
                    </div>
                    <motion.div
                      className="text"
                      variants={{
                        offscreen: {
                          opacity: 0,
                        },
                        onscreen: {
                          opacity: 1,
                          transition: {
                            delay: 1.8,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      셀러드 입점 업체수
                    </motion.div>
                    <Yline
                      width={213}
                      delay={1.2}
                      duration={0.3}
                      type="bottom"
                      visible={isInView}
                      className="line"
                    />
                  </li>
                  <li>
                    <img src={icon3} alt="icon3" />
                    <div className="title">
                      <CountUp
                        duration={1.2}
                        end={1}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={300}
                      />
                      억명+
                    </div>
                    <motion.div
                      className="text"
                      variants={{
                        offscreen: {
                          opacity: 0,
                        },
                        onscreen: {
                          opacity: 1,
                          transition: {
                            delay: 1.8,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      애드머스 누적 참여자수
                    </motion.div>
                    <Yline
                      width={213}
                      delay={1.5}
                      duration={0.3}
                      type="bottom"
                      visible={isInView}
                      className="line"
                    />
                  </li>
                  <li>
                    <img src={icon4} alt="icon4" />
                    <div className="title">
                      <CountUp
                        duration={1.2}
                        end={142}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={300}
                      />
                      %
                    </div>
                    <motion.div
                      className="text"
                      variants={{
                        offscreen: {
                          opacity: 0,
                        },
                        onscreen: {
                          opacity: 1,
                          transition: {
                            delay: 1.8,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      22년 매출 성장률
                    </motion.div>
                  </li>
                </ul>
              </motion.div>
              <BarBox
                width={267}
                height={200}
                className="bar-box"
                delay={1.5}
                fill={"#7C70E9"}
              />
              <motion.div
                className="ract-box"
                variants={{
                  offscreen: {
                    x: -100,
                    opacity: 0,
                  },
                  onscreen: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      delay: 1.5,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <XBar width={170} height={113} type="right" />
                <motion.svg width={170} height={113}>
                  <motion.circle cx={0} cy={56.5} r={56.5} fill={"#4F80FF"} />
                  <motion.circle cx={113} cy={56.5} r={56.5} fill={"#4F80FF"} />
                </motion.svg>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section3MoStyle} ref={ref}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Title
              title={
                <>
                  더욱 빠르게, 더 많이
                  <br />
                  혁신을 현실로
                </>
              }
              sub={
                <>
                  빠르게 변하는 환경에 앞서서 새로운 <br /> 기회를 창출하며 사업
                  규모를 확대해 나가고 있습니다.
                </>
              }
            />
            <div css={container}>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <ul className="list">
                  <li>
                    <div className="title">
                      <CountUp
                        duration={1.2}
                        end={750}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={300}
                      />
                      억+
                    </div>
                    <motion.div
                      className="text"
                      variants={{
                        offscreen: {
                          opacity: 0,
                        },
                        onscreen: {
                          opacity: 1,
                          transition: {
                            delay: 1.8,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      커머스 네트워크 GMV
                    </motion.div>
                  </li>
                  <li>
                    <div className="title">
                      <CountUp
                        duration={1.2}
                        end={6000}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={300}
                      />
                      개+
                    </div>
                    <motion.div
                      className="text"
                      variants={{
                        offscreen: {
                          opacity: 0,
                        },
                        onscreen: {
                          opacity: 1,
                          transition: {
                            delay: 1.8,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      셀러드 입점 업체수
                    </motion.div>
                  </li>
                  <li>
                    <div className="title">
                      <CountUp
                        duration={1.2}
                        end={130}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={300}
                      />
                      %+
                    </div>
                    <motion.div
                      className="text"
                      variants={{
                        offscreen: {
                          opacity: 0,
                        },
                        onscreen: {
                          opacity: 1,
                          transition: {
                            delay: 1.8,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      애드머스 누적 참여자수
                    </motion.div>
                  </li>
                  <li>
                    <div className="title">
                      <CountUp
                        duration={1.2}
                        end={142}
                        enableScrollSpy
                        scrollSpyOnce
                        scrollSpyDelay={300}
                      />
                      %+
                    </div>
                    <motion.div
                      className="text"
                      variants={{
                        offscreen: {
                          opacity: 0,
                        },
                        onscreen: {
                          opacity: 1,
                          transition: {
                            delay: 1.8,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      22년 매출 성장률
                    </motion.div>
                  </li>
                </ul>
              </motion.div>
              <BarBox
                width={123}
                height={91}
                className="bar-box"
                delay={1.5}
                fill={"#7C70E9"}
              />
              <motion.div
                className="ract-box"
                variants={{
                  offscreen: {
                    x: -100,
                    opacity: 0,
                  },
                  onscreen: {
                    opacity: 1,
                    x: 0,
                    transition: {
                      delay: 1.5,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <motion.svg width={135} height={90}>
                  <motion.circle cx={0} cy={45} r={45} fill={"#4F80FF"} />
                  <motion.circle cx={90} cy={45} r={45} fill={"#4F80FF"} />
                </motion.svg>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </Mobile>
    </>
  );
};

export default Section3;
