/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Contents from "components/Common/Contents";
import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import Xline from "components/Intraction/xLine";
import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import comeImage from "static/companyInfo/come.png";
import titleImage from "static/companyInfo/title.png";
import needWrap1Image from "static/companyInfo/needWrap_1.png";
import needWrap2Image from "static/companyInfo/needWrap_2.png";
import needWrap3Image from "static/companyInfo/needWrap_3.png";
import reward1Image from "static/companyInfo/reward_1.png";
import reward2Image from "static/companyInfo/reward_2.png";
import reward3Image from "static/companyInfo/reward_3.png";
import reward4Image from "static/companyInfo/reward_4.png";
import reward5Image from "static/companyInfo/reward_5.png";
import reward6Image from "static/companyInfo/reward_6.png";
import reward7Image from "static/companyInfo/reward_7.png";
import reward8Image from "static/companyInfo/reward_8.png";
import reward9Image from "static/companyInfo/reward_9.png";
import family1Image from "static/companyInfo/family_1.png";
import family2Image from "static/companyInfo/family_2.png";
import family3Image from "static/companyInfo/family_3.png";
import Bar from "components/Intraction/yBar";
import XBar from "components/Intraction/xBar";
import { Mobile, PC } from "components/Common/Responsive";
import { Helmet } from "react-helmet";

const CompanyInfo = () => {
  const firstRef = useRef(null);
  const secRef = useRef(null);
  const thirdRef = useRef(null);

  const isInFirstView = useInView(firstRef, { once: true, amount: 0.6 });
  const isInSecView = useInView(secRef, { once: true, amount: 0.6 });
  const isInThirdView = useInView(thirdRef, { once: true, amount: 0.6 });

  const oneRef = useRef(null);
  const twoRef = useRef(null);
  const threeRef = useRef(null);
  const fourRef = useRef(null);
  const fiveRef = useRef(null);
  const sixRef = useRef(null);
  const sevenRef = useRef(null);
  const eightRef = useRef(null);

  const isInOneView = useInView(oneRef, { once: true, amount: 0.6 });
  const isInTwoView = useInView(twoRef, { once: true, amount: 0.6 });
  const isInThreeView = useInView(threeRef, { once: true, amount: 0.6 });
  const isInFourView = useInView(fourRef, { once: true, amount: 0.6 });
  const isInFiveView = useInView(fiveRef, { once: true, amount: 0.6 });
  const isInSixView = useInView(sixRef, { once: true, amount: 0.6 });
  const isInSevenView = useInView(sevenRef, { once: true, amount: 0.6 });
  const isInEightView = useInView(eightRef, { once: true, amount: 0.6 });

  const companyInfoStyle = css`
    h1 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px; /* 130.769% */
      letter-spacing: -0.52px;
    }
    h3 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }

    .titleImage {
      position: relative;
      margin: 20px 95px 240px 95px;
    }
    .titleImage img {
      position: relative;
      width: 100%;
      border-radius: 22px;
      z-index: 3;
    }

    .titleImage .svg {
      position: absolute;
      top: -76px;
      right: 0;
      z-index: 1;
    }

    .needWrap {
      margin-top: 65px;
      margin-bottom: 240px;
    }
    .needWrap .line {
      width: 3px;
      height: 441px;
      background-color: #000;
      left: 0;
      position: absolute;
      transform-origin: center;
    }

    .needWrap .line.right {
      width: 3px;
      height: 441px;
      background-color: #000;
      right: 0;
      left: auto;
      position: absolute;
      transform-origin: center;
    }

    .needWrap div {
      position: relative;
    }
    .needWrap .content {
      height: 441px;
      margin-top: 85px;
      display: flex;
      justify-content: space-between;
    }
    .needWrap .info {
      padding-left: 93px;

      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .needWrap .info h1 {
      text-align: left;
      color: #4f80ff;
      font-family: Pretendard;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 129.412% */
    }
    .needWrap .info div {
      margin: 4px 0;
      color: #000;
      font-family: Pretendard;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 54px; /* 150% */
    }
    .needWrap .info span {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
    }

    .needWrap .image {
      position: relative;
    }
    .needWrap .image img {
      width: 441px;
      height: 441px;
      border-radius: 50%;
    }
    .needWrap .image svg {
      position: absolute;
      right: 213px;
      bottom: -9px;
    }

    .needWrap .left .image svg {
      left: 213px;
    }

    .needWrap .left .info {
      border-left: 0;
      padding-left: 0;
      padding-right: 93px;
    }
    .history {
      margin-top: 92px;
      height: 1750px;
      margin-bottom: 242px;
      position: relative;
    }
    .history .left {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: -12px;
    }
    .history .center_left {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      bottom: 425px;
    }
    .history .center_right {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      bottom: 700px;
    }
    .history .right {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .history .lines {
      position: absolute;
      left: 274px;
      width: calc(100% - 300px);
    }
    .history .lines .info {
      display: flex;
      position: absolute;
      left: 8px;
    }
    .history .lines .info div {
      flex: 1;
      margin-left: 40px;
      margin-top: -12px;
    }
    .history .lines .info div h1 {
      color: #000;
      font-family: Pretendard;
      text-align: left;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 129.412% */
    }
    .history .lines .info ul {
      margin-top: 20px;
    }
    .history .lines .info ul li {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px;
      letter-spacing: -0.16px;
    }
    .history .lines .info ul strong {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
      margin-right: 5px;
    }
    .history .lines .info:nth-of-type(1) {
      position: absolute;
      background-color: #000;
      width: 3px;
      height: 1624px;
      top: 2px;
    }
    .history .lines .info:nth-of-type(2) {
      top: -7px;
    }
    .history .lines .info:nth-of-type(3) {
      top: 200px;
    }
    .history .lines .info:nth-of-type(4) {
      top: 432px;
    }
    .history .lines .info:nth-of-type(5) {
      top: 659px;
    }
    .history .lines .info:nth-of-type(6) {
      top: 866px;
    }
    .history .lines .info:nth-of-type(7) {
      top: 1073px;
    }
    .history .lines .info:nth-of-type(8) {
      top: 1255px;
    }
    .history .lines .info:nth-of-type(9) {
      top: 1445px;
    }
    .history .lines .info:nth-of-type(10) {
      top: 1615px;
    }
    .family {
      width: 1029px;
      margin: 120px auto 240px auto;
    }
    .family > .line {
      display: flex;
      justify-content: space-between;
    }
    .family > .line > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .family > .line > div > div:first-child {
      cursor: pointer;
    }
    .family > .line div img {
      width: auto;
      height: 241px;
      margin-bottom: 48px;
    }
    .family > .line div .name {
      min-width: 179px;
      height: 53.945px;
      border-radius: 40.868px;
      background-color: #f8f8f8;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;
      padding: 0 30px;
    }
    .family > .line div .name span {
      color: #000;
      font-family: Pretendard;
      font-size: 22.886px;
      font-style: normal;
      font-weight: 700;
      line-height: 27.79px; /* 121.429% */
      letter-spacing: -0.229px;
    }
    .family > .line div > div .title {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 170% */
      letter-spacing: -0.2px;
      margin-bottom: 12px;
    }

    .family > .line div > div .text {
      color: rgba(0, 0, 0, 0.5);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
      text-align: left;
      display: list-item;
      list-style-type: disc;
    }
    .family > .line div > div .text::marker {
      font-size: 12px;
    }
    .reward {
      width: 1029px;
      margin: 90px auto 240px auto;
    }
    .reward > .line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;
    }
    .reward > .line:last-of-type {
      margin-bottom: 0px;
    }
    .reward > .line div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
    }
    .reward > .line div img {
      width: auto;
      height: 121px;
    }
    .reward > .line div span {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
      display: block;
      margin-top: 20px;
    }
  `;
  const companyInfoMoStyle = css`
    h1 {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px; /* 150% */
      letter-spacing: -0.64px;
    }
    h3 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.16px;
      height: auto;
    }
    .titleImage {
      position: relative;
      margin-top: 60px;
      margin-bottom: 92px;
    }
    .titleImage img {
      position: relative;
      width: 100%;
      border-radius: 8px;
      z-index: 3;
    }
    .titleImage svg {
      position: absolute;
      top: -30px;
      right: 0;
      z-index: 1;
    }

    .needWrap {
      margin-top: 23px;
      margin-bottom: 80px;
    }
    .needWrap > div {
      height: auto;
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .needWrap > div:first-of-type {
      margin-top: 24px;
    }
    .needWrap .info {
      padding-left: 33px;
      padding-right: 37px;
      border-left: 3px solid #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .needWrap .info h1 {
      color: var(--black, #000);
      font-family: Pretendard;
      text-align: left;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
      letter-spacing: -0.2px;
    }
    .needWrap .info div {
      color: var(--foundation-blue-normal, #4f80ff);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
      margin-bottom: 12px;
    }
    .needWrap .info span {
      color: var(--black, #000);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
    }

    .needWrap .image {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 12px;
    }
    .needWrap .image img {
      width: 259px;
      height: 259px;
      border-radius: 50%;
    }
    .needWrap .image svg {
      position: absolute;
      left: 50%;
      bottom: -9px;
    }

    .needWrap .left {
      border-left: 0;
    }
    .needWrap .left .image svg {
      left: 0;
    }
    .needWrap .left .info {
      border-left: 0;
      padding-left: 33px;
      padding-right: 37px;
      border-right: 3px solid #000;
    }
    .history {
      margin-top: 92px;
      height: 1550px;
      margin-bottom: 242px;
      position: relative;
    }
    .history .left {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      top: -12px;
    }
    .history .center_left {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      bottom: 425px;
    }
    .history .center_right {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0;
      bottom: 700px;
    }
    .history .right {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .history .lines {
      position: absolute;
      left: 274px;
      width: calc(100% - 300px);
    }
    .history .lines .info {
      display: flex;
      position: absolute;
      left: 8px;
    }
    .history .lines .info div {
      flex: 1;
      margin-left: 40px;
      margin-top: -12px;
    }
    .history .lines .info div h1 {
      color: #000;
      font-family: Pretendard;
      text-align: left;
      font-size: 34px;
      font-style: normal;
      font-weight: 700;
      line-height: 44px; /* 129.412% */
    }
    .history .lines .info ul {
      margin-top: 20px;
    }
    .history .lines .info ul li {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px;
      letter-spacing: -0.16px;
    }
    .history .lines .info ul strong {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
      margin-right: 5px;
    }
    .history .lines .info:nth-of-type(1) {
      position: absolute;
      background-color: #000;
      width: 3px;
      height: 1424px;
      top: 2px;
    }
    .history .lines .info:nth-of-type(2) {
      top: -7px;
    }
    .history .lines .info:nth-of-type(3) {
      top: 200px;
    }
    .history .lines .info:nth-of-type(4) {
      top: 402px;
    }
    .history .lines .info:nth-of-type(5) {
      top: 659px;
    }
    .history .lines .info:nth-of-type(6) {
      top: 866px;
    }
    .history .lines .info:nth-of-type(7) {
      top: 1073px;
    }
    .history .lines .info:nth-of-type(8) {
      top: 1247px;
    }
    .history .lines .info:nth-of-type(9) {
      top: 1415px;
    }
    .family {
      width: auto;
      margin: 40px 10px 90px 10px;
      display: flex;
      justify-content: center;
    }

    .family > .line > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 36px;
    }
    .family > .line > div:last-child {
      margin-bottom: 0;
    }
    .family > .line > div > div:first-child {
      cursor: pointer;
    }
    .family > .line div img {
      width: auto;
      height: 241px;
      margin-bottom: 24px;
    }
    .family > .line div .name {
      min-width: 179px;
      height: 53.945px;
      border-radius: 40.868px;
      background-color: #f8f8f8;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 18px;
      padding: 0 30px;
    }

    .family > .line div .name span {
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.2px;
    }
    .family > .line div > div .title {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.16px;
      margin-bottom: 12px;
      text-align: center;
    }
    .family > .line div > div .text {
      color: #000;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
      letter-spacing: -0.14px;
      text-align: left;
      display: list-item;
      list-style-type: disc;
    }
    .family > .line div > div .text::marker {
      font-size: 8px;
    }
    /* ------------------------------ */
    .reward {
      width: auto;
      margin: 40px 10px 90px 10px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .reward > .line {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .reward > .line div {
      display: flex;
      margin-top: 21px;
      width: 50%;
      flex-direction: column;
      align-items: center;
    }
    .reward > .line div img {
      width: auto;
      height: 63px;
    }
    .reward > .line div span {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      display: block;
      margin-top: 10px;
    }
  `;

  return (
    <>
      <Helmet>
        <title>
          회사 소개 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>
      <Layout>
        <PC>
          <PageTitle
            titleText="차세대 플랫폼 기업, 지니웍스"
            subTitleText="차세대 혁신적인 테크 기반 플랫폼을 선도하는 지니웍스입니다."
            contentsTitleText={`상상을 현실로 만드는 \n 기술 기반 플랫폼 기업입니다`}
            contentsSubTitleText={`지니웍스는 커머스, 마케팅, 여행 등의 분야에서 혁신을 만드는 플랫폼 기업으로,\n22년 지속적인 성장 가능성을 인정받아 시리즈B 투자를 유치하였습니다. 플랫폼과 \n데이터의 가치를 중심으로 고객과 파트너사, 구성원이 함께 성장하는 기업입니다.`}
            titleImage={comeImage}
          />
          <Contents>
            <div css={companyInfoStyle}>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
              >
                <div className="titleImage">
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <img src={titleImage} alt="titleImage" />
                  </motion.div>
                  <motion.div
                    className="svg"
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.6,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <Bar width={86} height={93} fill={"#7C70E9"} />
                  </motion.div>
                </div>
              </motion.div>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
              >
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <h1>
                    기술을 넘어
                    <br />
                    동반 성장을 추구합니다
                  </h1>
                </motion.div>
              </motion.div>
              <div className="needWrap">
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.6 }}
                >
                  <motion.div
                    style={{
                      transformOrigin: "center",
                    }}
                    variants={{
                      offscreen: {
                        y: 50,
                        scaleX: 1,
                        scaleY: 0,
                      },
                      onscreen: {
                        y: 0,
                        scaleY: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <div className="line" />
                  </motion.div>
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <div className="content" ref={firstRef}>
                      <div className="info">
                        <h1>Technology</h1>
                        <div>기술을 생각하는 가치</div>
                        <span>
                          커머스 네트워크 기술, 추천 알고리즘, 마케팅 자동화 및
                          광고
                          <br />
                          데이터 결합 등 우리만의 기술 역량을 강화하기 위해
                          플랫폼
                          <br />
                          연구에 투자를 지속하여 기술에 대한 가치를 높이고
                          있습니다.
                        </span>
                      </div>
                      <div className="image">
                        <img src={needWrap1Image} alt="needWrap1Image" />
                        <Xline
                          width={357}
                          delay={0.6}
                          type="left"
                          visible={isInFirstView}
                        />
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.6 }}
                >
                  <motion.div
                    style={{
                      transformOrigin: "center",
                    }}
                    variants={{
                      offscreen: {
                        y: 100,
                        scaleX: 1,
                        scaleY: 0,
                      },
                      onscreen: {
                        y: 0,
                        scaleY: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <div className="line right" />
                  </motion.div>
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <div className="content left" ref={secRef}>
                      <div className="image">
                        <img src={needWrap2Image} alt="needWrap2Image" />
                        <Xline width={357} delay={0.6} visible={isInSecView} />
                      </div>
                      <div className="info">
                        <h1>Partner</h1>
                        <div>제휴 파트너를 생각하는 가치</div>
                        <span>
                          파트너가 추구하는 목표에 따라 최적의 솔루션을 함께
                          고민하고
                          <br />
                          제안합니다. 기술 이전에 본질을 먼저 바라보고 지속
                          가능한 성장
                          <br />
                          가치를 만들고자 노력합니다.
                          <br />
                        </span>
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.6 }}
                >
                  <motion.div
                    style={{
                      transformOrigin: "center",
                    }}
                    variants={{
                      offscreen: {
                        y: 50,
                        scaleX: 1,
                        scaleY: 0,
                      },
                      onscreen: {
                        y: 0,
                        scaleY: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <div className="line" />
                  </motion.div>
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.3,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <div className="content" ref={thirdRef}>
                      <div className="info">
                        <h1>Member</h1>
                        <div>지니웍스 구성원을 생각하는 가치</div>
                        <span>
                          혼자서는 어렵지만, 함께 모여 도전하면 상상 이상의
                          결과를 낼 수
                          <br />
                          있습니다. 하나의 비전을 그리며 끊임없이 소통함으로써
                          기업과
                          <br />
                          구성원 모두가 함께 성장할 수 있도록 노력합니다.
                        </span>
                      </div>
                      <div className="image">
                        <img src={needWrap3Image} alt="needWrap3Image" />
                        <Xline
                          width={357}
                          delay={0.6}
                          visible={isInThirdView}
                          type="left"
                        />
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              </div>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
              >
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <h1>
                    앞으로도 지속될
                    <br />
                    성장을 기록합니다
                  </h1>
                </motion.div>
              </motion.div>

              <div className="history">
                <div className="left">
                  <motion.svg width={226} height={226}>
                    <motion.circle cx={113} cy={113} r={113} fill={"#7C70E9"} />
                  </motion.svg>
                  <XBar
                    width={226}
                    height={226}
                    type="right"
                    fill={"#7C70E9"}
                  />
                </div>
                <div className="center_left">
                  <motion.svg width={200} height={200}>
                    <motion.circle cx={0} cy={0} r={200} fill={"#4F80FF"} />
                  </motion.svg>
                  <XBar
                    width={200}
                    height={200}
                    type="right"
                    fill={"#4F80FF"}
                  />
                </div>
                <div className="center_right">
                  <XBar width={240} height={160} type="left" fill={"#4F80FF"} />
                  <motion.svg width={240} height={160}>
                    <motion.circle cx={80} cy={80} r={80} fill={"#4F80FF"} />
                    <motion.circle cx={240} cy={80} r={80} fill={"#4F80FF"} />
                  </motion.svg>
                </div>
                <div className="right">
                  <motion.svg width={220} height={220}>
                    <motion.circle cx={220} cy={220} r={220} fill={"#7C70E9"} />
                  </motion.svg>
                </div>
                <div className="lines">
                  <div className="info"></div>
                  <motion.div
                    ref={oneRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInOneView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2023</h1>
                      <ul>
                        <li>
                          <strong>10</strong>한국관광협회중앙회 관광의날 기념
                          관광산업 발전 표창
                        </li>
                        <li>
                          <strong>06</strong>대한민국 리딩기업대상 3년 연속
                          서비스 혁신기업 선정
                        </li>
                        <li>
                          <strong>03</strong>트래블테크 기업
                          (주)오예커뮤니케이션 인수
                        </li>
                        <li>
                          <strong>02</strong>미디어커머스 기업 ㈜리빙픽 인수
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    ref={oneRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInOneView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2022</h1>
                      <ul>
                        <li>
                          <strong>12</strong>국가정보화 유공 과학기술정보통신부
                          장관 표창
                        </li>
                        <li>
                          <strong>10</strong>중소기업기술혁신대전 중소벤처기업부
                          장관 표창
                        </li>
                        <li>
                          <strong>06</strong>중소벤처기업부 ‘아기유니콘200
                          육성사업’ 대상기업 선정
                        </li>
                        <li>
                          <strong>06</strong>시리즈 B 투자 유치 (LG U+,
                          한국투자파트너스 , TS 인베스트먼트 )
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    ref={twoRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInTwoView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2021</h1>
                      <ul>
                        <li>
                          <strong>12</strong> 유통업 상생협력 문화확산사업
                          산업통상자원부 장관상 수상
                        </li>
                        <li>
                          <strong>10</strong> 한경비즈니스 2021 소비자만족지수
                          1위
                        </li>
                        <li>
                          <strong>02</strong> 국가 디지털 전환사업
                          과학기술정보통신부 장관상 수상
                        </li>
                        <li>
                          <strong>01</strong> CES 2021 K-startup 관 참가
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    ref={threeRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInThreeView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2020</h1>
                      <ul>
                        <li>
                          <strong>12</strong> CJ ONE 쇼핑타운 셀러드 업무 제휴
                          계약
                        </li>
                        <li>
                          <strong>10</strong> 셀러드, LG U+ 커머스 업무 제휴
                          계약
                        </li>
                        <li>
                          <strong>09</strong> 애드머스, 해피포인트 게임 이벤트
                          업무 제휴 계약
                        </li>
                        <li>
                          <strong>08</strong> INNOBIZ(기술혁신형 중소기업) 인증
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    ref={fourRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInFourView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2019</h1>
                      <ul>
                        <li>
                          <strong>06</strong>코리아세일페스타 프로모션 주관 기업
                          선정
                        </li>
                        <li>
                          <strong>02</strong>참좋은여행 사이트 리뉴얼 프로젝트
                        </li>
                        <li>
                          <strong>01</strong>서울시 우수기업브랜드 ‘하이브랜드’
                          선정
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    ref={fiveRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInFiveView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2018</h1>
                      <ul>
                        <li>
                          <strong>11</strong> 프로모션 마케팅 플랫폼 ‘애드머스’
                          론칭
                        </li>
                        <li>
                          <strong>09</strong> 중소벤처기업부
                          혁신형기업기술개발사업 선정
                        </li>
                        <li>
                          <strong>08</strong> 중소벤처기업부 주관 글로벌
                          스타벤처 육성 사업자 선정
                        </li>
                        <li>
                          <strong>06</strong> 카 케어 O2O 플랫폼 기업
                          (주)오토카지 분할 설립
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    ref={sixRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInSixView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2017</h1>
                      <ul>
                        <li>
                          <strong>12</strong>정보통신산업진흥원 S/W프로슈머
                          평가사업 우수상 수상
                        </li>
                        <li>
                          <strong>06</strong>삼성페이 ‘찬스’ 서비스 론칭
                        </li>
                        <li>
                          <strong>03</strong>삼성페이 쇼핑 커머스 플랫폼 제휴 및
                          운영
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    ref={sevenRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInSevenView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2016</h1>
                      <ul>
                        <li>
                          <strong>11</strong>KT 제16회 모바일 기술 대상 수상
                        </li>
                        <li>
                          <strong>07</strong>시리즈A 투자유치 (삼성벤처투자,
                          나우IB)
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                  <motion.div
                    ref={eightRef}
                    initial="offscreen"
                    className="info"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.6 }}
                  >
                    <Xline width={280} delay={0.3} visible={isInEightView} />
                    <motion.div
                      variants={{
                        offscreen: {
                          y: 100,
                          opacity: 0,
                        },
                        onscreen: {
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 0.3,
                            duration: 0.3,
                          },
                        },
                      }}
                    >
                      <h1>2015</h1>
                      <ul>
                        <li>
                          <strong>11</strong>앱어워드코리아 대상 수상
                        </li>
                        <li>
                          <strong>06</strong>지니웍스 창업
                        </li>
                      </ul>
                    </motion.div>
                  </motion.div>
                </div>
              </div>
              {/*  */}
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
              >
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <h1 style={{ marginBottom: "20px" }}>Genieworks Family</h1>
                  <h3>
                    다양한 영역의 관계사들과 동반 성장하며, 시장 생태계를
                    혁신하고 있습니다.
                  </h3>
                </motion.div>
              </motion.div>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
              >
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <div className="family">
                    <div className="line">
                      <div>
                        <div
                          onClick={() =>
                            window.open("https://www.atzcare.com/main/main.az")
                          }
                        >
                          <img src={family1Image} alt="family1Image" />
                        </div>
                        <div className="name">
                          <span>오토카지</span>
                        </div>
                        <div>
                          <div className="title">카케어 O2O 플랫폼</div>
                          <div className="text">
                            프리미엄 카 케어의 모든 것!
                            <br />
                            디테일링, 출장세차, 대리운전,
                            <br />
                            수행기사, 탁송 등
                          </div>
                          <div className="text">
                            모바일 O2O 플랫폼 기반 전국
                            <br />
                            220여 개 지점망 보유
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          onClick={() =>
                            window.open("https://www.livingpick.com/")
                          }
                        >
                          <img src={family2Image} alt="family2Image" />
                        </div>
                        <div className="name">
                          <span>리빙픽</span>
                        </div>
                        <div>
                          <div className="title">생활용품 버티컬 플랫폼</div>
                          <div className="text">
                            일상을 더 편리하게 만들 리빙 플랫폼
                          </div>
                          <div className="text">
                            리빙 특화 상품을 넘어, 생활 밀착형의
                            <br />
                            콘텐츠 및 서비스 제안
                          </div>
                          <div className="text">
                            '23년 누적 매출액 2,000억 달성
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          onClick={() => window.open(" https://www.oye.co.kr/")}
                        >
                          <img src={family3Image} alt="family3Image" />
                        </div>
                        <div className="name">
                          <span>오예커뮤니케이션 </span>
                        </div>
                        <div>
                          <div className="title">트래블테크 솔루션</div>
                          <div className="text">
                            여행 시장을 혁신하는 B2B2C 트래블
                            <br />
                            테크 기업
                          </div>
                          <div className="text">
                            400여 국내 중소여행사가 이용하는
                            <br />
                            ERP 솔루션, 'OAAS’ 개발 및 운영
                          </div>
                          <div className="text">
                            종합여행 플랫폼 ‘트래뷰’ 운영 및
                            <br />
                            자체 상품 개발/판매
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
              >
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <h1>대외 수상 내역</h1>
                </motion.div>
              </motion.div>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
              >
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <div className="reward">
                    <div className="line">
                      <div>
                        <img src={reward2Image} alt="reward2Image" />
                        <span>
                          국가정보화 선도 유공
                          <br />
                          과학기술정보통신부 장관 표창
                        </span>
                      </div>
                      <div>
                        <img src={reward2Image} alt="reward2Image" />
                        <span>
                          중소기업 기술혁신 유공
                          <br />
                          중소벤처기업부 장관 표창
                        </span>
                      </div>
                      <div>
                        <img src={reward1Image} alt="reward1Image" />
                        <span>
                          대한민국 리딩기업대상
                          <br />
                          3년 연속 서비스 혁신기업
                        </span>
                      </div>
                      <div>
                        <img src={reward9Image} alt="reward9Image" />
                        <span>
                          관광의날 기념 한국관광협회중앙회
                          <br />
                          관광산업 발전 표창
                        </span>
                      </div>
                    </div>
                    {/*  */}

                    <div className="line">
                      <div>
                        <img src={reward2Image} alt="reward2Image" />
                        <span>
                          유통 상생협력 문화 확산
                          <br />
                          산업통상자원부 장관 표창
                        </span>
                      </div>
                      <div>
                        <img src={reward3Image} alt="reward3Image" />
                        <span>
                          2021 한국 소비자
                          <br />
                          만족지수 1위
                        </span>
                      </div>
                      <div>
                        <img src={reward2Image} alt="reward2Image" />
                        <span>
                          국가디지털전환사업
                          <br />
                          과학기술정보통신부 장관상
                        </span>
                      </div>
                      <div>
                        <img src={reward4Image} alt="reward4Image" />
                        <span>
                          기술혁신형중소기업
                          <br />
                          이노비즈 인증
                        </span>
                      </div>
                    </div>
                    <div className="line">
                      <div>
                        <img src={reward6Image} alt="reward6Image" />
                        <span>
                          SW프로슈머 평가사업
                          <br />
                          우수상
                        </span>
                      </div>
                      <div>
                        <img src={reward5Image} alt="reward5Image" />
                        <span>
                          서울시 우수기업
                          <br />
                          하이브랜드 선정
                        </span>
                      </div>
                      <div>
                        <img src={reward7Image} alt="reward7Image" />
                        <span>
                          앱어워드코리아
                          <br />
                          생활서비스 부분 대상
                        </span>
                      </div>
                      <div>
                        <img src={reward8Image} alt="reward8Image" />
                        <span>
                          대한민국 기업대상
                          <br />
                          서비스 혁신 대상
                        </span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </Contents>
        </PC>
        <Mobile>
          <PageTitle
            titleText="차세대 플랫폼 기업, 지니웍스"
            subTitleText="차세대 혁신적인 테크 기반 플랫폼을 선도하는 지니웍스입니다."
            contentsTitleText={`상상을 현실로 만드는 \n 기술 기반 플랫폼 기업입니다`}
            contentsSubTitleText={`지니웍스는 커머스, 마케팅, 여행 등의 분야에서 혁신을 만드는 플랫폼 기업으로,22년 지속적인 성장 가능성을 인정받아 시리즈B 투자를 유치하였습니다`}
            titleImage={comeImage}
          />
          <Contents>
            <div css={companyInfoMoStyle}>
              <div className="titleImage">
                <img src={titleImage} alt="titleImage" />
                <Bar width={39} height={37} fill={"#7C70E9"} />
              </div>
              <h1>
                기술을 넘어
                <br />
                동반 성장을 추구합니다
              </h1>
              <div className="needWrap">
                <div>
                  <div className="info">
                    <h1>Technology</h1>
                    <div>기술을 생각하는 가치</div>
                    <span>
                      지니웍스는 커머스, 마케팅, 여행 등의 분야에서 혁신을
                      만드는 플랫폼 기업으로, 22년 지속적인 성장 가능성을
                      인정받아 시리즈B 투자를 유치하였습니다
                    </span>
                  </div>
                  <div className="image">
                    <img src={needWrap1Image} alt="needWrap1Image" />
                    <Xline width={166} delay={0} />
                  </div>
                </div>
                <div className="left">
                  <div className="info">
                    <h1>Partner</h1>
                    <div>제휴 파트너를 생각하는 가치</div>
                    <span>
                      파트너가 추구하는 목표에 따라 최적의 솔루션을 함께
                      고민하고
                      <br />
                      제안합니다. 기술 이전에 본질을 먼저 바라보고 지속 가능한
                      성장
                      <br />
                      가치를 만들고자 노력합니다.
                      <br />
                    </span>
                  </div>
                  <div className="image">
                    <img src={needWrap2Image} alt="needWrap2Image" />
                    <Xline width={166} delay={0} type="left" />
                  </div>
                </div>
                <div>
                  <div className="info">
                    <h1>Member</h1>
                    <div>지니웍스 구성원을 생각하는 가치</div>
                    <span>
                      혼자서는 어렵지만, 함께 모여 도전하면 상상 이상의 결과를
                      낼 수
                      <br />
                      있습니다. 하나의 비전을 그리며 끊임없이 소통함으로써
                      기업과
                      <br />
                      구성원 모두가 함께 성장할 수 있도록 노력합니다.
                    </span>
                  </div>
                  <div className="image">
                    <img src={needWrap3Image} alt="needWrap3Image" />
                    <Xline width={166} delay={0} />
                  </div>
                </div>
              </div>
              <h1 style={{ marginBottom: "20px" }}>Genieworks Family</h1>
              <h3>
                다양한 영역의 관계사들과 동반 성장하며, 시장 생태계를 혁신하고
                있습니다.
              </h3>
              <div className="family">
                <div className="line">
                  <div>
                    <div
                      onClick={() =>
                        window.open("https://www.atzcare.com/main/main.az")
                      }
                    >
                      <img src={family1Image} alt="family1Image" />
                    </div>
                    <div className="name">
                      <span>오토카지</span>
                    </div>
                    <div>
                      <div className="title">카케어 O2O 플랫폼</div>
                      <div className="text">
                        프리미엄 카 케어의 모든 것!
                        <br />
                        디테일링, 출장세차, 대리운전,
                        <br />
                        수행기사, 탁송 등
                      </div>
                      <div className="text">
                        모바일 O2O 플랫폼 기반 전국
                        <br />
                        220여 개 지점망 보유
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      onClick={() => window.open("https://www.livingpick.com/")}
                    >
                      <img src={family2Image} alt="family2Image" />
                    </div>
                    <div className="name">
                      <span>리빙픽</span>
                    </div>
                    <div>
                      <div className="title">생활용품 버티컬 플랫폼</div>
                      <div className="text">
                        일상을 더 편리하게 만들 리빙 플랫폼
                      </div>
                      <div className="text">
                        리빙 특화 상품을 넘어, 생활 밀착형의
                        <br />
                        콘텐츠 및 서비스 제안
                      </div>
                      <div className="text">'23년 누적 매출액 2,000억 달성</div>
                    </div>
                  </div>
                  <div>
                    <div onClick={() => window.open(" https://www.oye.co.kr/")}>
                      <img src={family3Image} alt="family3Image" />
                    </div>
                    <div className="name">
                      <span>오예커뮤니케이션 </span>
                    </div>
                    <div>
                      <div className="title">트래블테크 솔루션</div>
                      <div className="text">
                        여행 시장을 혁신하는 B2B2C 트래블
                        <br />
                        테크 기업
                      </div>
                      <div className="text">
                        400여 국내 중소여행사가 이용하는
                        <br />
                        ERP 솔루션, 'OAAS’ 개발 및 운영
                      </div>
                      <div className="text">
                        종합여행 플랫폼 ‘트래뷰’ 운영 및
                        <br />
                        자체 상품 개발/판매
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h1>대외 수상 내역</h1>
              <div className="reward">
                <div className="line">
                  <div>
                    <img src={reward1Image} alt="reward1Image" />
                    <span>
                      대한민국 리딩기업대상
                      <br />
                      3년 연속 서비스 혁신기업
                    </span>
                  </div>
                  <div>
                    <img src={reward2Image} alt="reward2Image" />
                    <span>
                      유통 상생협력 문화 확산
                      <br />
                      산업통상자원부 장관 표창
                    </span>
                  </div>
                  <div>
                    <img src={reward3Image} alt="reward3Image" />
                    <span>
                      한국 소비자 만족 지수
                      <br />
                      1위
                    </span>
                  </div>
                  <div>
                    <img src={reward4Image} alt="reward4Image" />
                    <span>
                      기술혁신형중소기업
                      <br />
                      이노비즈 인증
                    </span>
                  </div>
                </div>
                <div className="line">
                  <div>
                    <img src={reward2Image} alt="reward2Image" />
                    <span>
                      중소기업 기술혁신 유공
                      <br />
                      산중소벤처기업부 장관 표창
                    </span>
                  </div>

                  <div>
                    <img src={reward6Image} alt="reward6Image" />
                    <span>
                      SW프로슈머 평가사업
                      <br />
                      우수상
                    </span>
                  </div>
                  <div>
                    <img src={reward2Image} alt="reward2Image" />
                    <span>
                      국가정보화 선도 유공
                      <br />
                      과학기술정보통신부 장관 표창
                    </span>
                  </div>
                  <div>
                    <img src={reward7Image} alt="reward7Image" />
                    <span>
                      앱어워드코리아
                      <br />
                      생활서비스 부분 대상
                    </span>
                  </div>
                </div>
                <div className="line">
                  <div>
                    <img src={reward2Image} alt="reward2Image" />
                    <span>
                      국가디지털전환사업
                      <br />
                      과학기술정보통신부 장관상
                    </span>
                  </div>
                  <div>
                    <img src={reward5Image} alt="reward5Image" />
                    <span>
                      서울시 우수기업
                      <br />
                      하이브랜드 선정
                    </span>
                  </div>
                  <div>
                    <img src={reward8Image} alt="reward8Image" />
                    <span>
                      대한민국 기업대상
                      <br />
                      서비스 혁신 대상
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Contents>
        </Mobile>
      </Layout>
    </>
  );
};

export default CompanyInfo;
