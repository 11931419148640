/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Mobile, PC } from "components/Common/Responsive";
import SectionTitle from "components/Common/SectionTitle";
import Xline from "components/Intraction/xLine";
import { motion } from "framer-motion";
import arrow from "static/marketing/arrow.png";
import circle from "static/marketing/circle.png";

const Section2 = () => {
  const section2Style = css`
    display: flex;
    align-items: center;
    justify-content: center;

    .arrow {
      width: 426px;
      height: 491px;
      margin-right: 28px;
    }
    .circle {
      width: 228px;
      height: 228px;
      margin-right: 46px;
    }
    .box_wrap {
      display: flex;
      flex-direction: column;
      width: 344px;
      height: 330px;
      justify-content: space-between;
    }
    .box_wrap > div {
      display: flex;
      position: relative;
      justify-content: flex-end;
      align-items: center;
    }
    .box_wrap > div span {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.48px;
    }
    .box_wrap > div > div {
      position: relative;
      width: 263px;
      flex-shrink: 0;
      border-radius: 20px;
      border: 8px solid #f4f2ff;
      background: #fff;
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.2px;
      text-align: center;
      padding: 22px 0;
    }
    .box_wrap > div svg {
      position: absolute;
      z-index: 1;
      left: 0;
    }
  `;
  const section2MoStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .arrow {
      width: 426px;
      height: 491px;
      margin-right: 28px;
    }
    .circle {
      width: 132px;
      height: 132px;
      margin-top: 60px;
    }
    .box_wrap {
      display: flex;
      margin-top: 60px;
      flex-direction: column;
      width: 344px;
      height: 250px;
      justify-content: space-between;
    }
    .box_wrap > div {
      display: flex;
      position: relative;
      justify-content: flex-end;
      align-items: center;
    }
    .box_wrap > div span {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: -0.48px;
    }
    .box_wrap > div > div {
      position: relative;
      width: 243px;
      flex-shrink: 0;
      border-radius: 20px;
      border: 8px solid #f4f2ff;
      background: #fff;
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -0.2px;
      text-align: center;
      padding: 16px 0;
    }
    .box_wrap > div svg {
      position: absolute;
      z-index: 1;
      right: 241px;
    }
  `;
  return (
    <>
      <PC>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <motion.div
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <SectionTitle
              mainText={`데이터와 기술로 완성하는\n압도적인 마케팅 성과`}
              subText={`지니웍스만의 기술이 결합된 애드 테크 플랫폼으로\n더 정확하고 효율적으로 마케팅 성과를 달성합니다.`}
              marginTop={127}
            />
          </motion.div>
          <div css={section2Style}>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.6,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img className="arrow" src={arrow} alt={"arrow"} />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.9,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img className="circle" src={circle} alt={"circle"} />
            </motion.div>
            <div className="box_wrap">
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 1.2,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <Xline width={106} type="left" />
                <div>System Integration</div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 1.5,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <Xline width={106} type="left" />
                <div>Data Analyzing</div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 1.8,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <Xline width={106} type="left" />
                <div>
                  AI <span>(Targeting & Curation)</span>
                </div>
              </motion.div>
            </div>
          </div>
        </motion.div>
      </PC>
      <Mobile>
        <SectionTitle
          mainText={`데이터와 기술로 완성하는\n압도적인 마케팅 성과`}
          subText={`지니웍스만의 기술이 결합된 애드 테크 플랫폼으로\n더 정확하고 효율적으로 마케팅 성과를 달성합니다.`}
          marginTop={90}
        />
        <div css={section2MoStyle}>
          <img className="circle" src={circle} alt={"circle"} />
          <div className="box_wrap">
            <div>
              <Xline width={85} type="left" />
              <div>System Integration</div>
            </div>
            <div>
              <Xline width={85} type="left" />
              <div>Data Analyzing</div>
            </div>
            <div>
              <Xline width={85} type="left" />
              <div>
                AI <span>(Targeting & Curation)</span>
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section2;
