/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import part1Image from "static/commerce/part1.png";
import part2Image from "static/commerce/part2.png";
import part3Image from "static/commerce/part3.png";
import arrowUp from "static/commerce/arrow_up.svg";
import { ReactComponent as ArrowDown } from "static/commerce/arrow_down.svg";
import arrowDownMo from "static/commerce/arrow_down.png";
import arrowRight from "static/commerce/arrow_right.svg";
// import arrowLeft from "static/commerce/arrow_left.svg";
import arrowLeft from "static/commerce/arrow_left.png";
import { Mobile, PC } from "components/Common/Responsive";
import SectionTitle from "components/Common/SectionTitle";
import { motion } from "framer-motion";

const Section2 = () => {
  const Section2Style = css`
    margin-top: 20px;
    margin-bottom: 240px;
    text-align: center;
    position: relative;
    span {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }
    .part {
      padding-top: 66px;
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    .part {
      .first {
        > div {
          width: 125px;
          position: absolute;
          top: 144px;
          right: -125px;
          z-index: 10;
          right: -140px;
          img {
            width: 100% !important;
          }
        }
      }
      .sec {
        > div {
          width: 125px;
          position: absolute;
          top: 144px;
          right: -106px;
          z-index: 10;

          img {
            width: 100% !important;
            margin: 0 !important;
          }
        }
      }
    }

    .part > div {
      position: relative;
      width: calc(33% - 78px);
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .part img:nth-of-type(2) {
    }
    .part > div:nth-of-type(1) img:first-of-type {
      width: 273px;
      margin-bottom: 30px;
    }
    .part > div:nth-of-type(2) img:first-of-type {
      width: 207px;
      margin-top: 43px;
      margin-bottom: 19px;
    }
    .part > div:nth-of-type(3) img:first-of-type {
      width: auto;
      height: 283px;
      margin-bottom: 21px;
    }
    .part h1 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
    }
    .part ul {
      margin-top: 14px;
    }
    .part ul li {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
      list-style: disc;
      text-align: left;
    }
    .animation {
      position: absolute;
      right: 152px;
    }
    .animation img {
      position: absolute;
    }
    .animation svg:nth-of-type(1) {
      position: absolute;
      right: -17px;
      top: 19px;
    }
    .animation img:nth-of-type(1) {
      width: 383px;
      right: 39px;
      top: 79px;
    }
    .animation img:nth-of-type(2) {
      right: 414px;
      top: -25px;
    }
    .animation span {
      position: absolute;
      right: -42px;
      width: 66px;
      top: 65px;
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }
    .animation ul {
      position: absolute;
      width: max-content;
      right: 148px;
      bottom: -70px;
    }
    .animation li {
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px;
      letter-spacing: -0.16px;
      list-style: disc;
    }
  `;
  const Section2MoStyle = css`
    margin-top: 20px;
    margin-bottom: 90px;
    text-align: center;
    position: relative;
    span {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }
    .part {
      padding-top: 66px;
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .part > div {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
    }

    .part img:nth-of-type(2) {
      position: absolute;
      top: 100px;
      left: 39px;
      z-index: 10;
      width: auto;
      height: 32px;
    }

    .part > div:nth-of-type(1) img:first-of-type {
      width: 88px;
    }
    .part > div:nth-of-type(2) {
      margin-top: 48px;
    }
    .part > div:nth-of-type(2) img:first-of-type {
      width: 88px;
    }
    .part > div:nth-of-type(3) {
      margin-top: 48px;
    }
    .part > div:nth-of-type(3) img:first-of-type {
      width: 88px;
    }
    .part h1 {
      color: #000;
      text-align: left;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      margin-bottom: 10px;
    }
    .part ul {
      margin-top: 14px;
      padding-left: 35px;
    }
    .part ul li {
      color: #000;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      text-align: left;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
    }
  `;
  return (
    <>
      <PC>
        <div css={Section2Style}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <SectionTitle mainText={`네트워크형 커머스 플랫폼, 셀러드`} />
              <span>
                상품을 다양한 경로로 빠르게 확산시키는 일,
                <br />
                셀러드가 지향하는 모델입니다.
              </span>
            </motion.div>
          </motion.div>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <div className="part">
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="first">
                  <img src={part1Image} alt="part1Image" />
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.6,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <img src={arrowRight} alt="arrowRight" />
                  </motion.div>
                  <h1>셀러(seller)</h1>
                  <ul>
                    <li>셀러드 SCM을 통한 상품 등록</li>
                    <li>플랫폼간 상품 API 연동</li>
                  </ul>
                </div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.9,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="sec">
                  <img src={part2Image} alt="part2Image" />
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 1.2,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <img src={arrowRight} alt="arrowRight" />
                  </motion.div>
                  <h1>셀러드</h1>
                  <ul>
                    <li>채널별 쇼핑몰 생성</li>
                    <li>상품 전시/운영/프로모션</li>
                  </ul>
                </div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 1.5,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <img src={part3Image} alt="part3Image" />
                <h1>채널(channel)</h1>
                <ul>
                  <li>채널별 쇼핑몰 오픈</li>
                  <li>채널 고객 대상 타겟팅(Push, SMS)</li>
                </ul>
              </motion.div>
            </div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 1.8,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="animation">
                <ArrowDown />
                <img src={arrowLeft} alt="arrowLeft" />
                <img src={arrowUp} alt="arrowUp" />
                <span>상품 구매</span>
                <ul>
                  <li>구매 고객 데이터 수집</li>
                  <li>데이터 기반 상품 추천</li>
                </ul>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <SectionTitle mainText={`네트워크형 커머스\n플랫폼, 셀러드`} />
        <div css={Section2MoStyle}>
          <span>
            상품을 다양한 경로로 빠르게 확산시키는 일,
            <br />
            셀러드가 지향하는 모델입니다.
          </span>
          <div className="part">
            <div>
              <img src={part1Image} alt="part1Image" />
              <img src={arrowDownMo} alt="arrowDownMo" />

              <ul>
                <h1>셀러(seller)</h1>
                <li>- 셀러드 SCM을 통한 상품 등록</li>
                <li>- 플랫폼간 상품 API 연동</li>
              </ul>
            </div>
            <div>
              <img src={part2Image} alt="part2Image" />
              <img src={arrowDownMo} alt="arrowDownMo" />

              <ul>
                <h1>셀러드</h1>
                <li>- 채널별 쇼핑몰 생성</li>
                <li>- 상품 전시/운영/프로모션</li>
              </ul>
            </div>
            <div>
              <img src={part3Image} alt="part3Image" />

              <ul>
                <h1>채널(channel)</h1>
                <li>- 채널별 쇼핑몰 오픈</li>
                <li>- 채널 고객 대상 타겟팅(Push, SMS)</li>
              </ul>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section2;
