export const isRequired = (value: string) =>
  value !== "" || value !== undefined || value !== null;

export const isPhoneFormat = (value: string) => {
  if (value === "") return true;
  var phoneRule = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
  return phoneRule.test(value);
};

export const isEmailFormat = (value: string) => {
  if (value === "") return true;
  const exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
  return exptext.test(value);
};

export const isMinLength = (value: string, max: number) => {
  if (value === "") return true;
  return value.length >= max;
};

export const isMaxLength = (value: string, max: number) => {
  if (value === "") return true;
  return value.length <= max;
};
