/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Mobile, PC } from "components/Common/Responsive";
import Bar from "components/Intraction/yBar";
import { motion } from "framer-motion";
import React from "react";
import commonArrowRight from "static/common/arrow_right.svg";
import { container } from "styles";

const Section5 = () => {
  const section5Style = css`
    > div {
      padding: 130px 0;
    }
    .title {
      font-size: 52px;
      font-weight: 700;
      line-height: 68px;
    }
    button {
      border-radius: 80px;
      padding: 12px 49px 12px 49px;
      border: 0;
      background: #4f80ff;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      margin-top: 40px;
    }
    .right {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  `;

  const section5MoStyle = css`
    > div {
      padding: 60px 20px 120px;
      background-color: #f8f8f8;
    }
    .title {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      line-height: 48px;
    }
    button {
      border-radius: 80px;
      padding: 5px 30px 5px 49px;
      border: 0;
      background: #4f80ff;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      font-weight: bold;
      margin: 30px auto 0 auto;
    }
    .right {
      position: absolute;
      right: 20px;
      bottom: 0;
    }
  `;

  return (
    <>
      <PC>
        <div css={section5Style}>
          <motion.div
            css={container}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <motion.div
              className="title"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              함께 상상을 현실로 만들어 갈 <br />
              동료를 기다립니다.
            </motion.div>
            <motion.button
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.6,
                    duration: 0.3,
                  },
                },
              }}
              onClick={() =>
                window.open(
                  "https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/genieworks"
                )
              }
            >
              채용 공고
              <img src={commonArrowRight} alt="commonArrowRight" />
            </motion.button>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.9,
                    duration: 0.3,
                  },
                },
              }}
              className="right"
            >
              <motion.svg width={230} height={345}>
                <motion.circle cx={115} cy={115} r={115} fill={"#4F80FF"} />
                <motion.circle cx={115} cy={345} r={115} fill={"#4F80FF"} />
              </motion.svg>
              <Bar width={230} height={345} />
            </motion.div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section5MoStyle}>
          <div css={container}>
            <div className="title">
              함께 상상을 현실로 만들어갈 동료를 기다립니다.
            </div>
            <button
              onClick={() =>
                window.open(
                  "https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/genieworks"
                )
              }
            >
              채용 공고
              <img src={commonArrowRight} alt="commonArrowRight" />
            </button>
            <div className="right">
              <motion.svg width={60} height={90}>
                <motion.circle cx={30} cy={30} r={30} fill={"#4F80FF"} />
                <motion.circle cx={30} cy={90} r={30} fill={"#4F80FF"} />
              </motion.svg>
              <Bar width={60} height={90} />
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section5;
