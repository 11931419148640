import Layout from "components/Common/Layout";
import Section1 from "components/Main/Section1";
import Section2 from "components/Main/Section2";
import Section3 from "components/Main/Section3";
import Section4 from "components/Main/Section4";
import Section5 from "components/Main/Section5";
import MainTitle from "components/Main/Title";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!</title>
      </Helmet>
      <Layout theme="blue">
        <MainTitle />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
      </Layout>
    </>
  );
};

export default Home;
