import { motion } from "framer-motion";
import React from "react";

interface XLineProps {
  width: number;
  delay?: number;
  duration?: number;
  type?: "right" | "left";
  visible?: boolean;
  fill?: string;
}

export const Xline = ({
  width,
  delay = 0,
  duration = 0.5,
  type = "right",
  visible = true,
  fill = "#000",
}: XLineProps) => {
  return (
    <motion.svg
      width={width}
      height="20"
      viewBox={`0 0 ${width} 20`}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
    >
      <motion.circle
        cx={type === "right" ? "2" : width - 4}
        cy="10"
        r="2"
        fill={fill}
        variants={{
          hidden: { pathLength: 0, opacity: 0 },
          visible: {
            pathLength: 1,
            opacity: 1,
            translateX: type === "right" ? width - 4 : -width + 6,
            transition: {
              translateX: {
                delay,
                duration,
              },
              opacity: { delay, duration },
            },
          },
        }}
        custom={1}
      />
      <motion.line
        x1={type === "right" ? 2 : width - 2}
        y1="10"
        x2={type === "right" ? width - 2 : 2}
        y2="10"
        stroke={fill}
        variants={{
          hidden: { pathLength: 0, opacity: 0 },
          visible: {
            pathLength: 1,
            opacity: 1,
            transition: {
              pathLength: { delay, duration },
              opacity: { delay, duration },
            },
          },
        }}
        custom={2}
      />
    </motion.svg>
  );
};

export default Xline;
