/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { tabandmo } from "styles/media";

interface ContentsProps {
  children: React.ReactNode;
  top?: number;
}
const Contents = ({ children, top = 70 }: ContentsProps) => {
  const contentsStyle = css`
    width: 1140px;
    margin: 0 auto;
    padding-top: ${top}px;
    position: relative;
    ${tabandmo} {
      padding: 0 20px;
      width: 100%;
    }
  `;
  return <div css={contentsStyle}>{children}</div>;
};

export default Contents;
