import { motion } from "framer-motion";

interface XBarProps {
  width: number;
  height: number;
  fill?: string;
  delay?: number;
  duration?: number;
  type?: "right" | "left";
  visible?: "visible" | "hidden";
}

export const XBar = ({
  width,
  height,
  type = "left",
  fill = "#4F80FF",
  visible = "visible",
}: XBarProps) => {
  return (
    <motion.svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      initial="hidden"
      animate={visible}
    >
      <motion.circle
        cx={type === "left" ? height / 2 : width - height / 2}
        cy={height / 2}
        r={height / 2}
        fill={fill}
        custom={1}
      />
      <motion.rect
        width={width - height / 2}
        height={height}
        x={type === "left" ? height / 2 : 0}
        y={0}
        rx={0}
        fill={fill}
        custom={2}
      />
    </motion.svg>
  );
};

export default XBar;
