/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { container } from "styles";
import Title from "components/Common/Title";
import bannerImg1 from "static/main/banner1.png";
import bannerImg2 from "static/main/banner2.png";
import bannerImg3 from "static/main/banner3.png";
import mListImg1 from "static/main/m_list_1.png";
import mListImg2 from "static/main/m_list_2.png";
import mListImg3 from "static/main/m_list_3.png";
import { motion, useInView } from "framer-motion";
import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import Yline from "components/Intraction/yLine";
import Arc from "components/Intraction/arc";
import { Mobile, PC } from "components/Common/Responsive";
import { useMediaQuery } from "react-responsive";

const Section2 = () => {
  const section2Style = css`
    padding: 120px 0;
    background: #7c70e9;

    .motion-container {
      margin-top: 35px;
      height: 480px;
      overflow: hidden;
      .img-box {
        > .slick-list {
          width: 680px;
          height: 480px;
          border-top-right-radius: 320px;
          border-bottom-right-radius: 320px;
          background-size: cover;
          position: relative;
          z-index: 3;
          .list {
            width: 680px;
            height: 480px;
          }
          img {
            height: 100%;
          }
        }
        .slick-prev,
        .slick-next {
          display: none !important;
        }
      }
      .slide {
        width: 482px;
        height: 482px;
        background: #fff;
        border-radius: 50%;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        .slick-prev,
        .slick-next {
          display: none !important;
        }

        .list {
          padding: 102px 89px;
          .number {
            color: #4f80ff;
            font-size: 36px;
            line-height: 54px;
          }
          .title {
            font-size: 36px;
            font-weight: bold;
            margin-top: 16px;
          }
          .text {
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
            letter-spacing: -0.16px;
            margin-top: 10px;
          }
        }
      }
      .slick-dots {
        bottom: 113px;
        padding-left: 86px;
        text-align: left;
        li {
          width: 13px;
          border-radius: 50%;
          height: 13px;
          border: 2px solid rgba(0, 0, 0, 0.3);

          margin: 0 12px;
          button {
            width: 13px;
            height: 13px;
            padding: 0;
          }
          button::before {
            display: none;
          }
          &:first-of-type {
            margin-left: 0;
          }
          &.slick-active {
            border: 2px solid #000;
          }
        }
      }
      .right-shape {
        position: absolute;
        top: -20px;
        right: 0;
        .line {
          position: absolute;
          left: 50%;
          bottom: 50%;
          transform: translate(-15px, 4px);
        }
      }
    }
  `;

  const sectionMo2Style = css`
    padding: 60px 20px;
    background: #7c70e9;

    .list {
      margin-top: 30px;
      li {
        .number {
          color: #4f80ff;
          font-size: 36px;
          line-height: 54px;
        }
        .title {
          font-size: 20px;
          font-weight: bold;
          margin-top: 24px;
          color: #fff;
        }
        .text {
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          letter-spacing: -0.16px;
          margin-top: 10px;
          color: #fff;
        }
        .img {
          img {
            width: 100%;
          }
        }
      }
      li + li {
        margin-top: 60px;
      }
    }
  `;

  const ref = useRef(null);
  const slideRef = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.6 });
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  const imgSliderRef = useRef<Slider | null>(null);
  const slideSliderRef = useRef<Slider | null>(null);

  const [imgBoxActiveIndex, setImgBoxActiveIndex] = useState(0);
  const [slideActiveIndex, setslideActiveIndex] = useState(0);

  useEffect(() => {
    if (slideRef !== null && !isMobile) {
      if (isInView) {
        (slideSliderRef as any).current.slickPlay();
      } else {
        (slideSliderRef as any).current.slickPause();
      }
    }
  }, [isInView]);

  const imgSettings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    afterChange: (currentSlide: any) => {
      setImgBoxActiveIndex(currentSlide);
      if (slideSliderRef.current !== null) {
        slideSliderRef.current.slickGoTo(currentSlide);
      }
    },
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide: any) => {
      setslideActiveIndex(currentSlide);
      if (imgSliderRef.current !== null) {
        imgSliderRef.current.slickGoTo(currentSlide, true);
      }
    },
  };

  return (
    <>
      <PC>
        <div css={section2Style} ref={ref}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Title
              title={
                <>
                  플랫폼을 통한 혁신,
                  <br />
                  지니웍스 서비스로 시작합니다
                </>
              }
              sub={
                <>
                  커머스 네트워크, 프로모션 마케팅, 스마트 투어
                  <br />
                  기술 기반 플랫폼으로 새로운 성장 가치를 실현하고 있습니다.
                </>
              }
              theme="white"
            />

            <div css={container}>
              <div className="motion-container">
                <motion.div
                  variants={{
                    offscreen: {
                      x: "-100%",
                    },
                    onscreen: {
                      x: 0,
                      opacity: 1,
                      transition: {
                        delay: 0.3,
                        duration: 0.5,
                      },
                    },
                  }}
                >
                  <Slider
                    {...imgSettings}
                    className="img-box"
                    ref={(slider) => (imgSliderRef.current = slider)}
                  >
                    <div>
                      <div className="list">
                        <img src={bannerImg1} alt="bannerImg1" />
                      </div>
                    </div>
                    <div>
                      <div className="list">
                        <img src={bannerImg2} alt="bannerImg2" />
                      </div>
                    </div>
                    <div>
                      <div className="list">
                        <img src={bannerImg3} alt="bannerImg3" />
                      </div>
                    </div>
                  </Slider>
                </motion.div>
                <motion.div
                  variants={{
                    offscreen: {
                      opacity: 0,
                    },
                    onscreen: {
                      opacity: 1,
                      transition: {
                        delay: 0.8,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <Slider
                    ref={(slider) => (slideSliderRef.current = slider)}
                    {...settings}
                    className="slide"
                  >
                    <div>
                      <div className="list">
                        <div className="number">01</div>
                        <div className="title">커머스 네트워크</div>
                        <div className="text">
                          국내 유일의 NO.1 커머스 네트워크 플랫폼, 고객 이
                          모여있는 곳에 쇼핑 서비스를 제공하며, 셀러와 매체
                          모두에게 강점이 되는 국내 유일의 커머스 <br />
                          네트워크 플랫폼입니다.
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="list">
                        <div className="number">02</div>
                        <div className="title">프로모션 마케팅</div>
                        <div className="text">
                          맞춤형 이벤트 제작 플랫폼 애드머스, 게임형 리워드
                          플랫폼 지니펀, 커머스 기반의 통합 마케팅 솔루션까지
                          지니웍스 고유의 마케팅 솔루션으로 비즈니스 성장을
                          이끌어갑니다.
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="list">
                        <div className="number">03</div>
                        <div className="title">스마트 투어</div>
                        <div className="text">
                          여행 산업은 이전과 달라질 수 있습니다. 여행사통합 관리
                          솔루션부터 글로벌 파트너와 연동된 ERP 시스템 등 기술을
                          기반으로 여행업을 혁신합니다.
                        </div>
                      </div>
                    </div>
                  </Slider>
                  <div className="right-shape">
                    <Yline
                      width={100}
                      delay={1.2}
                      duration={0.3}
                      type="bottom"
                      visible={isInView}
                      className="line"
                      fill="#fff"
                    />
                    <Arc
                      width={110}
                      height={110}
                      visible={isInView}
                      delay={0.9}
                      fill={"#CAC5FB"}
                    />
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={sectionMo2Style} ref={ref}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
          >
            <Title
              title={
                <>
                  플랫폼을 통한 혁신,
                  <br />
                  지니웍스 서비스로 시작
                </>
              }
              sub={
                <>
                  커머스 네트워크, 프로모션 마케팅, 여행 플랫폼,
                  <br />
                  기술 기반 플랫폼으로 새로운 성장 가치를
                  <br /> 실현하고 있습니다.
                </>
              }
              theme="white"
            />

            <div css={container}>
              <motion.div
                variants={{
                  offscreen: {
                    opacity: 0,
                  },
                  onscreen: {
                    opacity: 1,
                    transition: {
                      delay: 0.8,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <ul className="list">
                  <li>
                    <div className="img">
                      <img src={mListImg1} alt="list" />
                    </div>
                    <div className="title">1. 커머스 네트워크</div>
                    <div className="text">
                      국내 유일의 NO.1 커머스 네트워크 플랫폼, 고객이 모여있는
                      곳에 쇼핑 서비스를 제공하며, 셀러와 매체 모두에게 강점이
                      되는 국내 유일의 커머스 네트워크 플랫폼입니다.
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={mListImg2} alt="list" />
                    </div>
                    <div className="title">2. 프로모션 마케팅</div>
                    <div className="text">
                      맞춤형 이벤트 제작 플랫폼 애드머스, 게임형 리워드 플랫폼
                      지니펀, 커머스 기반의 통합 마케팅 솔루션까지 지니웍스
                      고유의 마케팅 솔루션으로 비즈니스 성장을 이끌어갑니다.
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src={mListImg3} alt="list" />
                    </div>
                    <div className="title">3. 여행 플랫폼</div>
                    <div className="text">
                      여행 산업은 이전과 달라질 수 있습니다. 여행사통합 관리
                      솔루션부터 글로벌 파트너와 연동된 ERP 시스템 등 기술을
                      기반으로 여행업을 혁신합니다.
                    </div>
                  </li>
                </ul>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </Mobile>
    </>
  );
};

export default Section2;
