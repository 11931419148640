/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import React from "react";
import { container } from "styles";
import company1 from "static/esg/company1.png";
import company2 from "static/esg/company2.png";
import company3 from "static/esg/company3.png";
import company4 from "static/esg/company4.png";

import { Mobile, PC } from "components/Common/Responsive";
import Title from "components/Common/Title";

const Section3 = () => {
  const section3Style = css`
    margin-top: 219px;
    .cont:nth-of-type(1) {
      margin-top: 211px;
    }
    .list {
      display: flex;
      flex-direction: column;
      margin-top: 163px;
      li {
        display: flex;
        flex-shrink: 0;
        .img {
          width: 346px;
          border-top: 2px solid #7c70e9;
          padding: 69px 103px;
          margin-right: 20px;
          img {
            width: 100%;
          }
        }
        .info {
          border-top: 2px solid rgba(124, 112, 233, 0.1);
          padding: 53px 10px;
          width: calc(100% - 346px - 20px);
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            color: #7c70e9;
            text-align: left;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 42px;
            margin-bottom: 10px;
          }
          .text {
            text-align: left;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            display: list-item;
            list-style-type: disc;
          }
          .text::marker {
            font-size: 12px;
          }
        }
      }
    }
  `;

  const section3MoStyle = css`
    margin-top: 90px;
    padding-bottom: 90px;
    .list {
      li {
        &:first-of-type {
          margin-top: 90px;
        }
        margin-top: 40px;
        margin: 40px auto 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
          width: 200px;
          img {
            width: 100%;
          }
        }
        .info {
          margin-top: 20px;
          padding: 0 100px;
          .title {
            color: #7c70e9;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px; /* 150% */
            margin-bottom: 10px;
          }
          .text {
            text-align: left;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 150% */
            display: list-item;
            list-style-type: disc;
            margin-bottom: 8px;
          }
          .text::marker {
            font-size: 12px;
          }
        }
      }
    }
  `;

  return (
    <>
      <PC>
        <div css={section3Style}>
          <div css={container}>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              <Title
                title={<>관계사 사례</>}
                sub={
                  <>
                    더 나은 미래를 위해, 사회적 임팩트를 확대하고 지속가능한
                    성장을 추구합니다.
                  </>
                }
              />
            </motion.div>
            <ul className="list">
              <li>
                <div className="img">
                  <img src={company1} alt="company1" />
                </div>
                <div className="info">
                  <div className="title">지니웍스</div>
                  <div>
                    <div className="text">
                      지역 소상공인 등 상대적으로 디지털 환경에 취약한
                      입점사들의 손쉬운 디지털 전환과 <br />
                      판로 개척 지원으로 2021년 산업부 장관 표창 수상
                    </div>
                    <div className="text">
                      코로나로 위축된 지역 관광 산업 부흥 및 상권 활성화를 위한
                      지역 스마트 쇼핑관광 서비스 구축
                    </div>
                    <div className="text">
                      국내 소비 촉진을 위한 코리아 세일 페스타 연계 이벤트
                      제작/운영
                    </div>
                    <div className="text">
                      정규직 채용 확대, 청년 및 경력 단절 여성 우선 채용
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="img">
                  <img src={company2} alt="company2" />
                </div>
                <div className="info">
                  <div className="title">오토카지</div>
                  <div>
                    <div className="text">
                      신규 가맹점주의 안정적 사업 운영을 위한 전문 교육 및 혜택
                      지원
                    </div>
                    <div className="text">
                      환경 오염은 최소화, 고객 만족은 최대화하는 워터리스 출장
                      세차 서비스 운영
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="img">
                  <img src={company3} alt="company3" />
                </div>
                <div className="info">
                  <div className="title">리빙픽</div>
                  <div>
                    <div className="text">
                      친환경/비건 상품 우선 소싱 및 판촉 이벤트, 관련 콘텐츠
                      제작 및 홍보를 통한 고객 가치 소비 지향
                    </div>
                    <div className="text">
                      친환경 이벤트를 통한 기후 위기 경각심 제고, 친환경 가치
                      공유 및 확산
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="img">
                  <img src={company4} alt="company4" />
                </div>
                <div className="info">
                  <div className="title">오예커뮤니케이션</div>
                  <div>
                    <div className="text">
                      국내 중소 여행사 디지털 전환 및 업무 환경 개선을 위한 ERP
                      시스템 개발 및 공급
                    </div>
                    <div className="text">
                      글로벌 OTA 대항, 토종 OTA의 경쟁력을 강화할 수 있는 공동
                      상품 및 다양한 서비스 개발, 운영
                    </div>
                    <div className="text">
                      비행을 통해 배출된 만큼의 탄소를 상쇄할 수 있는 친환경
                      항공예약 서비스 '세이브에어’ 개발 및 운영
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </PC>
      <Mobile>
        {/* 모바일 버전 작업 필요 */}
        <div css={section3MoStyle}>
          <div css={container}>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              <Title
                title={<>관계사 사례</>}
                sub={
                  <>
                    더 나은 미래를 위해, 사회적 임팩트를 확대하고 지속가능한
                    성장을 추구합니다.
                  </>
                }
              />
            </motion.div>
            <ul className="list">
              <li>
                <div className="img">
                  <img src={company1} alt="company1" />
                </div>
                <div className="info">
                  <div className="title">지니웍스</div>
                  <div>
                    <div className="text">
                      지역 소상공인 등 상대적으로 디지털 환경에 취약한
                      입점사들의 손쉬운 디지털 전환과
                      <br />
                      판로 개척 지원으로 2021년 산업부 장관 표창 수상
                    </div>
                    <div className="text">
                      코로나로 위축된 지역 관광 산업 부흥 및 상권 활성화를 위한
                      지역 스마트 쇼핑관광 서비스 구축
                    </div>
                    <div className="text">
                      국내 소비 촉진을 위한 코리아 세일 페스타 연계 이벤트
                      제작/운영
                    </div>
                    <div className="text">
                      정규직 채용 확대, 청년 및 경력 단절 여성 우선 채용
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="img">
                  <img src={company2} alt="company2" />
                </div>
                <div className="info">
                  <div className="title">오토카지</div>
                  <div>
                    <div className="text">
                      신규 가맹점주의 안정적 사업 운영을 위한 전문 교육 및 혜택
                      지원
                    </div>
                    <div className="text">
                      환경 오염은 최소화, 고객 만족은 최대화하는 워터리스 출장
                      세차 서비스 운영
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="img">
                  <img src={company3} alt="company3" />
                </div>
                <div className="info">
                  <div className="title">리빙픽</div>
                  <div>
                    <div className="text">
                      친환경/비건 상품 우선 소싱 및 판촉 이벤트, 관련 콘텐츠
                      제작 및 홍보를 통한 고객 가치 소비 지향
                    </div>
                    <div className="text">
                      친환경 이벤트를 통한 기후 위기 경각심 제고, 친환경 가치
                      공유 및 확산
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="img">
                  <img src={company4} alt="company4" />
                </div>
                <div className="info">
                  <div className="title">오예커뮤니케이션</div>
                  <div>
                    <div className="text">
                      국내 중소 여행사 디지털 전환 및 업무 환경 개선을 위한 ERP
                      시스템 개발 및 공급
                    </div>
                    <div className="text">
                      글로벌 OTA 대항, 토종 OTA의 경쟁력을 강화할 수 있는 공동
                      상품 및 다양한 서비스 개발, 운영
                    </div>
                    <div className="text">
                      비행을 통해 배출된 만큼의 탄소를 상쇄할 수 있는 친환경
                      항공예약 서비스 '세이브에어’ 개발 및 운영
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section3;
