/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Title from "components/Common/Title";
import React from "react";
import { container } from "styles";
import people1 from "static/employment/people1.png";
import people2 from "static/employment/people2.png";
import people3 from "static/employment/people3.png";
import people4 from "static/employment/people4.png";
import { Mobile, PC } from "components/Common/Responsive";
import Slider from "react-slick";
import { motion } from "framer-motion";

const Section3 = () => {
  const section3Style = css`
    padding-bottom: 160px;
    .list {
      display: flex;
      align-items: center;
      margin-top: 160px;
      li {
        text-align: center;
        padding: 73px 30px 34px;
        text-align: left;
        border-radius: 10px;
        width: 270px;
        height: 358px;
        .title {
          font-size: 18px;
          font-weight: bold;
          line-height: 28px;
        }
        .text {
          margin-top: 10px;
          line-height: 26px;
          font-size: 16px;
        }
        .name {
          font-size: 14px;
          line-height: 20px;
          margin-top: 10px;
        }
        background-color: #f8f8f8;
        position: relative;
      }
      img {
        position: absolute;
        left: 50%;
        top: -50px;

        margin-left: -50px;
      }
      li + li {
        margin-left: 20px;
      }
    }
  `;

  const section3MoStyle = css`
    padding-top: 40px;
    .slick-list {
      padding-top: 50px;
      width: 270px;
      height: 358px;
      margin: 0 auto;
    }
    .slide_wrap {
      padding: 35px 50px 40px 50px;
    }
    .list {
      display: flex;
      align-items: center;

      .list-slide {
        text-align: center;
        padding: 73px 30px 34px;
        text-align: left;
        border-radius: 10px;
        width: 100%;
        .title {
          font-size: 18px;
          font-weight: bold;
          line-height: 28px;
        }
        .text {
          margin-top: 10px;
          line-height: 26px;
          font-size: 16px;
        }
        .name {
          font-size: 14px;
          line-height: 20px;
          margin-top: 10px;
        }
        background-color: #f8f8f8;
        position: relative;
      }
      img {
        position: absolute;
        left: 50%;
        top: -50px;

        margin-left: -50px;
      }
      li + li {
        margin-left: 20px;
      }
    }
    .slick-dots {
      display: none !important;
    }
    .slick-next {
      ::before {
        color: #000;
      }
    }

    .slick-prev {
      ::before {
        color: #000;
      }
    }
  `;

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <PC>
        <div css={section3Style}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Title
              title={
                <>
                  함께 답을 찾아가는
                  <br />
                  지니웍스 구성원의 이야기
                </>
              }
            />
            <div css={container}>
              <ul className="list">
                <motion.li
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: 0.6,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <img src={people1} alt="people1" />
                  <div className="title">
                    믿고 의지할 수 있는 동료가
                    <br />
                    최고의 복지
                  </div>
                  <div className="text">
                    지니웍스에는 다양한 팀들이 협업하고 있는데요, 서로 협업하고
                    이해하려는 분위기가 너무 좋아요. 니일 내일 구분 없이 서로
                    협업하는 분위기가 최고의 복지입니다!
                  </div>
                  <div className="name">Producgt Manager / 지은</div>
                </motion.li>
                <motion.li
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: 0.8,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <img src={people2} alt="people2" />
                  <div className="title">
                    빠르게 성장하는 회사에서
                    <br />
                    주도적으로 일하기
                  </div>
                  <div className="text">
                    지니웍스는 빠르게 성장하고 있기에 이것저것 변화하는 것들이
                    많아요. 적응의 어려움도 있지만, 그만큼 새롭게 도전하며
                    성장할 수 있는 기회도 크다는 것이 가장 큰 장점입니다.
                  </div>
                  <div className="name">Front Engineer / 승혁</div>
                </motion.li>
                <motion.li
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: 1.0,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <img src={people3} alt="people3" />
                  <div className="title">새로운 플랫폼을 만드는 기회</div>
                  <div className="text">
                    지니웍스에서는 기존에 없던 플랫폼으로 커머스와 마케팅 툴
                    시장에서 성장 중입니다. 무엇이든 해볼 수 있는 기회가
                    열려있고, 그러한 곳에서 구성원으로서 함께 할 수 있다는 점이
                    가장 큰 장점입니다
                  </div>
                  <div className="name">Backend Engineer / 예진</div>
                </motion.li>
                <motion.li
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      opacity: 1,
                      y: 0,
                      transition: {
                        delay: 1.2,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <img src={people4} alt="people4" />
                  <div className="title">불필요한 눈치가 없는회사</div>
                  <div className="text">
                    회사마다 다양한 분위기가 있지만, 지니웍스처럼 다양한
                    구성원들이 모여있지만 눈치보지 않고 주도적으로 업무를 할 수
                    있는 곳은 없었어요. 눈치보느라 불필요하게 업무를 하는 일은
                    없어요!
                  </div>
                  <div className="name">Product Manager / 상범</div>
                </motion.li>
              </ul>
            </div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section3MoStyle}>
          <Title
            title={
              <>
                함께 답을 찾아가는
                <br />
                지니웍스 구성원의 이야기
              </>
            }
          />
          <div css={container} className="slide_wrap">
            <Slider {...settings} className="list slide">
              <div className="list-slide">
                <img src={people1} alt="people1" />
                <div className="title">
                  믿고 의지할 수 있는 동료가
                  <br /> 최고의 복지
                </div>
                <div className="text">
                  지니웍스에는 다양한 팀들이 협업하고 있는데요, 서로 협업하고
                  이해하려는 분위기가 너무 좋아요. 니일 내일 구분 없이 서로
                  협업하는 분위기가 최고의 복지입니다!
                </div>
                <div className="name">Producgt Manager / 지은</div>
              </div>
              <div className="list-slide">
                <img src={people2} alt="people2" />
                <div className="title">
                  빠르게 성장하는 회사에서
                  <br />
                  주도적으로 일하기
                </div>
                <div className="text">
                  지니웍스는 빠르게 성장하고 있기에 이것저것 변화하는 것들이
                  많아요. 적응의 어려움도 있지만, 그만큼 새롭게 도전하며 성장할
                  수 있는 기회도 크다는 것이 가장 큰 장점입니다.
                </div>
                <div className="name">Front Engineer / 승혁</div>
              </div>
              <div className="list-slide">
                <img src={people3} alt="people3" />
                <div className="title">새로운 플랫폼을 만드는 기회</div>
                <div className="text">
                  지니웍스에서는 기존에 없던 플랫폼으로 커머스와 마케팅 툴
                  시장에서 성장 중입니다. 무엇이든 해볼 수 있는 기회가 열려있고,
                  그러한 곳에서 구성원으로서 함께 할 수 있다는 점이 가장 큰
                  장점입니다
                </div>
                <div className="name">Backend Engineer / 예진</div>
              </div>
              <div className="list-slide">
                <img src={people4} alt="people4" />
                <div className="title">불필요한 눈치가 없는회사</div>
                <div className="text">
                  회사마다 다양한 분위기가 있지만, 지니웍스처럼 다양한
                  구성원들이 모여있지만 눈치보지 않고 주도적으로 업무를 할 수
                  있는 곳은 없었어요. 눈치보느라 불필요하게 업무를 하는 일은
                  없어요!
                </div>
                <div className="name">Product Manager / 상범</div>
              </div>
            </Slider>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section3;
