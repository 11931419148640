/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import section4 from "static/message/section3.png";
import React from "react";
import { container } from "styles";
import { tabandmo } from "styles/media";
import arrowRight from "static/common/arrow_right.svg";
import { useNavigate } from "react-router-dom";

const Section4 = () => {
  const navigator = useNavigate();
  const section4Style = css`
    margin-top: 120px;
    background: #f2f0ff;
    padding: 153px 0;
    .title {
      text-align: center;
      font-size: 52px;
      line-height: 68px;
      font-weight: 700;
    }
    .text {
      text-align: center;
      margin-top: 20px;
      line-height: 28px;
    }
    img {
      margin-top: 51px;
      width: 100%;
    }
    .btn_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 240px;
      button {
        border-radius: 80px;
        padding: 13px 7px 13px 28px;
        width: 265px;
        border: 0;
        background: #4f80ff;
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
      }
      img {
        margin-top: 0;
        width: auto;
      }
    }

    ${tabandmo} {
      margin-top: 80px;
      padding: 80px 0px;
      .title {
        font-size: 32px;
        line-height: 48px;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
      }
      .cont {
        padding: 0 20px;
      }
      img {
        margin-top: 10px;
      }
      .btn_wrap {
        margin-top: 20px;
        button {
          border-radius: 80px;
          padding: 5px 30px 5px 49px;
          border: 0;
          background: #4f80ff;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          display: flex;
          align-items: center;
          font-weight: bold;
          margin: 30px auto 0 auto;
        }
      }
    }
  `;
  return (
    <div css={section4Style}>
      <div className="title">활용 사례</div>
      <div className="text">통신사 문자광고 활용 사례</div>
      <div css={container} className="cont">
        <img src={section4} alt="section4" />
      </div>
      <div className="btn_wrap">
        <button onClick={() => navigator("/inquiry?type=3")}>
          문의하기
          <img src={arrowRight} alt="arrowRight" />
        </button>
      </div>
    </div>
  );
};

export default Section4;
