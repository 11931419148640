/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Contents from "components/Common/Contents";
import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import Xline from "components/Intraction/xLine";
import { motion } from "framer-motion";
import React, { useRef } from "react";
import comeImage from "static/commerce/come.png";

import Section1 from "components/Commerce/Section1";
import Section2 from "components/Commerce/Section2";
import Section3 from "components/Commerce/Section3";
import Section4 from "components/Commerce/Section4";
import Section5 from "components/Commerce/Section5";
import { Mobile, PC } from "components/Common/Responsive";
import { Helmet } from "react-helmet";

const Commerce = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          커머스 네트워크 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를
          바꾸다!
        </title>
      </Helmet>
      <PC>
        <PageTitle
          titleText="커머스 네트워크 플랫폼"
          subTitleText="잠재 고객을 보유한 다양한 채널과의 네트워크를 이어 갑니다."
          contentsTitleText={`하나의 플랫폼으로\n더 많은 고객을 만나는 커머스 솔루션`}
          contentsSubTitleText={`상품 공급자와 커머스 서비스를 연결하며 각 파트너의 공동 성장을 추구하는\n국내 유일의 커머스 네트워크 플랫폼입니다. `}
          titleImage={comeImage}
          fill="#7C70E9"
          textHeight={55}
        />
      </PC>
      <Mobile>
        <PageTitle
          titleText="커머스 네트워크 플랫폼"
          subTitleText="잠재 고객을 보유한 다양한 채널과의 네트워크를 이어 갑니다."
          contentsTitleText={`하나의 플랫폼으로\n더 많은 고객을 만나는\n커머스 솔루션`}
          contentsSubTitleText={`상품 공급자와 커머스 서비스를 연결하며\n각 파트너의 공동 성장을 추구하는\n국내 유일의 커머스 네트워크 플랫폼입니다.`}
          titleImage={comeImage}
          fill="#7C70E9"
          textHeight={85}
        />
      </Mobile>
      <Contents>
        <Section1 />
        <Section2 />
      </Contents>
      <Section3 />
      <Section4 />
      <Section5 />
    </Layout>
  );
};

export default Commerce;
