import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import SectionTitle from "components/Common/SectionTitle";
import Section1 from "components/Marketing/Section1";
import Section2 from "components/Marketing/Section2";
import Section3 from "components/Marketing/Section3";
import Section4 from "components/Marketing/Section4";
import Section5 from "components/Marketing/Section5";
import Section6 from "components/Marketing/Section6";
import { Helmet } from "react-helmet";
import comeImage from "static/marketing/come.png";

const Marketing = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          프로모션 마케팅 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를
          바꾸다!
        </title>
      </Helmet>
      <PageTitle
        titleText="프로모션 마케팅 플랫폼"
        subTitleText="맞춤형 이벤트 제작 플랫폼 애드머스와 게임형 리워드 플랫폼 지니펀으로 성공적인 마케팅 경험을 제공합니다."
        contentsTitleText={`마케터들의 고민을 한번에\n해결하는 맞춤형 마케팅 솔루션`}
        contentsSubTitleText={`게임형 이벤트, 게임형 리워드 플랫폼, 데이터를 활용한 종합 마케팅까지\n 목표를 더 효과적으로 달성시켜 줄 마케팅 솔루션을 제안합니다.`}
        titleImage={comeImage}
        fill="#7C70E9"
      />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
    </Layout>
  );
};

export default Marketing;
