/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import bigright from "static/marketing/BigRight.png";
import logo_admerce from "static/marketing/logo_admerce.png";
import arrowRight from "static/common/arrow_right.svg";
import { Mobile, PC } from "components/Common/Responsive";
import m_cont1 from "static/marketing/m_cont1.png";
import m_cont2 from "static/marketing/m_cont2.png";
import slide1 from "static/marketing/slide1.png";
import slide2 from "static/marketing/slide2.png";
import cha from "static/marketing/cha.png";
import { container } from "styles";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
const Section4 = () => {
  const navigate = useNavigate();

  const infiniteAnimation1 = keyframes`
  0% {
       transform: translateY(0%);
   }
   50% {
       transform: translateY(-100%);
   }
   50.1% {
       transform: translateY(100%);
   }
   100% {
       transform: translateY(0%);
   }
`;

  const infiniteAnimation2 = keyframes`
   0% {
       transform: translateY(0%);
   }
   100% {
       transform: translateY(-200%);
   }
`;

  const infiniteAnimation3 = keyframes`
  0% {
       transform: translateY(0%);
   }
   50% {
       transform: translateY(100%);
   }
   50.1% {
       transform: translateY(-100%);
   }
   100% {
       transform: translateY(0%);
   }
`;

  const infiniteAnimation4 = keyframes`
   0% {
       transform: translateY(-200%);
   }
   100% {
       transform: translateY(0%);
   }
`;

  const section4Style = css`
    background-color: #f9f8ff;
    height: 956px;
    position: relative;
    .info {
      position: absolute;
      top: 180px;
      left: 94px;
    }
    .info > img {
      width: 146px;
      height: 38px;
      flex-shrink: 0;
    }
    .info h1 {
      margin-top: 24px;
      color: #000;
      font-family: Pretendard;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px; /* 141.667% */
      letter-spacing: -0.48px;
    }
    .info > p {
      color: #000;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-top: 6px;
      margin-bottom: 16px;
    }
    .info span {
      color: rgba(45, 45, 45, 0.8);
      text-align: justify;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      display: block;
    }
    .info button {
      cursor: pointer;
      margin-top: 40px;
      background: #7c70e9;
      border-radius: 80px;
      padding: 13px 20px 13px 38px;
      border: 0;
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      position: relative;
      display: flex;
      align-items: center;
    }

    .point {
      display: flex;
      align-items: center;
      position: absolute;
      left: 94px;
      top: 742px;
      img {
        width: 194px;
        height: 213px;
      }
      .text {
        font-size: 14px;
        p {
          margin-top: 12px;
          line-height: 20px;
        }
      }
    }

    .slide_container {
      width: 368px;
      height: 956px;
      overflow: hidden;
      position: absolute;
      right: 300px;
      top: 0;
      .slide_wrapper {
        display: flex;
        flex-direction: column;
      }
      .slide {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        &.original {
          animation: 30s linear infinite ${infiniteAnimation1};
        }
        &.clone {
          animation: 30s linear infinite ${infiniteAnimation2};
        }
        li {
          img {
            width: 100%;
          }
        }
      }
    }

    .slide_container2 {
      width: 368px;
      height: 956px;
      overflow: hidden;
      position: absolute;
      right: 0px;
      top: 0;
      .slide_wrapper {
        display: flex;
        flex-direction: column;
      }
      .slide {
        display: flex;
        align-items: center;
        flex-direction: column;
        position: relative;
        &.original {
          animation: 30s linear infinite ${infiniteAnimation3};
        }
        &.clone {
          animation: 30s linear infinite ${infiniteAnimation4};
        }
        li {
          img {
            width: 100%;
          }
        }
      }
    }

    .btn_wrap {
      display: flex;
      > button:nth-of-type(1) {
        margin-left: 12px;
      }
    }
  `;

  const section4MoStyle = css`
    background-color: #f9f8ff;
    padding: 60px 20px 90px 20px;

    .info {
      text-align: center;
    }
    .info > img {
      width: 81px;
      height: 21px;
      flex-shrink: 0;
    }
    .info h1 {
      margin-top: 12px;
      color: #000;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px; /* 141.667% */
      letter-spacing: -0.48px;
    }
    .info > p {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-top: 12px;
      margin-bottom: 16px;
    }
    .info .img img {
      width: 100%;
      margin-top: 44px;
      margin-right: -20px;
    }
    .info span {
      color: rgba(45, 45, 45, 0.8);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      display: block;
    }
    .info button {
      cursor: pointer;
      margin-top: 40px;
      margin: 60px auto 0;
      background: #7c70e9;
      border-radius: 80px;
      padding: 4px 20px 4px 38px;
      font-weight: bold;
      border: 0;
      color: #fff;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      position: relative;
      display: flex;
      align-items: center;
    }
    .hpoint {
      display: flex;
      margin-top: 60px;
      align-items: end;
      img {
        width: 114px;
      }
      > .text {
        text-align: left;
        margin-left: 20px;
        .title {
          font-size: 16px;
          line-height: 24px;
          font-weight: bold;
        }
        .text {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
        }
      }
    }
    .btn_wrap {
      button:nth-of-type(2) {
        margin-top: 14px;
      }
    }
  `;
  return (
    <>
      <PC>
        <div css={section4Style}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <div css={container} style={{ height: "956px" }}>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="info">
                  <img src={logo_admerce} alt="logo" />
                  <h1>애드머스</h1>
                  <p>
                    100여종의 캐주얼 게임으로
                    <br />
                    유입부터 브랜딩까지 한번에!
                  </p>
                  <span>
                    기획부터 제작, 운영까지 원스톱 지원으로 마케터의 부담을
                    최소화
                    <br />
                    합니다. 흥미있는 게임으로 유입부터 체류시간까지 모든
                    지표에서 압도
                    <br />
                    적인 성과를 기대할 수 있습니다.
                  </span>
                  <div className="btn_wrap">
                    <a href="/pdf/admerce.pdf" download>
                      <button>
                        애드머스 소개서
                        <img src={arrowRight} alt="arrowRight" />
                      </button>
                    </a>
                    <button onClick={() => navigate("/inquiry?type=2")}>
                      문의하기 <img src={arrowRight} alt="arrowRight" />
                    </button>
                  </div>
                </div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="slide_container">
                  <ul className="slide_wrapper">
                    <div className={"slide original"}>
                      <li>
                        <img src={slide1} alt="slide1" />
                      </li>
                    </div>
                    <div className={"slide clone"}>
                      <li>
                        <img src={slide1} alt="slide1" />
                      </li>
                    </div>
                  </ul>
                </div>
                <div className="slide_container2">
                  <ul className="slide_wrapper">
                    <div className={"slide original"}>
                      <li>
                        <img src={slide2} alt="slide1" />
                      </li>
                    </div>
                    <div className={"slide clone"}>
                      <li>
                        <img src={slide2} alt="slide1" />
                      </li>
                    </div>
                  </ul>
                </div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.9,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="point">
                  <div className="text">
                    <div className="title">현대백화점ㅣH.Point의 H.game</div>
                    <p>
                      H.Point 앱 재방문을 높이기 위한 콘텐츠.
                      <br />
                      게임 완료 후 포인트 증정 및 주요 프로모션 구좌
                      <br />
                      개설로 자연스러운 브랜딩 효과
                    </p>
                  </div>
                  <div className="img">
                    <img src={cha} alt="cha" />
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section4MoStyle}>
          <div>
            <div className="info">
              <img src={logo_admerce} alt="logo" />
              <h1>애드머스</h1>
              <p>
                100여종의 캐주얼 게임으로
                <br />
                유입부터 브랜딩까지 한번에!
              </p>
              <span>
                고객사의 상황에 맞추어 각 분야 전문가들이
                <br />
                제안하는 최적의 솔루션을 선택할 수 있습니다.
              </span>
              <div className="img">
                <img src={m_cont1} alt="" />
              </div>
              <div className="hpoint">
                <img src={m_cont2} alt="" />
                <div className="text">
                  <div className="title">
                    현대백화점
                    <br />
                    H.Point의 H.game
                  </div>
                  <div className="text">
                    H.Point 앱 재방문을 높이기 위한 콘텐츠로 게임 완료 후 포인트
                    증정 및 주요 프로모션 구좌를 개설하여 자연스러운 브랜딩
                    효과까지 누림
                  </div>
                </div>
              </div>
              <div className="btn_wrap">
                <a href="/pdf/admerce.pdf" download>
                  <button>
                    애드머스 소개서
                    <img src={arrowRight} alt="arrowRight" />
                  </button>
                </a>
                <button onClick={() => navigate("/inquiry?type=2")}>
                  문의하기 <img src={arrowRight} alt="arrowRight" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section4;
