import { motion } from "framer-motion";
import React from "react";

interface ArcProps {
  width: number;
  height: number;
  fill?: string;
  delay?: number;
  visible?: boolean;
}

const Arc = ({
  width,
  height,
  fill = "#7C70E9",
  delay = 0.3,
  visible = true,
}: ArcProps) => {
  return (
    <motion.svg
      className="arc"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
    >
      <motion.circle
        cx="0"
        cy={width}
        r={width / 2}
        stroke={fill}
        strokeWidth={width}
        fill={"transparent"}
        variants={{
          hidden: { pathLength: 0 },
          visible: {
            pathLength: 1,
            transition: {
              repeatType: "loop",
              pathLength: {
                delay,
                duration: 0.3,
              },
            },
          },
        }}
      />
    </motion.svg>
  );
};

export default Arc;
