/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Contents from "components/Common/Contents";
import logo from "static/marketing/logo_market.png";
import arrowRight from "static/common/arrow_right.svg";
import logo1 from "static/marketing/logo_1.png";
import logo2 from "static/marketing/logo_2.png";
import logo3 from "static/marketing/logo_3.png";
import logo4 from "static/marketing/logo_4.png";
import hand from "static/marketing/hand.png";
import m_cont4 from "static/marketing/m_cont4.png";
import { Mobile, PC } from "components/Common/Responsive";
import { motion } from "framer-motion";

const Section6 = () => {
  const section6Style = css`
    position: relative;
    background: #f9f8ff;
    overflow: hidden;
    .info {
      padding-top: 150px;
      padding-left: 94px;
      padding-bottom: 123px;
    }
    .info > img {
      width: 116px;
      height: 38px;
      flex-shrink: 0;
    }
    .info > div {
      margin-top: 24px;
      padding: 45px 42px 45px 42px;
      border-radius: 10px;
      background: #edebff;
      width: 345px;
    }
    .info > div h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.5px;
      margin-bottom: 12px;
    }
    .info > div p {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.48px;
    }
    .info > div:first-of-type {
      margin-top: 76px;
      margin-bottom: 0;
    }
    .info > h1 {
      margin-top: 24px;
      color: #000;
      font-family: Pretendard;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px; /* 141.667% */
      letter-spacing: -0.48px;
    }
    .info > p {
      color: #000;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-top: 6px;
      margin-bottom: 16px;
    }
    .info > span {
      color: rgba(45, 45, 45, 0.8);
      text-align: justify;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      display: block;
    }
    .info > button {
      margin-top: 40px;
      background: #7c70e9;
      border-radius: 80px;
      padding: 13px 20px 13px 38px;
      border: 0;
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      position: relative;
      display: flex;
      align-items: center;
    }
    .imgBox {
      div {
        position: absolute;
        width: 162px;
        height: 162px;
        img {
          width: 100%;
        }
      }
      div:nth-of-type(1) {
        width: 695px;
        height: 693px;
        top: 465px;
        right: 0;
      }
      div:nth-of-type(2) {
        right: 496px;
        top: 600px;
      }
      div:nth-of-type(3) {
        left: 537px;
        top: 396px;
      }
      div:nth-of-type(4) {
        right: 261px;
        top: 273px;
      }
      div:nth-of-type(5) {
        right: 46px;
        top: 262px;
      }
    }
  `;

  const section6MoStyle = css`
    background-color: #f9f8ff;
    padding: 60px 0px 0px 0px;

    .info {
      text-align: center;
    }
    .info > img {
      width: 81px;
      height: 26px;
      flex-shrink: 0;
    }
    .info h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px; /* 141.667% */
      letter-spacing: -0.48px;
    }
    .info > .list {
      margin-top: 24px;
      padding: 50px 40px;
      border-radius: 10px;
      background: #edebff;
      width: 100%;
      text-align: left;
    }
    .info > .list h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-bottom: 12px;
    }
    .info > .list p {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.48px;
    }
    .info > p {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .info .img img {
      width: 100%;
      margin-top: 44px;
      margin-right: -20px;
    }
    .info span {
      color: rgba(45, 45, 45, 0.8);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      display: block;
    }
  `;
  return (
    <>
      <PC>
        <div css={section6Style}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Contents top={0}>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="imgBox">
                  <div>
                    <img src={hand} alt="hand" />
                  </div>
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.7,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <img src={logo1} alt="logo1" />
                  </motion.div>
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.8,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <img src={logo2} alt="logo2" />
                  </motion.div>
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 0.9,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <img src={logo3} alt="logo3" />
                  </motion.div>
                  <motion.div
                    variants={{
                      offscreen: {
                        y: 100,
                        opacity: 0,
                      },
                      onscreen: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          delay: 1,
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <img src={logo4} alt="logo4" />
                  </motion.div>
                </div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="info">
                  <img src={logo} alt="logo" />
                  <h1>IMC MKT</h1>
                  <p>
                    빅데이터와 AI 기술에 인사이트를 더하여,
                    <br />
                    더욱 향상된 마케팅 퍼포먼스
                  </p>
                  <span>
                    자사의 고유 기술 시스템과 풍부한 마케팅 경험이 더해져
                    <br />
                    더욱 향상된 마케팅 퍼포먼스를 제공합니다.
                  </span>
                  <div>
                    <h1>온라인 마케팅 인사이트</h1>
                    <p>
                      삼성페이, LGU+, CJ ONE 등 국내 우수 기<br />
                      업들과 커머스 사업제휴, 자사몰 운영으로 쌓<br />은 경험을
                      통해 새로운 마케팅 인사이트를 제<br />
                      공합니다.
                    </p>
                  </div>
                  <div>
                    <h1>빅데이터 활용의 정밀 타겟팅</h1>
                    <p>
                      SKT, LGU+ 등 타깃 메시지 광고 공식 대행사 경험으로 쌓인
                      인사이트와 기술 데이터를 활용, 보다 정밀한 고객 타겟팅
                      서비스를 제공합니다.
                    </p>
                  </div>
                </div>
              </motion.div>
            </Contents>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section6MoStyle}>
          <Contents top={0}>
            <div className="info">
              <img src={logo} alt="logo" />
              <h1>IMC MKT</h1>
              <p>
                빅데이터와 AI 기술에 인사이트를 더하여,
                <br />
                더욱 향상된 마케팅 퍼포먼스
              </p>
              <span>
                자사의 고유 기술 시스템과 풍부한 마케팅 경험이 더해져
                <br />
                더욱 향상된 마케팅 퍼포먼스를 제공합니다.
              </span>

              <div className="list">
                <h1>온라인 마케팅 인사이트</h1>
                <p>
                  삼성페이, LGU+, CJ ONE 등 국내 우수 기<br />
                  업들과 커머스 사업제휴, 자사몰 운영으로 쌓<br />은 경험을 통해
                  새로운 마케팅 인사이트를 제<br />
                  공합니다.
                </p>
              </div>
              <div className="list">
                <h1>빅데이터 활용의 정밀 타겟팅</h1>
                <p>
                  SKT, LGU+ 등 타깃 메시지 광고 공식 대행사 경험으로 쌓인
                  인사이트와 기술 데이터를 활용, 보다 정밀한 고객 타겟팅
                  서비스를 제공합니다.
                </p>
              </div>
              <div className="img">
                <img src={m_cont4} alt="" />
              </div>
            </div>
          </Contents>
        </div>
      </Mobile>
    </>
  );
};

export default Section6;
