/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import React from "react";
import { container } from "styles";
import bg from "static/message/bg.png";

import arrowRight from "static/common/arrow_right.svg";
import { tabandmo } from "styles/media";
import { useNavigate } from "react-router-dom";

const Section1 = () => {
  const navigator = useNavigate();
  const section1Style = css`
    margin-top: 86px;
    .title {
      width: 100%;
      img {
        margin-top: 142px;
        width: 100%;
      }
    }
    .btn_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      > button:nth-of-type(1) {
        margin-left: 64px;
      }
    }
    button {
      border-radius: 80px;
      padding: 13px 7px 13px 28px;
      width: 256px;
      border: 0;
      background: #4f80ff;
      color: #fff;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }
    ${tabandmo} {
      margin-top: 24px;
      .title {
        width: 100%;
        img {
          margin-top: 80px;
          width: 100%;
        }
      }
      .btn_wrap {
        flex-direction: column;
        button:nth-of-type(2) {
          margin: 30px auto 0 auto;
        }
      }
      button {
        border-radius: 80px;
        padding: 5px 30px 5px 49px;
        border: 0;
        background: #4f80ff;
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        display: flex;
        align-items: center;
        font-weight: bold;
        margin: 30px auto 0 auto;
      }
    }
  `;

  return (
    <>
      <div css={section1Style}>
        <div className="btn_wrap">
          <a href="/pdf/message.pdf" download>
            <button>
              문자 광고 상품 소개서
              <img src={arrowRight} alt="arrowRight" />
            </button>
          </a>
          <button onClick={() => navigator("/inquiry?type=3")}>
            문의하기
            <img src={arrowRight} alt="arrowRight" />
          </button>
        </div>
        <div css={container}>
          <div className="title">
            <img src={bg} alt="bg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Section1;
