/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import logo from "static/common/logo.png";
import logo_black from "static/common/logo_black.png";
import { container } from "styles";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Mobile, PC } from "components/Common/Responsive";
import hambuger from "static/common/hambuger.png";
import hambugerBlack from "static/common/hambuger_black.png";
import close from "static/common/close.svg";
import MenuItem from "components/Common/MenuItem";

interface HeaderProps {
  theme?: "white" | "blue";
}

const Header = ({ theme = "white" }: HeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const headerStyle = css`
    background-color: ${theme === "blue" ? "#4F80FF" : "#fff"};
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: all 0.3s;
    &.active {
      box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.06),
        0 -1px 4px 0 rgba(0, 0, 0, 0.18);
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 0px;
      h1 {
        cursor: pointer;
      }
      img {
        width: 131px;
        float: left;
      }
      nav {
        > ul {
          display: flex;
        }
        > ul > li {
          font-size: 16px;
          line-height: 26px;
          color: ${theme === "blue" ? "#fff" : "#000"};
          padding: 0 30px;
          cursor: pointer;
          position: relative;
          &:hover > .sub {
            display: block;
          }
          .sub {
            display: none;
            position: absolute;
            width: 120px;
            top: -25px;
            left: 50%;
            font-weight: normal;
            color: #fff;
            transform: translateX(-50%);
            background-color: #7c70e9;
            text-align: center;
            li {
              padding: 10px 0px;
            }
            li.on {
              font-weight: bold;
            }
            li:hover {
              font-weight: bold;
            }
            li:first-of-type:hover {
              font-weight: normal;
            }
            li:first-of-type {
              line-height: 76px;
              padding: 0;
            }
          }
        }
        > ul > li.on {
          font-weight: bold;
          color: ${theme === "blue" ? "#fff" : "#4F80FF"};
        }
      }
    }
  `;

  const headerMoStyle = css`
    background-color: ${theme === "blue" ? "#4F80FF" : "#fff"};
    position: fixed;
    width: 100%;
    z-index: 100;
    transition: all 0.3s;
    &.active {
      box-shadow: 0 -2px 12px 0 rgba(0, 0, 0, 0.06),
        0 -1px 4px 0 rgba(0, 0, 0, 0.18);
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      h1 {
        img {
          width: 99px;
          float: left;
        }
      }
      .menu {
        &.close {
          img {
            width: 24px;
          }
        }
        img {
          width: 39px;
        }
        margin-left: auto;
      }
    }
    nav {
      position: fixed;
      top: 0;
      right: -100%;
      width: 100%;
      height: 100%;
      background-color: #7c70e9;
      &.active {
        background: #6668f4;
        right: 0;
        transition: all 0.5s ease;
        z-index: 1;
      }
    }
  `;

  const { scrollY } = useScroll();
  const [acitve, setActive] = useState(false);

  const menu = [
    {
      path: "/companyinfo",
      name: "회사소개",
    },
    {
      name: "비즈니스",
      path: "/business",
      children: [
        {
          path: "/business/commerce",
          name: "커머스 네트워크",
        },
        {
          path: "/business/marketing",
          name: "프로모션 마케팅",
        },
        {
          path: "/business/message",
          name: "통신사 타겟 문자 광고",
        },
      ],
    },
    {
      name: "ESG",
      path: "/esg",
    },
    {
      name: "뉴스",
      path: "/news",
      children: [
        {
          path: "/news/list",
          name: "언론보도",
        },
        {
          path: "/news/video",
          name: "비디오",
        },
      ],
    },
    {
      path: "/employment",
      name: "채용",
    },
    { path: "/inquiry", name: "문의" },
  ];

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > 20) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  return (
    <>
      <PC>
        <header css={headerStyle} className={acitve ? "active" : ""}>
          <div css={container}>
            <div className="header">
              <h1 onClick={() => navigate("/")}>
                <img src={theme === "blue" ? logo : logo_black} alt="logo" />
              </h1>
              <nav>
                <ul>
                  {menu.map((v, k) => {
                    return (
                      <li
                        className={
                          v.path === `/${location.pathname.split("/")[1]}`
                            ? "on"
                            : ""
                        }
                        onClick={() => !v.children && navigate(v.path)}
                        key={k}
                      >
                        {v.name}
                        {v.children !== undefined && (
                          <ul className="sub">
                            <li
                              key={k}
                              onClick={() => navigate(v.children[0].path)}
                            >
                              {v.name}
                            </li>
                            {v.children.map((menu, menuKey) => {
                              return (
                                <li
                                  className={
                                    menu.path === location.pathname ? "on" : ""
                                  }
                                  onClick={() => navigate(menu.path)}
                                  key={menuKey}
                                >
                                  {menu.name}
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </PC>
      <Mobile>
        <header css={headerMoStyle} className={acitve ? "active" : ""}>
          <div className="header">
            <h1 onClick={() => navigate("/")}>
              <img src={theme === "blue" ? logo : logo_black} alt="logo" />
            </h1>
            <div className="menu" onClick={() => setClicked(true)}>
              <img
                src={theme === "blue" ? hambuger : hambugerBlack}
                alt="logo"
              />
            </div>
          </div>
          <nav className={clicked ? "active" : ""}>
            <div className="header">
              <h1 onClick={() => navigate("/")}>
                <img src={logo} alt="logo" />
              </h1>
              <div className="menu close" onClick={() => setClicked(false)}>
                <img src={close} alt="close" />
              </div>
            </div>
            <div>
              {menu.map((v, k) => {
                return (
                  <MenuItem
                    name={v.name}
                    path={v.path}
                    menuOff={() => setClicked(false)}
                    children={v.children}
                    key={k}
                  />
                );
              })}
            </div>
          </nav>
        </header>
      </Mobile>
    </>
  );
};

export default Header;
