import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "styles/reset.css";
import Home from "pages/home";
import NewsList from "pages/news/list";
import NewsVideo from "pages/news/video";
import Detail from "pages/news/detail";
import Inquiry from "pages/inquiry";
import CompanyInfo from "pages/companyInfo";
import Commerce from "pages/commerce";
import Employment from "pages/employment";
import Marketing from "pages/marketing";
import Message from "pages/message";
import Esg from "pages/esg";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/news/list",
    element: <NewsList newsType="image" />,
  },
  {
    path: "/news/video",
    element: <NewsVideo newsType="video" />,
  },
  {
    path: "/news/list/:id",
    element: <Detail detailType="image" />,
  },
  {
    path: "/news/video/:id",
    element: <Detail detailType="video" />,
  },
  {
    path: "/inquiry",
    element: <Inquiry />,
  },
  {
    path: "/companyInfo",
    element: <CompanyInfo />,
  },
  {
    path: "/business/commerce",
    element: <Commerce />,
  },
  {
    path: "/business/marketing",
    element: <Marketing />,
  },
  {
    path: "/business/message",
    element: <Message />,
  },
  {
    path: "/employment",
    element: <Employment />,
  },
  {
    path: "/esg",
    element: <Esg />,
  },
]);

const App = () => {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
