/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Contents from "components/Common/Contents";
import { motion } from "framer-motion";
import arrowRight from "static/common/arrow_right.svg";
import logo from "static/marketing/logo_genie.png";
import genie1 from "static/marketing/genie1.png";
import genie2 from "static/marketing/genie2.png";
import genie3 from "static/marketing/genie3.png";
import mobile1 from "static/marketing/mobile1.png";
import mobile2 from "static/marketing/mobile2.png";
import mobile3 from "static/marketing/mobile3.png";
import mobile4 from "static/marketing/mobile4.png";
import Yline from "components/Intraction/yLine";
import Xline from "components/Intraction/xLine";
import m_cont3 from "static/marketing/m_cont3.png";
import { Mobile, PC } from "components/Common/Responsive";
import { useNavigate } from "react-router-dom";

const Section5 = () => {
  const navigate = useNavigate();

  const section5Style = css`
    position: relative;
    background: #f2f0ff;
    .imgBox {
      img:nth-of-type(1) {
        position: absolute;
        right: 402px;
        top: 236px;
        width: 167px;
        height: 173px;
      }
      img:nth-of-type(2) {
        position: absolute;
        right: 170px;
        top: 188px;
        width: 192px;
        height: 219px;
      }
      img:nth-of-type(3) {
        position: absolute;
        right: 80px;
        top: 180px;
        width: 95px;
        height: 167px;
        transform: rotate(-11.105deg);
      }
    }
    .info {
      padding: 150px 0 120px 94px;
    }
    .info > img {
      width: 116px;
      height: 38px;
      flex-shrink: 0;
    }
    .info > h1 {
      margin-top: 24px;
      color: #000;
      font-family: Pretendard;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px; /* 141.667% */
      letter-spacing: -0.48px;
    }
    .info > p {
      color: #000;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-top: 6px;
      margin-bottom: 16px;
    }
    .info > span {
      color: rgba(45, 45, 45, 0.8);
      text-align: justify;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      display: block;
    }
    .info button {
      cursor: pointer;
      margin-top: 40px;
      background: #7c70e9;
      border-radius: 80px;
      padding: 13px 20px 13px 38px;
      border: 0;
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      position: relative;
      display: flex;
      align-items: center;
    }
    .mobiles {
      padding: 0 100px 180px 100px;
      display: flex;
      justify-content: space-between;
    }
    .mobiles img {
      width: 100%;
    }
    .mobiles .mobile:nth-of-type(3) svg {
      position: absolute;
      top: -22px;
      z-index: 1;
      left: 50%;
      transform: translateX(-50%);
    }
    .mobiles .mobile p {
      color: #7c70e9;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px; /* 145.833% */
      letter-spacing: -0.24px;
    }
    .mobiles .mobile:nth-of-type(3) p {
      position: absolute;
      top: -58px;
      width: max-content;
      left: -34px;
    }
    .mobiles .mobile:nth-of-type(2) .line {
      height: 60px;
      width: 1px;
      background-color: #4f80ff;
      position: absolute;
      bottom: -77px;
      left: 50%;
      transform: translateX(-50%);
    }
    .mobiles .mobile:nth-of-type(4) .line {
      height: 60px;
      width: 1px;
      background-color: #4f80ff;
      position: absolute;
      bottom: -77px;
      left: 50%;
      transform: translateX(-50%);
    }
    .mobiles .mobile:nth-of-type(2) svg {
      position: absolute;
      bottom: -87px;
      left: calc(50% + 27px);
      transform: translateX(-50%);
    }
    .mobiles .mobile:nth-of-type(4) svg {
      position: absolute;
      bottom: -87px;
      left: calc(50% - 27px);
      transform: translateX(-50%);
    }
    .mobiles .mobile:nth-of-type(2) p {
      color: #7c70e9;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 35px; /* 145.833% */
      letter-spacing: -0.24px;
      position: absolute;
      bottom: -95px;
      width: max-content;
      left: 182px;
    }
    .mobiles .mobile {
      position: relative;
      width: 221px;
      height: auto;
      margin-top: 67px;
      margin-bottom: 97px;
      text-align: center;
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 35px; /* 218.75% */
      letter-spacing: -0.16px;
    }
    .btn_wrap {
      display: flex;
      > button:nth-of-type(1) {
        margin-left: 12px;
      }
    }
  `;

  const section5MoStyle = css`
    background-color: #f2f0ff;
    padding: 60px 0px 90px 0px;

    .info {
      text-align: center;
    }
    .info > img {
      width: 81px;
      height: 21px;
      flex-shrink: 0;
    }
    .info h1 {
      margin-top: 12px;
      color: #000;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px; /* 141.667% */
      letter-spacing: -0.48px;
    }
    .info > p {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 141.667% */
      letter-spacing: -0.24px;
      margin-top: 12px;
      margin-bottom: 16px;
    }
    .info .img img {
      width: 100%;
      margin-top: 44px;
    }
    .info span {
      color: rgba(45, 45, 45, 0.8);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: -0.14px;
      display: block;
    }
    .info button {
      cursor: pointer;
      margin-top: 40px;
      margin: 60px auto 0;
      background: #7c70e9;
      border-radius: 80px;
      padding: 4px 20px 4px 38px;
      font-weight: bold;
      border: 0;
      color: #fff;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      position: relative;
      display: flex;
      align-items: center;
    }
    .btn_wrap {
      button:nth-of-type(2) {
        margin-top: 14px;
      }
    }
  `;
  return (
    <>
      <PC>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <div css={section5Style}>
            <Contents top={0}>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="imgBox">
                  <img src={genie1} alt="genie1" />
                  <img src={genie2} alt="genie2" />
                  <img src={genie3} alt="genie3" />
                </div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="info">
                  <img src={logo} alt="logo" />
                  <h1>지니펀</h1>
                  <p>
                    앱 활성화가 고민이라면,
                    <br />
                    지니펀 플랫폼으로
                  </p>
                  <span>
                    참여 보상형 게임을 통해 유저를 Lock-In(락-인)시키는
                    <br />
                    효과를 넘어, 게임 전후 광고로 수익 창출까지 동시에
                    제공합니다.
                  </span>
                  <div className="btn_wrap">
                    <a href="/pdf/geniefun.pdf" download>
                      <button>
                        지니펀 소개서 <img src={arrowRight} alt="arrowRight" />
                      </button>
                    </a>
                    <button onClick={() => navigate("/inquiry?type=2")}>
                      문의하기 <img src={arrowRight} alt="arrowRight" />
                    </button>
                  </div>
                </div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.9,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="mobiles">
                  <div className="mobile">
                    <img src={mobile1} alt="mobile1" />
                    게임 안내 화면
                  </div>
                  <div className="mobile">
                    <div className="line"></div>
                    <p>게임 전, 후의 광고를 통한 수익 창출</p>
                    <Xline width={60} fill="#4F80FF" />
                    <img src={mobile2} alt="mobile2" />
                    게임 전 광고 화면
                  </div>
                  <div className="mobile">
                    <p>게임 리워드를 통한 리텐션 강화</p>
                    <Yline width={54} fill="#4F80FF" type="bottom" />
                    <img src={mobile3} alt="mobile3" />
                    게임 화면
                  </div>
                  <div className="mobile">
                    <div className="line"></div>
                    <Xline width={60} type="left" fill="#4F80FF" />
                    <img src={mobile4} alt="mobile4" />
                    게임 완료 후 오퍼월 광고
                  </div>
                </div>
              </motion.div>
            </Contents>
          </div>
        </motion.div>
      </PC>
      <Mobile>
        <div css={section5MoStyle}>
          <Contents top={0}>
            <div className="info">
              <img src={logo} alt="logo" />
              <h1>지니펀</h1>
              <p>
                앱 활성화가 고민이라면,
                <br />
                지니펀 플랫폼으로
              </p>
              <span>
                참여 보상형 게임을 통해 유저를 Lock-In(락-인)시키는
                <br />
                효과를 넘어, 게임 전후 광고로 수익 창출까지 동시에 제공합니다.
              </span>
              <div className="img">
                <img src={m_cont3} alt="" />
              </div>
              <div className="btn_wrap">
                <a href="/pdf/geniefun.pdf" download>
                  <button>
                    지니펀 소개서 <img src={arrowRight} alt="arrowRight" />
                  </button>
                </a>
                <button onClick={() => navigate("/inquiry?type=2")}>
                  문의하기 <img src={arrowRight} alt="arrowRight" />
                </button>
              </div>
            </div>
          </Contents>
        </div>
      </Mobile>
    </>
  );
};

export default Section5;
