import { motion } from "framer-motion";

interface BarProps {
  width: number;
  height: number;
  fill?: string;
  delay?: number;
  duration?: number;
  visible?: "visible" | "hidden";
}

export const Bar = ({
  width,
  height,
  fill = "#4F80FF",
  visible = "visible",
}: BarProps) => {
  return (
    <motion.svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      initial="hidden"
      animate={visible}
    >
      <motion.circle
        cx={width / 2}
        cy={width / 2}
        r={width / 2}
        fill={fill}
        custom={1}
      />
      <motion.rect
        width={width}
        height={height - width / 2}
        x={0}
        y={width / 2}
        rx={0}
        fill={fill}
        custom={2}
      />
    </motion.svg>
  );
};

export default Bar;
