/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import React from "react";
import { container } from "styles";
import list1 from "static/esg/list1.png";
import list2 from "static/esg/list2.png";
import list3 from "static/esg/list3.png";

import { Mobile, PC } from "components/Common/Responsive";
import Xline from "components/Intraction/xLine";
import Title from "components/Common/Title";

const Section4 = () => {
  const section4Style = css`
    margin-top: 177px;
    padding-bottom: 200px;
    .cont:nth-of-type(1) {
      margin-top: 211px;
    }
    .list {
      display: flex;
      justify-content: space-between;
      margin-top: 163px;
      li {
        width: 119px;
        flex-shrink: 0;
        .img {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .info {
          margin-top: 60px;
          .title {
            color: #000;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px; /* 107.143% */
            letter-spacing: -0.28px;
            margin-bottom: 18px;
          }
          .text {
            color: rgba(0, 0, 0, 0.6);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 20px; /* 142.857% */
            letter-spacing: -0.28px;
          }
        }
      }
    }
  `;

  const section4MoStyle = css`
    margin-top: 90px;
    padding-bottom: 90px;
    .list {
      li {
        &:first-of-type {
          margin-top: 90px;
        }
        margin-top: 40px;
        width: 200px;
        margin: 40px auto 0 auto;
        .img {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .info {
          margin-top: 20px;
          .title {
            color: #7c70e9;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 150% */
          }
          .text {
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 36px; /* 150% */
          }
        }
      }
    }
  `;

  return (
    <>
      <PC>
        <div css={section4Style}>
          <div css={container}>
            <motion.div
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.6 }}
            >
              <Title
                title={<>ESG 경영 추진 방향</>}
                sub={
                  <>환경과 사회를 위한 지니웍스의 ESG 방향성을 소개합니다.</>
                }
              />
            </motion.div>
            <motion.ul
              initial="offscreen"
              whileInView="onscreen"
              className="list"
              viewport={{ once: true, amount: 0.6 }}
            >
              <motion.li
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="img">
                  <img src={list1} alt="list1" />
                </div>
                <div className="info">
                  <div className="title">투명성</div>
                  <div className="text">Transparency</div>
                </div>
              </motion.li>
              <motion.li
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="img">
                  <img src={list2} alt="list1" />
                </div>
                <div className="info">
                  <div className="title">정직성</div>
                  <div className="text">Truthfulness</div>
                </div>
              </motion.li>
              <motion.li
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <div className="img">
                  <img src={list3} alt="list1" />
                </div>
                <div className="info">
                  <div className="title">준법성</div>
                  <div className="text">Thoroughness</div>
                </div>
              </motion.li>
            </motion.ul>
          </div>
        </div>
      </PC>
      <Mobile>
        <div css={section4MoStyle}>
          <div css={container}>
            <div>
              <Title title={<>ESG 경영 추진 방향</>} />
            </div>
            <ul className="list">
              <li>
                <div className="img">
                  <img src={list1} alt="list1" />
                </div>
                <div className="info">
                  <div className="title">Transparency</div>
                  <div className="text">투명성</div>
                </div>
              </li>
              <li>
                <div className="img">
                  <img src={list2} alt="list1" />
                </div>
                <div className="info">
                  <div className="title">Truthfulness</div>
                  <div className="text">정직성</div>
                </div>
              </li>
              <li>
                <div className="img">
                  <img src={list3} alt="list1" />
                </div>
                <div className="info">
                  <div className="title">Thoroughness</div>
                  <div className="text">준법성</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section4;
