/** @jsxImportSource @emotion/react */
import Layout from "components/Common/Layout";
import PageTitle from "components/Common/PageTitle";
import { Mobile, PC } from "components/Common/Responsive";
import Section1 from "components/Employment/Section1";
import Section2 from "components/Employment/Section2";
import Section3 from "components/Employment/Section3";
import Section4 from "components/Employment/Section4";
import Section5 from "components/Employment/Section5";
import { Helmet } from "react-helmet";
import titleImage from "static/employment/title.png";

const Employment = () => {
  return (
    <Layout>
      <Helmet>
        <title>
          채용 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>
      <PC>
        <PageTitle
          titleText="함께 실현하는 사람들"
          subTitleText="혁신을 꿈꾸며 함께, 우리만의 답을 찾는 사람들입니다."
          contentsTitleText={
            <>
              서로가 성장의 원동력이 되며 <br />
              함께 불가능을 가능으로 만듭니다
            </>
          }
          contentsSubTitleText={`혼자서는 할 수 없지만 함께하면 가능을 만들 수 있다고 믿습니다.\n 더 큰 시너지를 만들기 위해 서로를 존중하며 나아가고 있습니다. `}
          titleImage={titleImage}
          fill="#7C70E9"
          textHeight={55}
        />
      </PC>
      <Mobile>
        <PageTitle
          titleText={
            <>
              함께 실현하는
              <br />
              사람들
            </>
          }
          subTitleText={
            <>
              혁신을 꿈꾸며 함께, <br />
              우리만의 답을 찾는 사람들입니다.
            </>
          }
          contentsTitleText={
            <>
              서로가 성장의 원동력이
              <br /> 되며 함께 불가능을
              <br />
              가능으로 만듭니다
            </>
          }
          contentsSubTitleText={`혼자서는 할 수 없지만 함께하면 가능을 만들 수\n 있다고 믿습니다. 더 큰 시너지를 만들기 위해\n 서로를 존중하며 나아가고 있습니다. `}
          titleImage={titleImage}
          fill="#7C70E9"
          textHeight={55}
        />
      </Mobile>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </Layout>
  );
};

export default Employment;
