/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import { motion, useInView } from "framer-motion";
import titleImg from "static/main/title.png";
//
import titleImg1 from "static/main/title1.png";
import titleImg2 from "static/main/title2.png";
import titleImg3 from "static/main/title3.png";
import Xline from "components/Intraction/xLine";
import { tabandmo } from "styles/media";
import { Mobile, PC } from "components/Common/Responsive";

const MainTitle = () => {
  const mainStyle = css`
    padding-top: 76px;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .circle {
      width: 59vw;
      height: 59vw;
      border-radius: 50%;
      position: absolute;
      left: -23vw;
      top: 29vh;
      background-color: #4f80ff;
    }
    .arc {
      position: absolute;
      top: 8vh;
      left: 8vw;
    }
    .title {
      padding: 20vh 0;
      font-size: 4vw;
      font-weight: bold;
      position: absolute;
      left: 20vw;
      line-height: 6vw;
    }
    .slide-img {
      position: absolute;
      top: 76px;
      right: -100%;
    }
    .slide {
      > .slick-list {
        width: 53vw;
        height: calc(100vh - 76px);
        overflow: hidden;
        margin: 0 auto;
        border-top-left-radius: 40vw;
        border-bottom-left-radius: 40vw;
        .list {
          width: 53vw;
          height: calc(100vh - 76px);
        }
        img {
          height: 100%;
        }
      }
      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }

    .line {
      position: absolute;
      top: 20vh;
      left: 30vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        width: 50%;
        font-weight: 700;
        font-size: 18px;
        display: flex;
        align-items: center;
        svg {
          margin-left: 6px;
        }
        padding-right: 25px;
        &:last-of-type {
          margin-top: 8px;
        }
      }
    }
    ${tabandmo} {
      padding-top: 68px;
      .title {
        font-size: 32px;
        line-height: 38px;
        color: #fff;
        left: 5vw;
        bottom: 7vh;
        padding: 0;
      }
      .circle {
        width: 250vw;
        height: 250vw;
        left: -130vw;
        top: 35vh;
      }
      .slide-img {
        top: 50%;
        left: 10vw;
        transform: translateY(-50%);
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;
        height: 50vh;
        width: 100vh;
      }
      .arc {
        left: 5vw;
        top: 21w;
      }
      .line {
        display: none;
      }
    }
  `;
  const ref = useRef(null);
  const slideRef = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.6 });

  useEffect(() => {
    if (slideRef !== null) {
      if (isInView) {
        (slideRef as any).current.slickPlay();
      } else {
        (slideRef as any).current.slickPause();
      }
    }
  }, [isInView]);

  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  return (
    <div css={mainStyle}>
      <PC>
        <motion.svg
          className="arc"
          width="160"
          height="160"
          viewBox="0 0 160 160"
          initial="hidden"
          animate="visible"
        >
          <motion.circle
            cx="80"
            cy="160"
            r="40"
            stroke="#7C70E9"
            strokeWidth={80}
            fill="#fff"
            variants={{
              hidden: { pathLength: 0 },
              visible: {
                pathLength: 1,
                transition: {
                  repeatType: "loop",
                  pathLength: {
                    delay: 1.5,
                    type: "spring",
                    duration: 0.5,
                    bounce: 0,
                  },
                },
              },
            }}
          />
        </motion.svg>
      </PC>
      <Mobile>
        <motion.svg
          className="arc"
          width="100"
          height="100"
          viewBox="0 0 100 100"
          initial="hidden"
          animate="visible"
        >
          <motion.circle
            cx="50"
            cy="100"
            r="25"
            stroke="#7C70E9"
            strokeWidth={50}
            fill="#fff"
            variants={{
              hidden: { pathLength: 0 },
              visible: {
                pathLength: 1,
                transition: {
                  repeatType: "loop",
                  pathLength: {
                    delay: 1.5,
                    type: "spring",
                    duration: 0.5,
                    bounce: 0,
                  },
                },
              },
            }}
          />
        </motion.svg>
      </Mobile>
      <motion.div
        className="circle"
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      />
      <div className="title">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          INNOVATIVE
          <br />
          TECHNOLOGY
          <br />
          PLATFORM
        </motion.div>
      </div>
      <ul className="line">
        <li>
          INNOVATIVE
          <Xline width={64} delay={0} />
        </li>
        <li>
          GROWTH <Xline width={64} delay={0.5} />
        </li>
        <li>
          CREATIVE <Xline width={64} delay={1} />
        </li>
      </ul>
      <div>
        <motion.div
          className="slide-img"
          initial={{ right: "-100%", opacity: 0 }}
          animate={{ right: "-3%", opacity: 1 }}
          transition={{ duration: 0.5, type: "spring", delay: 1, bounce: 0 }}
        >
          <Slider {...settings} className="slide" ref={slideRef}>
            <div>
              <div className="list">
                <img src={titleImg1} alt="titleImg1" />
              </div>
            </div>
            <div>
              <div className="list">
                <img src={titleImg2} alt="titleImg2" />
              </div>
            </div>
            <div>
              <div className="list">
                <img src={titleImg3} alt="titleImg3" />
              </div>
            </div>
          </Slider>
        </motion.div>
      </div>
    </div>
  );
};

export default MainTitle;
