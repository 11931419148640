/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Stack,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
} from "@mui/material";
import Contents from "components/Common/Contents";
import { useState } from "react";
import {
  isEmailFormat,
  isMinLength,
  isPhoneFormat,
  isRequired,
} from "utils/vaildation";
import info1Image from "static/esg/info1.png";
import info2Image from "static/esg/info2.png";
import info3Image from "static/esg/info3.png";
import { tabandmo } from "styles/media";
import { api } from "service/config";

const Form = () => {
  const formStyle = css`
    margin-top: 20px;
    padding-bottom: 200px;
    .title {
      text-align: center;
      h2 {
        font-size: 52px;
        line-height: 68px;
      }
      p {
        margin-top: 20px;
        font-size: 18px;
        line-height: 28px;
      }
    }
    .contents {
      margin-top: 90px;
      .list {
        padding: 24px;
        background: #fdfdfd;
        li {
          h3 {
            font-size: 20px;
            line-height: 30px;
          }
          p {
            font-size: 19px;
            line-height: 30px;
          }
        }
        li + li {
          margin-top: 30px;
        }
      }
      .form {
        display: flex;
        flex-direction: column;
        .terms {
          display: flex;
          width: 100%;
          align-items: center;
          margin-top: 24px;
        }
        .formNotice {
          margin-top: 70px;
          font-size: 16px;
          font-weight: 700;
          padding-bottom: 20px;
          border-bottom: 2px solid #000;
        }
        .inputForm {
          margin-top: 40px;
          max-width: 528px;
        }
        .inputContForm {
          margin-top: 16px;
        }

        button {
          padding: 10px 22px;
          background: #2196f3;
          border: 0;
          border-radius: 4px;
          color: #fff;
          font-size: 15px;
          line-height: 26px;
          margin-top: 62px;
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
    .info {
      margin-top: 57px;
      .title {
        text-align: left;
        font-size: 18px;
        font-weight: bold;
      }
      .list {
        display: flex;
        margin-top: 26px;
        li {
          display: flex;
          align-items: center;
          img {
            width: 50px;
          }
          .text {
            margin-left: 10px;
            .sTitle {
              color: #808080;
              font-size: 18px;
              line-height: 24px;
            }
            .cont {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }
        li + li {
          margin-left: 34px;
        }
      }
    }
    ${tabandmo} {
      padding-bottom: 80px;
      .title {
        text-align: center;
        h2 {
          font-size: 32px;
          line-height: 48px;
        }
        p {
          margin-top: 20px;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .contents {
        margin-top: 12px;
        .list {
          padding: 12px;
          li {
            h3 {
              font-size: 16px;
              line-height: 24px;
            }
            p {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
      .info {
        .list {
          flex-direction: column;
          li + li {
            margin-left: 0;
            margin-top: 14px;
          }
        }
      }
    }
  `;

  const [term, setTerm] = useState("N");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [submit, setSubmit] = useState(false);

  const validation = () =>
    isRequired(name) &&
    isPhoneFormat(phone) &&
    isEmailFormat(email) &&
    isMinLength(title, 5) &&
    isMinLength(content, 100);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setSubmit(true);
    if (term !== "Y") {
      window.alert("개인정보 이용에 동의해주세요");
      return;
    }

    if (validation()) {
      api
        .post("/esg/esg", {
          title: title,
          content: content,
          name: name,
          phone: phone,
          email: email,
        })
        .then(function (response) {
          alert("제보가 접수되었습니다. \n감사합니다.");
          window.location.reload();
        });
    }
  };

  return (
    <Contents>
      <div css={formStyle}>
        <div className="title">
          <h2>제보하기</h2>
          <p>
            지니웍스 임직원의 부조리, 회사 정책의 개선/제안 사항, 거래와 관련한
            <br />
            불이익 사항을 제보하는 공간입니다.
          </p>
        </div>
        <div className="contents">
          <ul className="list">
            <li>
              <h3>수집하는 개인정보 항목</h3>
              <p>성명, 휴대전화, 이메일</p>
            </li>
            <li>
              <h3>수집 및 이용목적</h3>
              <p>
                제보에 대한 처리 및 결과 회신을 위한 의사소통경로 확보, 제보내역
                관리
              </p>
            </li>
            <li>
              <h3>보유 및 이용기간</h3>
              <p>
                제보 목적 달성 시 즉시 파기
                <br /> ※ 관계 법률에 의해 보존할 필요가 있는 경우 일정기간 보존
              </p>
            </li>
          </ul>
          <form onSubmit={handleSubmit} className="form">
            <FormControl className="terms">
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="Terms"
                value={term}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio />}
                  label="개인정보 이용에 동의합니다."
                  onChange={(e: any) => setTerm(e.target.value)}
                />
                <FormControlLabel
                  value="N"
                  control={<Radio />}
                  label="동의하지 않습니다."
                  onChange={(e: any) => setTerm(e.target.value)}
                />
              </RadioGroup>
            </FormControl>
            <div className="formNotice">*표시는 필수입력 항목입니다.</div>
            <FormControl className="inputForm" fullWidth>
              <Stack spacing={2}>
                <TextField
                  label="성명"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  label="휴대폰 번호"
                  value={phone}
                  required
                  error={!isPhoneFormat(phone) && submit}
                  helperText={
                    !isPhoneFormat(phone) &&
                    submit &&
                    "휴대폰 번호를 입력해주세요"
                  }
                  onChange={(e) => setPhone(e.target.value)}
                />
                <TextField
                  label="이메일"
                  value={email}
                  required
                  error={!isEmailFormat(email) && submit}
                  helperText={
                    !isEmailFormat(email) &&
                    submit &&
                    "이메일 형식이 맞지 않습니다."
                  }
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Stack>
            </FormControl>
            <FormControl className="inputContForm" fullWidth>
              <Stack spacing={2}>
                <TextField
                  label="제목"
                  required
                  placeholder="최소 5자이상으로 입력해주세요"
                  value={title}
                  error={submit && !isMinLength(title, 5)}
                  helperText={
                    submit &&
                    !isMinLength(title, 5) &&
                    "제목은 최소 5자 이상으로 입력해주세요"
                  }
                  onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                  label="내용"
                  required
                  placeholder="제보내용 입력시 사실에 근거한 내용을 육하원칙(누가, 언제, 어디서, 무엇을, 어떻게, 왜)에 따라 가급적 구체적으로 작성하여 주실 것을 부탁드립니다. 근거 없는 소문, 타인을 비방할 목적의 음해성 제보의 경우 조사를 진행하지 않을 수 있으며, 관련 자료 (사진, 문서, 증빙 등)가 있을 경우 첨부하여 주시면 조사에 많은 도움이 됩니다."
                  multiline
                  rows={4}
                  value={content}
                  error={submit && !isMinLength(content, 100)}
                  inputProps={{ maxLength: 1000 }}
                  helperText={
                    <Box
                      component="span"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "8px",
                      }}
                    >
                      {submit && !isMinLength(content, 100) && (
                        <span>내용은 최소 100자이상 입력해주세요.</span>
                      )}
                      <span
                        style={{ marginLeft: "auto" }}
                      >{`${content.length} / 1000`}</span>
                    </Box>
                  }
                  onChange={(e) => setContent(e.target.value)}
                />
              </Stack>
            </FormControl>
            <button type="submit">제보하기</button>
          </form>
        </div>
        <div className="info">
          <div className="title">지니웍스 제보 채널</div>
          <ul className="list">
            <li>
              <img src={info1Image} alt="info1" />
              <div className="text">
                <div className="sTitle">전화 제보</div>
                <div className="cont">1800-1011</div>
              </div>
            </li>
            <li>
              <img src={info2Image} alt="info2" />
              <div className="text">
                <div className="sTitle">이메일</div>
                <div className="cont">esg@genieworks.net</div>
              </div>
            </li>
            <li>
              <img src={info3Image} alt="info3" />
              <div className="text">
                <div className="sTitle">우편</div>
                <div className="cont">
                  강남구 논현로 653, 2층 지니웍스 사업전략실
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Contents>
  );
};

export default Form;
