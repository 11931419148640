/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import section1 from "static/message/section1.png";
import React from "react";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Section2 = () => {
  const section2Style = css`
    margin-top: 120px;
    .title {
      text-align: center;
      font-size: 52px;
      line-height: 68px;
      font-weight: 700;
    }
    .text {
      text-align: center;
      margin-top: 20px;
      line-height: 28px;
    }
    img {
      margin-top: 116px;
      width: 100%;
    }
    ${tabandmo} {
      margin-top: 80px;
      .title {
        font-size: 32px;
        line-height: 48px;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
      }
      img {
        margin-top: 66px;
      }
    }
  `;
  return (
    <div css={section2Style}>
      <div className="title">Big-Data를 활용한 맞춤 타겟팅</div>
      <div className="text">
        고객의 니즈만 파악하면 사막에서도 냉장고를 팔 수 있습니다. <br />
        다른 곳에서 얻을 수 없는 거대한 Data를 통해 핵심 타겟의 마음을 저격하는
        맞춤 타겟팅이 가능합니다. <br />
        취향과 상황에 최적화된 광고로 잠재 고객을 구매 고객으로 전환시켜 보세요.
      </div>
      <div css={container}>
        <img src={section1} alt="section1" />
      </div>
    </div>
  );
};

export default Section2;
