/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Mobile, PC } from "components/Common/Responsive";
import SectionTitle from "components/Common/SectionTitle";
import { motion } from "framer-motion";
import group1 from "static/marketing/group1.png";
import group2 from "static/marketing/group2.png";
import group3 from "static/marketing/group3.png";

const Section3 = () => {
  const section3Style = css`
    display: flex;
    max-width: 920px;
    margin: 0 auto;
    margin-top: 90px;
    padding-bottom: 232px;
    justify-content: space-between;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    h1 {
      color: #7c70e9;
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px; /* 170% */
      letter-spacing: -0.2px;
    }
    span {
      margin-top: 12px;
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 138.462% */
      letter-spacing: -0.26px;
    }
    img {
      margin-top: 30px;
      width: 183px;
      height: 202px;
    }
  `;

  const section3MoStyle = css`
    display: flex;
    max-width: 920px;
    margin: 0 auto;
    margin-top: 10px;
    justify-content: space-between;
    flex-direction: column;
    padding-bottom: 90px;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 55px;
    }

    h1 {
      color: #7c70e9;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px; /* 170% */
      letter-spacing: -0.2px;
      margin-top: 24px;
    }
    span {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px; /* 138.462% */
      letter-spacing: -0.26px;
    }
    img {
      width: 222px;
      height: 222px;
    }
  `;
  return (
    <>
      <PC>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <motion.div
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.3,
                  duration: 0.3,
                },
              },
            }}
          >
            <SectionTitle
              mainText={`상황과 목적에 따라 선택 가능한\n최적의 마케팅 솔루션`}
              subText={`고객사의 상황에 맞추어 각 분야 전문가들이\n제안하는 최적의 솔루션을 선택할 수 있습니다.`}
              marginTop={240}
            />
          </motion.div>
          <motion.div
            variants={{
              offscreen: {
                y: 100,
                opacity: 0,
              },
              onscreen: {
                y: 0,
                opacity: 1,
                transition: {
                  delay: 0.6,
                  duration: 0.3,
                },
              },
            }}
          >
            <div css={section3Style}>
              <div>
                <h1>애드머스</h1>
                <span>
                  게임으로 유입부터
                  <br />
                  브랜딩까지 원스톱으로
                </span>
                <img src={group1} alt="group1" />
              </div>
              <div>
                <h1>지니펀</h1>
                <span>
                  리워드형 게임 이벤트로
                  <br />
                  신규 유저에서 충성 고객으로
                </span>
                <img src={group2} alt="group2" />
              </div>
              <div>
                <h1>종합 마케팅</h1>
                <span>
                  빅데이터 활용으로
                  <br />더 정밀해진 타겟팅
                </span>
                <img src={group3} alt="group3" />
              </div>
            </div>
          </motion.div>
        </motion.div>
      </PC>
      <Mobile>
        <SectionTitle
          mainText={`상황과 목적에 따라 선택\n가능한 마케팅 솔루션`}
          subText={`고객사의 상황에 맞추어 각 분야 전문가들이\n제안하는 최적의 솔루션을 선택할 수 있습니다.`}
          marginTop={90}
        />
        <div css={section3MoStyle}>
          <div>
            <img src={group1} alt="group1" />
            <h1>애드머스</h1>
            <span>
              게임으로 유입부터
              <br />
              브랜딩까지 원스톱으로
            </span>
          </div>
          <div>
            <img src={group2} alt="group2" />
            <h1>지니펀</h1>
            <span>
              리워드형 게임 이벤트로
              <br />
              신규 유저에서 충성 고객으로
            </span>
          </div>
          <div>
            <img src={group3} alt="group3" />
            <h1>종합 마케팅</h1>
            <span>
              빅데이터 활용으로
              <br />더 정밀해진 타겟팅
            </span>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section3;
