/** @jsxImportSource @emotion/react */
import { delay, motion } from "framer-motion";
import Bar from "./yBar";
import { css } from "@emotion/react";

interface BarBoxProps {
  width: number;
  height: number;
  fill?: string;
  delay?: number;
  duration?: number;
  className?: string;
  visible?: "visible" | "hidden";
}

export const BarBox = ({
  width,
  height,
  className,
  fill = "#4F80FF",
  delay = 0.3,
  visible = "visible",
}: BarBoxProps) => {
  const barWidth = (width - width / 6) / 3;
  const barBoxStyle = css`
    width: ${width}px;
    height: ${height}px;
    display: flex;
    overflow: hidden;
    div + div {
      margin-left: ${width / 12}px;
    }
    div:nth-of-type(1) {
      position: relative;
      bottom: -${height - width / 5}px;
    }

    div:nth-of-type(2) {
      position: relative;
      bottom: -${height / 2}px;
    }
  `;

  return (
    <div css={barBoxStyle} className={className}>
      <motion.div
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay,
              duration: 0.3,
            },
          },
        }}
      >
        <Bar width={barWidth} height={height} fill={fill} />
      </motion.div>
      <motion.div
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: delay + 0.3,
              duration: 0.3,
            },
          },
        }}
      >
        <Bar width={barWidth} height={height} fill={fill} />
      </motion.div>
      <motion.div
        variants={{
          offscreen: {
            y: 100,
            opacity: 0,
          },
          onscreen: {
            y: 0,
            opacity: 1,
            transition: {
              delay: delay + 0.6,
              duration: 0.3,
            },
          },
        }}
      >
        <Bar width={barWidth} height={height} fill={fill} />
      </motion.div>
    </div>
  );
};

export default BarBox;
