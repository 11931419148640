/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import Contents from "components/Common/Contents";
import Layout from "components/Common/Layout";
import Maps from "components/Common/Map";
import PageTitle from "components/Common/PageTitle";
import { Mobile, PC } from "components/Common/Responsive";
import Form from "components/Inquiry/Form";
import Xline from "components/Intraction/xLine";
import { useInView } from "framer-motion";
import useWindowDimemsions from "hooks/useWindowDimemsions";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import comeImage from "static/inquiry/come.png";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Inquiry = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.6 });
  const { width } = useWindowDimemsions();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const inquiryStyle = css`
    .title {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }
    .list {
      padding: 90px;
      display: flex;
      justify-content: space-between;
    }
    .list .info {
      color: #000;
      font-family: Pretendard;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 142.857% */
    }
    .list .info span {
      display: block;
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 40px;
    }
    .list .info strong {
      color: #000;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .list ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .list ul li {
      width: 400px;
      list-style: disc;
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
    }
    ${tabandmo} {
      margin-top: 56px;
      .list {
        padding: 60px 15px;
        flex-direction: column;
      }
      .list .info {
        font-size: 20px;
        line-height: 30px;
        span {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .list ul {
        padding-left: 10px;
        margin-top: 24px;
      }
      .list ul li {
        padding-left: 15px;
        list-style: none;
        position: relative;
        font-size: 16px;
        width: 100%;
      }
      .list li::before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        left: 0;
        top: 11px;
        position: absolute;
        background: #000;
        border-radius: 50%;
      }
    }
  `;

  const comeStyle = css`
    .come {
      margin-top: 100px;
      background-color: #f8f8f8;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 140px;
    }
    .come h1 {
      margin-top: 120px;
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 64px; /* 118.519% */
      letter-spacing: -0.54px;
    }
    .come span {
      display: block;
      margin-top: 110px;
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 145.455% */
      letter-spacing: -0.22px;
      margin-bottom: 30px;
    }
    .come img {
      width: 1064px;
      height: 442px;
    }
    ${tabandmo} {
      .come {
        margin-top: 0;
        padding: 20px;
        background: #fff;
      }
      .come h1 {
        font-size: 32px;
        line-height: 48px;
        margin-top: 0;
      }
      .come span {
        font-size: 16px;
        line-height: 24px;
        margin-top: 24px;
      }
      .come img {
        width: 100%;
        height: auto;
        margin-bottom: 90px;
      }
    }
  `;

  return (
    <Layout>
      <Helmet>
        <title>
          문의 | 지니웍스 (Genieworks) - 모바일 플랫폼의 트렌드를 바꾸다!
        </title>
      </Helmet>
      <PC>
        <PageTitle
          titleText="성장을 위한 첫 단추"
          subTitleText="지니웍스의 파트너가 되어 새로운 성장을 시작하세요."
          contentsTitleText={`우리는 언제나 함께할\n준비가 되어있습니다`}
          contentsSubTitleText={`파트너십 문의 및 프로젝트 의뢰는 메일과 연락처로 문의 주세요.`}
          titleImage={comeImage}
          textHeight={55}
        />
        <Contents>
          <div css={inquiryStyle} ref={ref}>
            <Xline width={1140} duration={0.6} type="left" visible={isInView} />
            <div className="list">
              <div className="info">
                커머스 네트워크
                <span> sellerd@genieworks.net</span>
              </div>
              <ul>
                <li>우리 채널에 맞는 커머스 도입을 고려하는 채널 담당자</li>
                <li>
                  한 번의 입점으로 다수 채널에 판매를 희망하는 판매 담당자
                </li>
              </ul>
            </div>
            <Xline width={1140} duration={0.6} type="left" visible={isInView} />
            <div className="list">
              <div className="info">
                프로모션 마케팅
                <span> support@genieworks.net</span>
              </div>
              <ul>
                <li>단순 이벤트를 넘어, 유입부터 브랜딩까지 고민하는 마케터</li>
                <li>No.1 리워드형 게임 이벤트 구축을 고민하는 채널 담당자</li>
                <li>데이터 기반의 마케팅 성과를 고민하는 마케팅 담당자</li>
              </ul>
            </div>
            <Xline width={1140} duration={0.6} type="left" visible={isInView} />
            <div className="list">
              <div className="info">
                기타 문의
                <span> support@genieworks.net</span>
                <strong>1800 - 1011</strong>
              </div>
              <ul>
                <li>위 서비스 외, 파트너십 제휴 및 프로젝트 의뢰</li>
                <li> 회사 및 투자정보, 기타 문의 사항</li>
              </ul>
            </div>
          </div>
          <Form type={type as string} />
        </Contents>
        <div css={comeStyle}>
          <div className="come">
            <div css={container}>
              <h1>오시는 길</h1>
              <span>서울 강남구 논현로 653 2층(에이엔에이 빌딩)</span>
              <Maps />
            </div>
          </div>
        </div>
      </PC>
      <Mobile>
        <PageTitle
          titleText={
            <>
              성장을 위한
              <br />첫 단추
            </>
          }
          subTitleText={
            <>
              지니웍스의 파트너가 되어 새로운
              <br />
              성장을 시작하세요
            </>
          }
          contentsTitleText={`우리는 언제나 함께할\n준비가 되어있습니다`}
          contentsSubTitleText={
            <>
              파트너십 문의 및 프로젝트 의뢰는 메일과
              <br /> 연락처로 문의 주세요.
            </>
          }
          titleImage={comeImage}
          textHeight={55}
        />
        <Contents>
          <div css={inquiryStyle} ref={ref}>
            <Xline
              width={width - 40}
              delay={0.8}
              duration={0.6}
              type="left"
              visible={isInView}
            />
            <div className="list">
              <div className="info">
                커머스 네트워크
                <span> sellerd@genieworks.net</span>
              </div>
              <ul>
                <li>우리 채널에 맞는 커머스 도입을 고려하는 채널 담당자</li>
                <li>
                  한 번의 입점으로 다수 채널에 판매를 희망하는 판매 담당자
                </li>
              </ul>
            </div>
            <Xline
              width={width - 40}
              delay={0.8}
              duration={0.6}
              type="left"
              visible={isInView}
            />
            <div className="list">
              <div className="info">
                프로모션 마케팅
                <span> support@genieworks.net</span>
              </div>
              <ul>
                <li>단순 이벤트를 넘어, 유입부터 브랜딩까지 고민하는 마케터</li>
                <li>No.1 리워드형 게임 이벤트 구축을 고민하는 채널 담당자</li>
                <li>데이터 기반의 마케팅 성과를 고민하는 마케팅 담당자</li>
              </ul>
            </div>
            <Xline
              width={width - 40}
              delay={0.8}
              duration={0.6}
              type="left"
              visible={isInView}
            />
            <div className="list">
              <div className="info">
                기타 문의
                <span> support@genieworks.net</span>
                <strong>1800 - 1011</strong>
              </div>
              <ul>
                <li>위 서비스 외, 파트너십 제휴 및 프로젝트 의뢰</li>
                <li> 회사 및 투자정보, 기타 문의 사항</li>
              </ul>
            </div>
          </div>
          <Form type={type as string} />
        </Contents>
        <div css={comeStyle}>
          <div className="come">
            <h1>오시는 길</h1>
            <span>서울 강남구 논현로 653 2층(에이엔에이 빌딩)</span>
            <Maps height={250} />
          </div>
        </div>
      </Mobile>
    </Layout>
  );
};

export default Inquiry;
