/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import section2 from "static/message/section2.png";
import React from "react";
import { container } from "styles";
import { tabandmo } from "styles/media";

const Section3 = () => {
  const section3Style = css`
    margin-top: 120px;
    .title {
      text-align: center;
      font-size: 52px;
      line-height: 68px;
      font-weight: 700;
    }
    .text {
      text-align: center;
      margin-top: 20px;
      line-height: 28px;
    }
    img {
      margin-top: 116px;
      width: 100%;
    }
    ${tabandmo} {
      margin-top: 80px;
      .title {
        font-size: 32px;
        line-height: 48px;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
      }
      img {
        margin-top: 66px;
      }
    }
  `;
  return (
    <div css={section3Style}>
      <div className="title">주목도 획득</div>
      <div className="text">
        흥미를 끄는 이미지와 멀티 미디어를 통해
        <br />
        1차로 고객의 눈길을 사로잡고
        <br />
        타겟팅을 통해 파악한 수신자의 관심 정보를 제공하여
        <br />
        2차로 고객의 마음을 사로잡아
        <br />
        브랜드 및 상품 홍보화 함께 확실한 성과까지!
      </div>
      <div css={container}>
        <img src={section2} alt="section3" />
      </div>
    </div>
  );
};

export default Section3;
