/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import searchIcon from "static/news/search.svg";
import leftIcon from "static/news/left.svg";
import rightIcon from "static/news/right.svg";
import { tabandmo } from "styles/media";

interface PaginationProps {
  totalPosts: number;
  limit: number;
  page: number;
  setPage: (page: number) => void;
}

const Pagination = ({ totalPosts, limit, page, setPage }: PaginationProps) => {
  const numPages = Math.ceil(totalPosts / limit);

  const paginationStyle = css`
    .pagination {
      margin-top: 105px;
      margin-bottom: 150px;
      display: flex;
      justify-content: center;
      span {
        color: #aeaeae;
        text-align: center;
        font-family: Pretendard;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
        letter-spacing: -0.24px;
        margin-right: 20px;
        cursor: pointer;
      }
      .left {
        background: url(${leftIcon});
        margin-right: 34px;
        width: 12px;
        height: 34px;
        background-size: 12px 18px;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      .right {
        background: url(${rightIcon});
        margin-left: 14px;
        width: 12px;
        height: 34px;
        background-size: 12px 18px;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
    }
    .pagination .pagination span.active {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
    }

    ${tabandmo} {
      .title {
        flex-direction: column-reverse;
        .search {
          padding: 4px 0px;
          width: 100%;
          i {
            bottom: 4px;
          }
        }
        .total {
          width: 100%;
          margin-top: 36px;
          margin-top: 26px;
          padding: 10px 0;
          border-bottom: 1px solid #000;
        }
      }
      .list {
        margin-top: 24px;
        .item {
          margin: 0;
          width: 100%;
        }

        .item + .item {
          margin-top: 40px;
        }
      }
    }
  `;

  return (
    <div css={paginationStyle}>
      <div className="pagination">
        <div
          className="icon left"
          onClick={() => {
            1 < page && setPage(page - 1);
          }}
        ></div>
        {Array(numPages)
          .fill("")
          .map((_, i) => {
            return (
              <span
                key={i + 1}
                onClick={() => setPage(i + 1)}
                className={`${i + 1 === page ? "active" : ""} `}
              >
                {i + 1}
              </span>
            );
          })}
        <div
          className="icon right"
          onClick={() => {
            numPages > page && setPage(page + 1);
          }}
        ></div>
      </div>
    </div>
  );
};

export default Pagination;
