import { motion } from "framer-motion";
import React from "react";

interface YLineProps {
  width: number;
  delay?: number;
  duration?: number;
  type?: "top" | "bottom";
  visible?: boolean;
  fill?: string;
  className?: string;
}

export const Yline = ({
  width,
  delay = 0,
  duration = 0.5,
  type = "top",
  visible = true,
  fill = "#000",
  className,
}: YLineProps) => {
  return (
    <motion.svg
      width="20"
      className={className}
      height={width}
      viewBox={`0 0  20 ${width}`}
      initial="hidden"
      animate={visible ? "visible" : "hidden"}
    >
      <motion.circle
        cx="10"
        cy={type === "top" ? "2" : width - 4}
        r="2"
        fill={fill}
        variants={{
          hidden: { pathLength: 0, opacity: 0 },
          visible: {
            pathLength: 1,
            opacity: 1,
            translateY: type === "top" ? width - 4 : -width + 6,
            transition: {
              translateY: {
                delay,
                duration,
              },
              opacity: { delay, duration },
            },
          },
        }}
        custom={1}
      />
      <motion.line
        x1="10"
        y1={type === "top" ? 2 : width - 2}
        x2="10"
        y2={type === "top" ? width - 2 : 2}
        stroke={fill}
        variants={{
          hidden: { pathLength: 0, opacity: 0 },
          visible: {
            pathLength: 1,
            opacity: 1,
            transition: {
              pathLength: { delay, duration },
              opacity: { delay, duration },
            },
          },
        }}
        custom={2}
      />
    </motion.svg>
  );
};

export default Yline;
