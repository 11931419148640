/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import XBar from "components/Intraction/xBar";
import React from "react";
import { motion } from "framer-motion";
import big2 from "static/commerce/big2.png";
import img1 from "static/commerce/img5.png";
import img2 from "static/commerce/img6.png";
import img3 from "static/commerce/img7.png";
import img4 from "static/commerce/img8.png";
import Bar from "components/Intraction/yBar";
import { Mobile, PC } from "components/Common/Responsive";

const Section4 = () => {
  const section4Style = css`
    background: #f4f3ff;
    height: 964px;
    > div {
      position: relative;
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      padding: 255px 100px 156px 100px;
    }

    > div > svg {
      position: absolute;
      right: 0;
    }
    > div > svg:nth-of-type(1) {
      left: 0;
      top: 0;
    }
    > div > svg:nth-of-type(2) {
      left: 0;
      top: 96px;
    }
    > div > svg:nth-of-type(3) {
      right: 0;
      bottom: 10px;
    }
    .right {
      margin-left: 88px;
      color: #000;
      font-family: Pretendard;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 34px; /* 141.667% */
      letter-spacing: -0.24px;
    }
    .right span {
      color: var(--gray-2-aeaeae, #666);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px; /* 150% */
      letter-spacing: -0.2px;
    }
    .right h1 {
      color: #000;
      margin: 20px 0;
      font-family: Pretendard;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 58px; /* 120.833% */
      letter-spacing: -0.48px;
    }

    .right img {
      width: 305px;
      height: 245px;
      margin-top: 67px;
    }

    .left {
      margin-right: 88px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .left > div {
      width: 178px;
      color: #000;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
    }

    .left > div:nth-of-type(n + 3) {
      margin-top: 44px;
    }

    .left > div img {
      width: 103px;
      height: 103px;
      flex-shrink: 0;
    }
    .left > div h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 154.545% */
      letter-spacing: -0.22px;
      margin-top: 24px;
      margin-bottom: 12px;
    }
  `;
  const section4MoStyle = css`
    background: #f4f3ff;
    > div {
      position: relative;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      padding-top: 60px;
      padding-bottom: 90px;
    }

    .left {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    }
    .left span {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.36px;
    }
    .left h1 {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px; /* 150% */
      letter-spacing: -0.64px;
      margin-bottom: 12px;
      margin-top: 2px;
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 26px 25px 0px 25px;
    }
    .right > div {
      width: calc(50% - 16px);
      margin-top: 34px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #000;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
    }
    .right > div img {
      width: 102px;
      height: 102px;
      flex-shrink: 0;
    }
    .right > div h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      margin-top: 16px;
      margin-bottom: 8px;
    }
  `;
  return (
    <>
      <PC>
        <div css={section4Style}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <motion.svg width={96} height={96}>
              <motion.circle cx={0} cy={0} r={96} fill={"#7C70E9"} />
            </motion.svg>
            <XBar width={96} height={96} type="right" fill={"#7C70E9"} />
            <motion.svg width={130} height={109}>
              <motion.circle cx={130} cy={130} r={130} fill={"#7C70E9"} />
            </motion.svg>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.6,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="left">
                <div>
                  <img src={img1} alt="img1" />
                  <h1>개발 비용 절감</h1>
                  UI/UX 커스터마이징이 가능하며, 전체 이커머스 시스템 개발
                  불필요
                </div>
                <div>
                  <img src={img2} alt="img2" />
                  <h1>신규 수익 확보</h1>
                  CPS로 신규 수익 확보 및 자사 상품 이외의 다양한 상품을 쉽고
                  빠르게 소싱 가능
                </div>
                <div>
                  <img src={img3} alt="img3" />
                  <h1>높은 구매전환율</h1>CS 등 이커머스 운영 체계 구축 불필요.
                  시스템 통한 실시간 현황 확인 가능
                </div>
                <div>
                  <img src={img4} alt="img4" />
                  <h1>서비스 만족도 상승</h1>
                  고객 대상 이벤트 진행 등을 통한서비스 만족도 상승
                </div>
              </div>
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="right">
                <span>채널(channel)</span>
                <h1>채널 파트너</h1>
                소싱부터 운영까지 별도 투자 없이
                <br />
                커머스 시스템 도입 가능
                <img src={big2} alt="big2" />
              </div>
            </motion.div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section4MoStyle}>
          <div>
            <div className="left">
              <span>채널(channel)</span>
              <h1>채널 파트너</h1>
              소싱부터 운영까지 별도 투자없이
              <br />
              커머스 시스템 도입 가능
            </div>
            <div className="right">
              <div>
                <img src={img1} alt="img1" />
                <h1>개발 비용 절감</h1>
                UI/UX 커스터마이징이 가능하며, 전체 이커머스 시스템 개발 불필요
              </div>
              <div>
                <img src={img2} alt="img2" />
                <h1>신규 수익 확보</h1>
                CPS로 신규 수익 확보 및 자사 상품 이외의 다양한 상품을 쉽고
                빠르게 소싱 가능
              </div>
              <div>
                <img src={img3} alt="img3" />
                <h1>높은 구매전환율</h1>각 채널별 고객 DB와 정교한 AI 추천 각
                채널별 고객 DB와 정교한 AI 추천 알고리즘을 활용한 추천 및 타겟팅
              </div>
              <div>
                <img src={img4} alt="img4" />
                <h1>폐쇄형 서비스</h1>
                제휴 연동된 고객에게만 판매하여 검색엔진에 가격 비노출
              </div>
            </div>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section4;
