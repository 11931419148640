/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import TitleImage from "static/marketing/mobileTitle.png";
import img1 from "static/marketing/img1.png";
import img2 from "static/marketing/img2.png";
import img3 from "static/marketing/img3.png";
import img4 from "static/marketing/img4.png";
import img5 from "static/marketing/img5.png";
import img6 from "static/marketing/img6.png";
import img7 from "static/marketing/img7.png";
import img8 from "static/marketing/img8.png";
import { Mobile, PC } from "components/Common/Responsive";
import { motion } from "framer-motion";

const Section1 = () => {
  const section1Style = css`
    max-width: 604px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 140px;
    img {
      width: 100%;
    }

    > div:nth-of-type(1) {
      position: relative;
      width: 603px;
      height: 774px;
    }
    > div:nth-of-type(2) {
      position: absolute;
      width: 132px;
      height: 182px;
      transform: rotate(-9.718deg);
      top: 310px;
      left: -98px;
    }
    > div:nth-of-type(3) {
      position: absolute;
      width: 96px;
      height: 106px;
      transform: rotate(4.583deg);
      top: 3px;
      left: 118px;
    }
    > div:nth-of-type(4) {
      position: absolute;
      width: 210px;
      height: 268px;
      transform: rotate(8.776deg);
      top: 22px;
      left: 177px;
    }
    > div:nth-of-type(5) {
      position: absolute;
      width: 81px;
      height: 89px;
      top: -55px;
      left: 338px;
    }
    > div:nth-of-type(6) {
      position: absolute;
      right: 63px;
      top: 60px;
      width: 98px;
      height: 107px;
    }
    > div:nth-of-type(7) {
      position: absolute;
      right: 3px;
      top: 180px;
      width: 106.126px;
      height: 117.2px;
      transform: rotate(30.445deg);
    }
    > div:nth-of-type(8) {
      position: absolute;
      right: -54px;
      top: -44px;
      width: 101.365px;
      height: 112.798px;
      transform: rotate(12.505deg);
    }
    > div:nth-of-type(9) {
      position: absolute;
      width: 228px;
      height: 357px;
      bottom: 90px;
      right: -133px;
    }
  `;
  const section1MoStyle = css`
    max-width: 246px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 80px;
    img {
      position: absolute;
    }
    > img:nth-of-type(1) {
      position: relative;
      width: 246px;
      height: 280px;
    }
    > img:nth-of-type(2) {
      width: 53px;
      height: 74px;
      transform: rotate(-9.718deg);
      top: 124px;
      left: -35px;
    }
    > img:nth-of-type(3) {
      width: 39px;
      height: 43px;
      transform: rotate(4.583deg);
      top: 3px;
      left: 54px;
    }
    > img:nth-of-type(4) {
      width: 86px;
      height: 109px;
      transform: rotate(8.776deg);
      top: 8px;
      left: 81px;
    }
    > img:nth-of-type(5) {
      width: 33px;
      height: 36px;
      top: -23px;
      left: 127px;
    }
    > img:nth-of-type(6) {
      right: 30px;
      width: 40px;
      height: 43px;
    }
    > img:nth-of-type(7) {
      right: 15px;
      top: 60px;
      width: 43px;
      height: 47px;
      transform: rotate(30.445deg);
    }
    > img:nth-of-type(8) {
      right: -12px;
      top: -21px;
      width: 41px;
      height: 46px;
      transform: rotate(12.505deg);
    }
    > img:nth-of-type(9) {
      width: 100px;
      height: 157px;
      bottom: 10px;
      right: -30px;
    }
  `;
  return (
    <>
      <PC>
        <motion.div
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.6 }}
        >
          <div css={section1Style}>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={TitleImage} alt="TitleImage" />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.6,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={img1} alt="img1" />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 1.5,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={img2} alt="img2" />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.9,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={img3} alt="img2" />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 1.5,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={img4} alt="img2" />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 1.5,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={img5} alt="img2" />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 1.5,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={img6} alt="img2" />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 1.5,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={img7} alt="img2" />
            </motion.div>
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 1.2,
                    duration: 0.3,
                  },
                },
              }}
            >
              <img src={img8} alt="img8" />
            </motion.div>
          </div>
        </motion.div>
      </PC>
      <Mobile>
        <div css={section1MoStyle}>
          <img src={TitleImage} alt="TitleImage" />
          <img src={img1} alt="img1" />
          <img src={img2} alt="img2" />
          <img src={img3} alt="img3" />
          <img src={img4} alt="img4" />
          <img src={img5} alt="img5" />
          <img src={img2} alt="img6" />
          <img src={img7} alt="img7" />
          <img src={img8} alt="img8" />
        </div>
      </Mobile>
    </>
  );
};

export default Section1;
