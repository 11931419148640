/** @jsxImportSource @emotion/react */
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import { container } from "styles";
import React, { useEffect, useRef, useState } from "react";
import Title from "components/Common/Title";
import { motion, useInView } from "framer-motion";
import Xline from "components/Intraction/xLine";
import arrow from "static/main/arrow.png";
import { Mobile, PC } from "components/Common/Responsive";
import useWindowDimemsions from "hooks/useWindowDimemsions";
import { api } from "service/config";
import moment from "moment";

const Section5 = () => {
  const { width } = useWindowDimemsions();
  const section5Style = css`
    padding: 120px 0px;
    .list-wrap {
      margin-top: 100px;
      .list {
        li {
          display: flex;
          padding: 40px 80px;
          border-bottom: 1px solid #e1e1e1;
          align-items: center;
          &:first-of-type {
            padding-top: 30px;
          }
          &:last-of-type {
            border-bottom: 0;
            padding-bottom: 30px;
          }
          .date {
            color: #666;
            font-size: 16px;
          }
          .title {
            margin-left: 12px;
          }
          .arrow {
            background: url(${arrow});
            width: 9px;
            height: 12px;
            background-size: 100%;
            margin-left: auto;
          }
        }
      }
    }
  `;
  const sectionMo5Style = css`
    padding: 60px 20px;
    .list-wrap {
      margin-top: 56px;
      .list {
        li {
          display: flex;
          padding: 24px 10px;
          border-bottom: 1px solid #e1e1e1;
          flex-direction: column;
          &:last-of-type {
            border-bottom: 0;
          }
          .date {
            color: #666;
            font-size: 12px;
            line-height: 18px;
          }
          .title {
            margin-left: 0px;
            font-size: 16px;
            line-height: 24px;
            white-space: nowrap;
            -webkit-line-clamp: 1;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .arrow {
            background: url(${arrow});
            width: 9px;
            height: 12px;
            background-size: 100%;
            margin-left: auto;
          }
        }
      }
    }
  `;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.6 });
  const [news, setNews] = useState<any[]>([]);

  useEffect(() => {
    api
      .get("/news/news", {
        params: {
          page: 1,
          pageSize: 4,
        },
      })
      .then((response) => {
        setNews(response.data.list);
      });
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <PC>
        <div css={section5Style} ref={ref}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Title
              title={
                <>
                  언론 속 지니웍스
                  <br />
                  히스토리를 만들어 갑니다
                </>
              }
            />

            <div css={container}>
              <motion.div
                className="list-wrap"
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.4,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <Xline
                  width={1140}
                  delay={0.4}
                  duration={0.6}
                  visible={isInView}
                />
                <ul className="list">
                  {news.map((value, index) => (
                    <li
                      key={index}
                      onClick={() => navigate("/news/list/" + value.id)}
                    >
                      <div className="date">
                        {moment(value.createAt).format("YYYY-MM-DD")}
                      </div>
                      <div className="title">{value.title}</div>
                      <div className="arrow"></div>
                    </li>
                  ))}
                </ul>
                <Xline
                  width={1140}
                  delay={0.8}
                  duration={0.6}
                  type="left"
                  visible={isInView}
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={sectionMo5Style} ref={ref}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.6 }}
          >
            <Title
              title={
                <>
                  언론 속 지니웍스
                  <br />
                  히스토리를 만들어 갑니다
                </>
              }
            />

            <div css={container}>
              <motion.div
                className="list-wrap"
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    opacity: 1,
                    y: 0,
                    transition: {
                      delay: 0.4,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <Xline
                  width={width - 40}
                  delay={0.4}
                  duration={0.6}
                  visible={isInView}
                />
                <ul className="list">
                  {news.map((value, index) => (
                    <li
                      key={index}
                      onClick={() => navigate("/news/list/" + value.id)}
                    >
                      <div className="date">
                        {moment(value.createAt).format("YYYY-MM-DD")}
                      </div>
                      <div className="title">{value.title}</div>
                    </li>
                  ))}
                </ul>
                <Xline
                  width={width - 40}
                  delay={0.8}
                  duration={0.6}
                  type="left"
                  visible={isInView}
                />
              </motion.div>
            </div>
          </motion.div>
        </div>
      </Mobile>
    </>
  );
};

export default Section5;
