/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import { tabandmo } from "styles/media";

interface SectionTitleProps {
  mainText: string;
  subText?: string;
  marginTop?: number;
}
const SectionTitle = ({
  mainText,
  subText,
  marginTop = 0,
}: SectionTitleProps) => {
  const TitleStyle = css`
    text-align: center;
    > h1 {
      white-space: pre-line;
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 52px;
      font-style: normal;
      font-weight: 700;
      line-height: 68px; /* 130.769% */
      letter-spacing: -0.52px;
      margin-top: ${marginTop}px;
      margin-bottom: 20px;
      text-align: center;
    }
    > span {
      white-space: pre-line;
      color: rgba(45, 45, 45, 0.8);
      text-align: center;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
    }
    ${tabandmo} {
      > h1 {
        white-space: pre-line;
        color: var(--black, #000);
        text-align: center;
        font-family: Pretendard;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px; /* 150% */
        letter-spacing: -0.64px;
        margin-top: ${marginTop}px;
        margin-bottom: 20px;
        text-align: center;
      }
      > span {
        white-space: pre-line;
        color: var(--black, #000);
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        letter-spacing: -0.16px;
      }
    }
  `;
  return (
    <div css={TitleStyle}>
      <h1>{mainText}</h1>
      {subText !== undefined && <span>{subText}</span>}
    </div>
  );
};

export default SectionTitle;
