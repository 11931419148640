/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { motion, useInView } from "framer-motion";
import mobile4Image from "static/commerce/mobile4_logo.png";
import mobile5Image from "static/commerce/mobile5_logo.png";
import commonArrowRight from "static/common/arrow_right.svg";

import partner1Image from "static/commerce/partner1.png";
import partner1MoImage from "static/commerce/partner1_Mo.png";
import partner2Image from "static/commerce/partner2.png";
import partner2MoImage from "static/commerce/partner2_Mo.png";
import Yline from "components/Intraction/yLine";
import Bar from "components/Intraction/yBar";
import Xline from "components/Intraction/xLine";
import { Mobile, PC } from "components/Common/Responsive";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

const Section5 = () => {
  const firstRef = useRef(null);
  const secRef = useRef(null);
  const thirdRef = useRef(null);
  const navigate = useNavigate();

  const isInFirstView = useInView(firstRef, { once: true, amount: 0.6 });
  const isInSecView = useInView(secRef, { once: true, amount: 0.6 });
  const isInThirdView = useInView(thirdRef, { once: true, amount: 0.6 });

  const section5Style = css`
    .title {
      margin-top: 242px;
      margin-bottom: 90px;
      text-align: center;
    }
    .title h1 {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 54px;
      font-style: normal;
      font-weight: 700;
      line-height: 70px; /* 129.63% */
      letter-spacing: -0.54px;
      margin-bottom: 20px;
    }
    .title span {
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.16px;
    }
    .mobile {
      display: flex;
      justify-content: center;
      margin-bottom: 210px;
    }
    .mobile > div {
      display: flex;
      justify-content: center;
      margin-right: 101.3px;
    }
    .mobile img {
      width: 177px;
      height: 413px;
    }
    .mobile .info {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.48px;
      width: 206px;
      padding-top: 200px;
      margin-left: 44.25px;
    }
    .mobile .info.big {
      width: 233px;
    }
    .mobile .info h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 140% */
      letter-spacing: -0.2px;
    }
    .mobile .info span {
      color: #989898;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.14px;
    }
    .partner {
      margin-bottom: 240px;
      max-width: 1140px;
      margin: 0 auto;
      text-align: center;
    }
    .partner h1 {
      margin-top: 82px;
      margin-bottom: 16px;
      text-align: center;
      color: #000;
      font-family: Pretendard;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 34px; /* 154.545% */
      letter-spacing: -0.22px;
    }
    .partner > div:first-of-type {
      margin-top: 0;
    }

    .partner > div {
      margin-top: 40px;
    }

    .partner img {
      width: 959px;
      height: 143px;
      margin: 40px;
    }
    .footer {
      position: relative;
      padding: 137px 0 98px 0;
      background: #f8f8f8;
      margin-top: 240px;
    }
    .footer .left {
      position: absolute;
      left: calc(50% - 456px);
      transform: translateX(-50%);
      bottom: 0;
      svg {
        float: left;
      }
    }
    .footer .right {
      position: absolute;
      bottom: 0;
      left: calc(50% + 453px);
      transform: translateX(-50%);
      svg {
        float: left;
      }
    }
    .footer .center {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 78px;
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 50px; /* 147.059% */
      letter-spacing: -0.34px;
    }
    .footer .center svg {
      margin: 0 116px 0 71px;
    }
    .footer .bottom {
      display: flex;
      justify-content: center;
      padding-bottom: 98px;
    }
    .footer button {
      border-radius: 80px;
      padding: 13px 20px 13px 38px;
      border: 0;
      background: #4f80ff;
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      display: flex;
      cursor: pointer;
      align-items: center;
    }
    .btn_wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      button {
        left: auto;
        transform: none;
      }
      button:nth-of-type(1) {
        margin-right: 12px;
      }
    }
  `;

  const section5MoStyle = css`
    .title {
      margin-top: 60px;
      margin-bottom: 55px;
      text-align: center;
    }
    .title h1 {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 48px; /* 150% */
      letter-spacing: -0.64px;
      margin-bottom: 24px;
    }
    .title span {
      color: var(--black, #000);
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    }
    .mobile {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-bottom: 28px;
    }
    .mobile img {
      width: 100%;
      height: auto;
    }
    .mobile .info {
      color: #000;
      text-align: justify;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      letter-spacing: -0.48px;
      width: 100%;
      padding: 24px 20px 62px 20px;
    }
    .mobile .info h1 {
      color: #000;
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.18px;
      display: inline-block;
      margin-right: 18px;
    }
    .mobile .info span {
      color: #787878;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.14px;
    }
    .partner {
      margin-bottom: 90px;
      margin: 0 auto;
      text-align: center;
    }
    .partner h1 {
      margin-top: 52px;
      margin-bottom: 24px;
      color: #000;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 150% */
      letter-spacing: -0.2px;
    }
    .partner h1:first-of-type {
      margin-top: 0;
    }

    .partner img {
      width: 352px;
      height: 267.745px;
    }
    .footer {
      position: relative;
      padding: 41px 0 90px 0;
      background: #f8f8f8;
      margin-top: 90px;
    }
    .footer .center {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px 37px 25px;
      color: #000;
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: -0.16px;
    }

    .footer .center div {
      width: calc(50% - 20px);
    }

    .footer .bottom {
      display: flex;
      justify-content: center;
      padding-bottom: 98px;
    }
    .footer button {
      border-radius: 80px;
      padding: 13px 20px 13px 38px;
      border: 0;
      background: #4f80ff;
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      display: flex;
      align-items: center;
    }
  `;

  return (
    <>
      <PC>
        <div css={section5Style}>
          <motion.div
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
          >
            <motion.div
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.3,
                  },
                },
              }}
            >
              <div className="title">
                <h1>
                  국내 대표 채널이 선택한, 셀러드
                  <br />
                  사례로 직접 확인하세요
                </h1>
                <span>
                  다수의 국내 프리미엄급 모바일APP, 통신사, 광고 네트워크사와
                  함께
                  <br />
                  셀러드를 활용한 쇼핑 서비스를 진행하고 있습니다.
                </span>
              </div>
            </motion.div>
            <div className="mobile">
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <img src={mobile4Image} alt="mobile4Image" />
                <div className="info">
                  <h1>U+ 콕 </h1>
                  <span>LG 유플러스</span>
                  <br />
                  <br />
                  U+의 약 900만명의 고객 데이터를 활용하여 맞춤형 상품 추천 및
                  U+콕이라는 개별 앱으로 확대, 더 많은 혜택을 제공하는 통신사
                  대표 커머스입니다.
                </div>
              </motion.div>
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.6,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <img src={mobile5Image} alt="mobile5Image" />
                <div className="info big">
                  <h1>PAYCO 포인트몰 </h1>
                  <span>PAYCO [페이코]</span>
                  <br />
                  <br />
                  PAYCO 포인트 사용자를 위한 PAYCO
                  <br />
                  회원 전용 쇼핑몰로, 타겟팅 및 푸시로
                  <br />
                  맞춤형 상품과 기획전 등의 혜택을 제공
                  <br />
                  합니다.
                </div>
              </motion.div>
            </div>
          </motion.div>
          <div className="partner">
            <motion.div
              ref={firstRef}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
            >
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <h1>제휴 파트너사</h1>
                <Xline
                  width={1140}
                  type="left"
                  visible={isInFirstView}
                  delay={0.6}
                />
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 1.5,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <img src={partner1Image} alt="partner1Image" />
                </motion.div>
                <Xline width={1140} visible={isInFirstView} delay={0.9} />
              </motion.div>
            </motion.div>
            <motion.div
              ref={secRef}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.2 }}
            >
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                <h1>입점/유통사</h1>
                <Xline
                  width={1140}
                  type="left"
                  visible={isInSecView}
                  delay={0.6}
                />
                <motion.div
                  variants={{
                    offscreen: {
                      y: 100,
                      opacity: 0,
                    },
                    onscreen: {
                      y: 0,
                      opacity: 1,
                      transition: {
                        delay: 1.5,
                        duration: 0.3,
                      },
                    },
                  }}
                >
                  <img src={partner2Image} alt="partner2Image" />
                </motion.div>
                <Xline width={1140} visible={isInSecView} delay={0.9} />
              </motion.div>
            </motion.div>
          </div>

          <motion.div
            ref={thirdRef}
            initial="offscreen"
            whileInView="onscreen"
            className="footer"
            viewport={{ once: true, amount: 0.2 }}
          >
            <div className="left">
              <motion.svg width={104} height={155}>
                <motion.circle cx={52} cy={52} r={52} fill={"#4F80FF"} />
                <motion.circle cx={52} cy={156} r={52} fill={"#4F80FF"} />
              </motion.svg>
              <Bar width={103} height={155} />
            </div>
            <div className="right">
              <motion.svg width={104} height={155}>
                <motion.circle cx={52} cy={156} r={52} fill={"#4F80FF"} />
              </motion.svg>
              <Bar width={103} height={155} />
            </div>
            <div className="center">
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                우리 상품을 단 한 번의 등록으로
                <br />
                다수 채널에 판매하세요
              </motion.div>
              <Yline
                width={117}
                type="bottom"
                visible={isInThirdView}
                delay={0.6}
              />
              <motion.div
                variants={{
                  offscreen: {
                    y: 100,
                    opacity: 0,
                  },
                  onscreen: {
                    y: 0,
                    opacity: 1,
                    transition: {
                      delay: 0.3,
                      duration: 0.3,
                    },
                  },
                }}
              >
                투자비용없이 우리 채널에
                <br />
                커머스를 도입하세요
              </motion.div>
            </div>
            <motion.div
              className="btn_wrap"
              variants={{
                offscreen: {
                  y: 100,
                  opacity: 0,
                },
                onscreen: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    delay: 0.9,
                    duration: 0.3,
                  },
                },
              }}
            >
              <button
                onClick={() =>
                  window.open(
                    "https://sellerd.notion.site/Sellerd-Partner-Guide-ce8ba2e4a7c349179ca3d0dc846266ce"
                  )
                }
              >
                셀러드 파트너 가이드
                <img src={commonArrowRight} alt="commonArrowRight" />
              </button>
              <button onClick={() => navigate("/inquiry?type=1")}>
                커머스 도입 문의
                <img src={commonArrowRight} alt="commonArrowRight" />
              </button>
            </motion.div>
          </motion.div>
        </div>
      </PC>
      <Mobile>
        <div css={section5MoStyle}>
          <div className="title">
            <h1>
              국내 대표 채널, 셀러드
              <br />
              사례로 직접 확인하세요
            </h1>
            <span>
              상품을 다양한 경로로 빠르게 확산시키는 일, <br />
              셀러드가 지향하는 모델입니다.
            </span>
          </div>
          <div className="mobile">
            <img src={mobile4Image} alt="mobile4Image" />
            <div className="info">
              <h1>U+ 콕 </h1>
              <span>LG 유플러스</span>
              <br />
              U+의 약 900만명의 고객 데이터를 활용하여 맞춤형 상품 추천 및
              U+콕이라는 개별 앱으로 확대, 더 많은 혜택을 제공하는 통신사 대표
              커머스입니다.
            </div>
            <img src={mobile5Image} alt="mobile5Image" />
            <div className="info big">
              <h1>PAYCO 포인트몰 </h1>
              <span>PAYCO [페이코]</span>
              <br />
              PAYCO 포인트 사용자를 위한 PAYCO 회원 전용 쇼핑몰로, 타겟팅 및
              푸시로 맞춤형 상품과 기획전 등의 혜택을 제공 합니다.
            </div>
          </div>
          <div className="partner">
            <h1>제휴 파트너사</h1>
            <Xline width={333} type="left" />
            <img src={partner1MoImage} alt="partner1MoImage" />
            <Xline width={333} />
            <h1>입점/유통사</h1>
            <Xline width={333} type="left" />
            <img src={partner2MoImage} alt="partner2MoImage" />
            <Xline width={333} />
          </div>
          <div className="footer">
            <div className="center">
              <div>
                우리 상품을 단 한번의
                <br /> 등록으로 다수 채널에
                <br /> 판매하세요
              </div>
              <Yline width={117} type="bottom" />
              <div>
                투자 비용없이
                <br /> 우리 채널에 커머스를
                <br /> 도입하세요
              </div>
            </div>
            <button
              onClick={() =>
                window.open(
                  "https://sellerd.notion.site/Sellerd-Partner-Guide-ce8ba2e4a7c349179ca3d0dc846266ce"
                )
              }
            >
              셀러드 파트너 가이드
              <img src={commonArrowRight} alt="commonArrowRight" />
            </button>
          </div>
        </div>
      </Mobile>
    </>
  );
};

export default Section5;
